/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as DateTimeUtils from './DateTimeUtils';

export const SERIES_COMPARISON_FIELD = 'SeriesComparisonField';

export function getSeriesItem(seriesComparison, chartWidget){
  return {
    seriesValues: {
      [SERIES_COMPARISON_FIELD]: seriesComparison.name,
    },
    seriesType: chartWidget.chartType,
    fields: [
      SERIES_COMPARISON_FIELD,
    ],
    plotOnY: true,
    plotOnY2: false,
    color: seriesComparison.color,
    startDate: seriesComparison.startDate,
    endDate: seriesComparison.endDate,
    strokeType: seriesComparison.strokeType,
    lineThickness: seriesComparison.lineThickness,
  };
}
export function formatTimeComparisonChartSeries(dateRange, chartWidget, timeSeriesComparisons){
  const series = {};
  let diff;

  (timeSeriesComparisons || []).forEach((timeComparisonSerie, index) => {
    if(index == 0){
      timeComparisonSerie.startDate = dateRange.from;
      timeComparisonSerie.endDate = dateRange.to;
      diff = timeComparisonSerie.endDate - timeComparisonSerie.startDate;
      chartWidget.mainSeriesName = timeComparisonSerie.name;
    }else{
      if(timeComparisonSerie.relativeDate){
        timeComparisonSerie.startDate = DateTimeUtils.getEpochFromRelativeDate(timeComparisonSerie.relativeDate, dateRange.from);
      }
      timeComparisonSerie.endDate = timeComparisonSerie.startDate + diff;
    }
    series[timeComparisonSerie.name] = getSeriesItem(timeComparisonSerie, chartWidget);
  });

  return series;
}