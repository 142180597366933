/* Copyright 2019 Greyskies. All Rights Reserved. */

import { createContext } from 'react';
import { createContextualCan } from '@casl/react';
import { defineAbility } from '@casl/ability';

export const Ability = (userData) => defineAbility((can) => {
  if(userData.isSuperAdmin){
    can('manage', 'all');
  } else {
    userData.workflowPermissions.forEach(workflowPermission => { 
      can(workflowPermission.actions?.map(action => action.toLowerCase()), workflowPermission.pageName.toLowerCase());
    });
  }
});

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

