/* Copyright 2019 Greyskies. All Rights Reserved. */
import * as Utils from '../Utils';

const LINE_DELIMITER = '\r\n';

export const parseFile = (text, transformParams) => {
  const lines = text.split(/\r?\n/);
  const totalCount = lines.length;
  const data = [];

  lines.forEach(line => {      
    let dataElement = line;

    if(transformParams.trimWhiteSpaces){
      dataElement =  dataElement.trim();
    }

    if(transformParams.filterEmptyLines 
        && Utils.isEmptyString(dataElement)){
      return;
    }

    if(transformParams.rowFormatter){
      dataElement = transformParams.rowFormatter(dataElement);
    }

    data.push(dataElement); 
  });

  return {
    data,
    totalCount,
  };
};

export const writeFile = (data, transformParams) => {
  let fileContent = '';
  let exportedData = data;

  if(transformParams.preMappingFiltering){
    exportedData = transformParams.preMappingFiltering(exportedData);
  }

  exportedData.forEach((element, index) => {      
    const row = transformParams.rowFormatter(element);

    fileContent = index > 0 ? fileContent + LINE_DELIMITER + row : row;
  });

  return fileContent;
};