/* Copyright 2019 Greyskies. All Rights Reserved. */
export default [
  '#377EB8', '#999999', '#F1C52D', '#66C2A5', '#F781BF', '#E41A1C', '#984EA3', '#FF7F00',
  '#4DAF4A', '#A65628', '#8073AC', '#FF6361', '#7285F8', '#FFA319', '#CD418E',

  '#5A94C9', '#A7A7A7', '#F2D55E', '#75D1B8', '#FA92C7', '#F05641', '#AE6CB6', '#FF923D',
  '#6CBB64', '#BD7248', '#A08DC0', '#FF8378', '#959CFA', '#FCBA44', '#DA6DAC',

  '#78AADB', '#B6B6B6', '#F5E388', '#85E0CA', '#FCA3CF', '#F97E67', '#C48ACA', '#FFA563',
  '#87C77E', '#D48E69', '#BFA9D4', '#FF9F92', '#B3B5FC', '#FBCF69', '#E693C8',

  '#95C1ED', '#C5C5C5', '#F9F1B0', '#95F0DD', '#FEB4D8', '#FEA28F', '#D9A9DE', '#FFB787',
  '#A1D299', '#EAAB8B', '#DCC7E9', '#FFBAAD', '#CECDFE', '#FCE28E', '#F2B7E2',

  '#B2D8FF', '#D4D4D4', '#FFFDD8', '#A6FFF0', '#FFC4E0', '#FFC5B8', '#EFC8F2', '#FCCAAB',
  '#BADEB3', '#FFC9AE', '#F9E5FF', '#FFD4CB', '#E8E7FF', '#FFF4B4', '#FFDBF9',

  '#1F62A2', '#707070', '#BB9325', '#4E9276', '#C75E90', '#B90C1A', '#7A3987', '#D05718',
  '#378639', '#8C3E21', '#6A5E93', '#D44B4A', '#5362C1', '#CC7B1A', '#A93073',

  '#0A468B', '#4A4A4A', '#85651C', '#316D50', '#973D65', '#8F0316', '#5E256B', '#9E331A',
  '#235E28', '#712819', '#544A7B', '#AA3335', '#34418D', '#995617', '#861F59',

  '#002B73', '#272727', '#513B13', '#17462B', '#691E3D', '#67000F', '#421250', '#6B1614',
  '#123918', '#561311', '#3F3663', '#811B21', '#16235C', '#673510', '#650E41',

  '#001059', '#000000', '#221600', '#002307', '#3E001B', '#410000', '#290037', '#3A0000',
  '#001900', '#3C0000', '#2B244D', '#5B020D', '#000030', '#371800', '#46002A',

  '#4989C1', '#A0A0A0', '#F1CD48', '#6ECAAE', '#F88AC3', '#ED3E2F', '#A35DAD', '#FF8926',
  '#5DB557', '#B26438', '#9080B6', '#FF736C', '#8491F9', '#FEAF30', '#D4589D',

  '#699FD2', '#AFAFAF', '#F3DC74', '#7DD9C1', '#FB9BCB', '#F56B54', '#B97BC0', '#FF9C51',
  '#7AC171', '#C98058', '#AF9BCA', '#FF9185', '#A4A8FB', '#FCC556', '#E080BA',

  '#87B5E4', '#BDBDBD', '#F6EA9C', '#8DE8D4', '#FDACD4', '#FC907B', '#CF9AD4', '#FFAE75',
  '#94CD8B', '#DF9D7A', '#CEB8DE', '#FFAD9F', '#C1C1FD', '#FBD97B', '#ECA5D5',
  
  '#A4CCF6', '#CCCCCC', '#FCF7C4', '#9EF7E7', '#FEBCDC', '#FFB4A3', '#E4B8E8', '#FFC199',
  '#ADD8A6', '#F5BA9C', '#EBD6F4', '#FFC7BC', '#DBDAFE', '#FDEBA1', '#F8C9EE',

  '#2B70AD', '#848484', '#D6AB29', '#5AAA8D', '#DF6FA7', '#CE131B', '#894495', '#E86A11',
  '#419A42', '#994A25', '#75689F', '#E95756', '#6273DC', '#E68E1A', '#BB3880',

  '#145497', '#5D5D5D', '#A07B21', '#427C60', '#AF4D7A', '#A40718', '#6C2F79','#B8441A',
  '#2C7231', '#7F331D', '#5F5487', '#BE3F40', '#4351A7', '#B36819', '#982766',

  '#03397F', '#383838', '#6B4F18', '#24593D', '#802D51', '#7B0113', '#501B5D', '#852418',
  '#1A4B20', '#641E15', '#49406F', '#95272B', '#253274', '#804514', '#76174D',

  '#001E66', '#171717', '#38280C', '#09341A', '#530E2C', '#530008', '#350743', '#52080D',
  '#0B280F', '#49080A', '#352D58', '#6E0F17', '#051545', '#4E260A', '#550635'


];