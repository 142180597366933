/* Copyright 2019 Greyskies. All Rights Reserved. */
import {COUNT_ES_FIELDNAME} from '../AggregationUtils';
export const AGGREGATE_FIELD_NAME = 'Aggregate';
export const DATE_FIELD_NAME = 'DATE';
export const DATE_TIME_FIELD_NAME = 'DATETIME';
export const DATE_FIELD_ES_NAME = 'coAt';
export const NE_ID_ELASTIC_SEARCH_NAME = 'neId';
export const NETWORK_ELEMENT_TREE_PATH = 'NETWORK_ELEMENT_TREE_PATH';
export const NETWORK_ELEMENT_ID = 'NETWORK_ELEMENT_ID';
export const NETWORK_ELEMENT = 'NETWORK_ELEMENT';
export const ELASTIC_SEARCH_PREFIX = 'Elastic_Search_';
export const FULL_TREE_PATH = `${NETWORK_ELEMENT}.${NETWORK_ELEMENT_TREE_PATH}`;
export const ATTRIBUTE = 'ATTRIBUTE';
export const FieldTypes = {
  Numeric: 'NUMERIC',
  NonNumeric: 'NONNUMERIC',
  Mixed: 'MIXED',
  Date: 'DATE',
  NE: 'NETWORK_ELEMENT',
  Ref: 'REF',
  Time : 'TIME',
};
export const FieldTypeText = {
  [FieldTypes.Numeric]: 'Numeric',
  [FieldTypes.NonNumeric]: 'Text',
  [FieldTypes.Mixed]: 'Mixed',
  [FieldTypes.Date]: 'Date',
  [FieldTypes.NE]: 'Network Element',
  [FieldTypes.Ref]: 'Ref',
};

export const RESERVED_NAMES = {
  COUNT_FIELD_NAME: 'count',
  AGGREGATE_FIELD_NAME: 'aggregate',
  ML_LOW_LOWER_FIELD_NAME: 'mlAnomalyLowLowerBoundary',
  ML_LOW_UPPER_FIELD_NAME: 'mlAnomalyLowUpperBoundary',
  ML_MID_LOWER_FIELD_NAME: 'mlAnomalyMidLowerBoundary',
  ML_MID_UPPER_FIELD_NAME: 'mlAnomalyMidUpperBoundary',
  ML_HIGH_LOWER_FIELD_NAME: 'mlAnomalyHighLowerBoundary',
  ML_HIGH_UPPER_FIELD_NAME: 'mlAnomalyHighUpperBoundary',
  ML_SEVERITY_FIELD_NAME: 'mlAnomalySeverity',
  ML_ENSEMBLE_EXPECTED_FIELD_NAME: 'mlAnomalyExpectedSignal',
  ML_BULK_SIGNAL_IDENTIFIER: 'signal',
  ML_INTERPOLATED_VALUE_FIELD_NAME: 'mlAnomalyInterpolatedValue',
};

export function isReservedFieldName(fieldName) {
  return Object.values(RESERVED_NAMES).map(field => field.toLowerCase()).includes(fieldName.toLowerCase());
}

export function isDateFieldName(fieldName){
  return fieldName == DATE_FIELD_NAME;
}

export function isDateESName(esname){
  return esname == DATE_FIELD_ES_NAME;
}

export function isDateField(field){
  return field && isDateFieldName(field.fieldName);
}

export function isCountField(field){
  return field.elasticsearchName == COUNT_ES_FIELDNAME;
}

export function isNetworkElementESName(esname){
  return esname === NE_ID_ELASTIC_SEARCH_NAME;
}

export function isNetworkElementField(field){
  return isNetworkElementESName(field.elasticsearchName);
}

export function isRefField(field){
  return field.ref;
}

export function isNumericField(field){
  return field.type == FieldTypes.Numeric;
}

export function isNonNumericField(field){
  return field.type == FieldTypes.NonNumeric;
}

export function getFieldType(field){
  if(isNetworkElementField(field)){
    return FieldTypes.NE;
  }else if(isRefField(field)){
    return FieldTypes.Ref;
  }else if(isDateField(field)){
    return FieldTypes.Date;
  }

  return field.type;
}

export function isRef(dataType){
  return dataType == FieldTypes.Ref;
}

export function isAggregateField(fieldName){
  return fieldName == AGGREGATE_FIELD_NAME;
}