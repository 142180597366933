/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from "react";
import { Col, Row } from 'react-bootstrap';
import {Icons} from 'react-template';

export default function ExternalPageAlert(props) {
  return (
    <Row>
      <Col xs={12}>
        <div className={`alert ${props.isError ? 'alert-danger' : 'alert-success'}`} >
            <strong> <i className={`ace-icon ${props.isError ? Icons.close : Icons.checkMark}`}></i> &nbsp;
              {props.title}
            </strong>
            <br />{props.message}<br/>
        </div>
      </Col>
    </Row>
  );
}