/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import {Router, hashHistory, withRouter}
  from 'react-router';
import AppStore from 'stores/AppStore';
import AppActions from 'actions/AppActions';
import {admin, reporter} from 'config/sidebarConfig';
import Template from 'components/layout/Template';
import {Loader, UIConstructionUtils} from 'react-template';
import {BASIC_ROUTES, MAIN_ROUTES, LOGIN_ROUTES} from 'config/routesDefaults';
import {ROUTES_COMPONENETS_SCHEME} from 'config/routesScheme';
import { fromJS } from 'immutable';
import {RoutesUtils } from 'js-utils';
import {AbilityContext, Ability} from 'authorization/Authorization';

export default class Routes extends Component {

  constructor(){
    super();
    this.components = ROUTES_COMPONENETS_SCHEME;
    this.onChange = ::this.onChange;
    this.getRoutes = ::this.getRoutes;
    this.checkAndSetRouteComponentToAuthorized = ::this.checkAndSetRouteComponentToAuthorized;
    this.setIndexRoute = ::this.setIndexRoute;
    this.getIndexRoute = ::this.getIndexRoute;
    this.hasPermission = ::this.hasPermission;
    this.getLoginRoutes = ::this.getLoginRoutes;

    this.state = {
      checkingLogin: AppStore.getCheckingLogin(),
    };
  }

  componentDidMount(){
    AppStore.addChangeListener(this.onChange);
    AppActions.getUserData();
    AppActions.getAppConfigs();
    AppActions.getFooterProps();
  }

  componentWillUnmount() {
    AppStore.removeChangeListener(this.onChange);
  }

  onChange = () => {
    if((!_.isEqual(this.state.userData, AppStore.getUserData()))
      || (this.state.checkingLogin != AppStore.getCheckingLogin())
      || (this.state.loggedIn != AppStore.getLoggedIn())
      || (this.state.expired != AppStore.getExpired())){
      if(AppStore.getUserData()){
        this.ability = Ability(AppStore.getUserData());
      }
      this.setState({
        userData: AppStore.getUserData(),
        footerProps: AppStore.getFooterProps(),
        checkingLogin: AppStore.getCheckingLogin(),
        loggedIn: AppStore.getLoggedIn(),
        expired: AppStore.getExpired(),
      });
    }
  };
  
  setIndexRoute(newRoute){
    this.indexRoute = newRoute;
  }

  getIndexRoute(){
    return this.indexRoute;
  }

  hasPermission(elementId){
    if(elementId){
      const elementPermission = this.components[elementId].permission;
      
      return this.ability.can(elementPermission.action, elementPermission.page);
    }

    return true;
  }
  
  getRoutes(){
    const reporterChildRoutes = [];
    const adminChildRoutes = [];
    const sidebarElements = {REPORTER:[], ADMIN: []};
    this.indexRoute = undefined;
    RoutesUtils.addRoutes(
      reporter.elements, sidebarElements.REPORTER, 
      reporterChildRoutes, this.state.userData, this.components, 
      this.checkAndSetRouteComponentToAuthorized, this.getIndexRoute, 
      this.setIndexRoute, this.hasPermission
    );
    reporterChildRoutes.push(...BASIC_ROUTES);
    this.reporterIndexRoute = this.indexRoute ? fromJS(this.indexRoute).toJS() : null;
    if(this.reporterIndexRoute){
      this.reporterIndexRoute.path = `${MAIN_ROUTES.REPORTER}/${this.reporterIndexRoute.path}`;
    }    
    this.indexRoute = null;
    RoutesUtils.addRoutes(admin.elements, sidebarElements.ADMIN, 
      adminChildRoutes, this.state.userData, this.components, 
      this.checkAndSetRouteComponentToAuthorized, this.getIndexRoute, 
      this.setIndexRoute, this.hasPermission
    );
    adminChildRoutes.push(...BASIC_ROUTES);
    this.adminIndexRoute = this.indexRoute ? fromJS(this.indexRoute).toJS() : null;
    if(this.adminIndexRoute){
      this.adminIndexRoute.path = `${MAIN_ROUTES.ADMIN}/${this.adminIndexRoute.path}`;
    } 
    this.indexRoute = this.reporterIndexRoute ? this.reporterIndexRoute : this.adminIndexRoute;
    const AdminTemplateWrapper
    = withRouter(
      (props) =>
        <Template
          sidebarElements={sidebarElements.ADMIN}
          userData = {this.state.userData}
          footerProps = {this.state.footerProps}
          navigationButtonURL={this.reporterIndexRoute ? `/${this.reporterIndexRoute.path}` : null}
          context={MAIN_ROUTES.ADMIN}
          {...props}
        />
    );
    const ReporterTemplateWrapper
      = withRouter(
        (props) =>
          <Template
            sidebarElements={sidebarElements.REPORTER}
            userData = {this.state.userData}
            footerProps = {this.state.footerProps}
            navigationButtonURL={this.adminIndexRoute ? `/${this.adminIndexRoute.path}` : null}
            context={MAIN_ROUTES.REPORTER}
            {...props}
          />
      );
    const TemplateWrapper = this.reporterIndexRoute ? ReporterTemplateWrapper : AdminTemplateWrapper ;

    return [{
      path: `/${MAIN_ROUTES.ADMIN}`,
      indexRoute: this.adminIndexRoute ? {
        onEnter: (nextState, replace) => replace(`/${this.adminIndexRoute.path}`),
      } : undefined,
      component: AdminTemplateWrapper,
      childRoutes: adminChildRoutes,
    },
    {
      path: `/${MAIN_ROUTES.REPORTER}`,
      component: ReporterTemplateWrapper,
      indexRoute: this.reporterIndexRoute ? {
        onEnter: (nextState, replace) => replace(`/${this.reporterIndexRoute.path}`),
      } : undefined,
      childRoutes: reporterChildRoutes,
    },
    {
      path: '/',
      indexRoute: this.indexRoute ? {
        onEnter: (nextState, replace) => replace(`/${this.indexRoute.path}`),
      } : undefined,
      component: TemplateWrapper,
      childRoutes: BASIC_ROUTES,
    },
  ];
  }

  checkAndSetRouteComponentToAuthorized(route, component, componentInfo){
    let isAuthorized = false;
    if (this.ability.can(componentInfo.permission?.action, componentInfo.permission?.page)){

      route.getComponent = componentInfo.component;
      route.query = componentInfo.query;
      delete route.component;
      isAuthorized = true;
    } else{
      route.component = UIConstructionUtils.UNAUTHORIZED_COMPONENET;
    }

    return isAuthorized;
  }

  getLoginRoutes(){
    return LOGIN_ROUTES(this.state.expired);
  }

  render(){
    if(this.state.checkingLogin){
      return <Loader />;
    }else {
      if(!this.state.loggedIn){
        return (
          <AbilityContext.Provider value={this.ability}>
            <Router key='login' history={hashHistory} routes={this.getLoginRoutes()}/>
          </AbilityContext.Provider>      );
      }else if(this.state.userData){
        return (
          <AbilityContext.Provider value={this.ability}>
            <Router key='loggedIn' history={hashHistory} routes={this.getRoutes()}/>
          </AbilityContext.Provider>      );
      }

      return null;
    }

  }
}
