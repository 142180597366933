/* 
 * @preserved
 * Copyright 2019 Greyskies. All Rights Reserved. 
 */

import * as WidgetTypes from './types/WidgetTypes';
import * as ArrayUtils from './ArrayUtils';


export const hasAggregatedData = (aggregatedData) => {
  return (aggregatedData && aggregatedData.VALUE) ;
};

export const hasData = (widgetType, datapoints, aggregatedData) => {
  const isAggregatedTypeWidget = WidgetTypes.isAggregatedTypeWidget(widgetType);
  
  return isAggregatedTypeWidget ? hasAggregatedData(aggregatedData) 
    : ArrayUtils.hasItems(datapoints);
};