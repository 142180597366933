/* Copyright 2019 Greyskies. All Rights Reserved. */

const getSpecialCharsErrorMsg = (label) => {
  return `${label} should not contain any special characters`;
};

export const messages = {
  base: (label, type) => new Error(`${label} must be a ${type}`),
  required: (label) => new Error(`${label} is required`),
  stringMaxLength: (label, limit) => new Error(`${label} should be at most ${limit} characters`),
  stringMinLength: (label, limit) => new Error(`${label} should be at least ${limit} characters`),
  stringAlphaNum: (label) => new Error(getSpecialCharsErrorMsg(label)),
  stringRegex: (regexValidationMsg) => new Error(regexValidationMsg),
  stringLowercase: (label) => new Error(`${label} should be in lowercasr`),
  arrayMinLength: (label, limit) => new Error(`At least ${limit} ${label}${limit == 1 ? ' is' : 's are'} required`),
  arrayMaxLength: (label, limit) => new Error(`At Most ${limit} ${label} is required`),
  arrayInvalidItem: (label) => new Error(`Errors found at 1 or more ${label}`),
  arrayUniqueItems: (label) => new Error(`${label} contain duplicate entries`),
  integerNumber: (label) => new Error(`${label} should be an integer`), 
  numberMaxValue: (label, maxValue) => new Error(`Maximum is ${maxValue}`),
  numberMinValue: (label, maxValue) => new Error(`Minimum is ${maxValue}`),
  invalidName: (label) => new Error(`Invalid ${label} Name`),
  exist: (label) => new Error(`${label} already exists`),
  greater: (label, compareValue) => new Error(`${label} must be greater than ${compareValue}`),
  fileExtension: (extensions) => new Error(`Only the following file type${extensions.length == 1 ? ' is' : 's are'} supported: ${extensions.join(', ')}`),
  fileSize: (size, unit) => new Error(`File size should not exceed ${size} ${unit}`),
  greaterOrEqual: (label, compareValue) => new Error(`${label} must be greater than or equal to ${compareValue}`),
  invalidValue: (label, value) => new Error(`${label} cannot be ${value}`),
  invalidComponent: (label) => new Error(`Error found at ${label}`),
  reservedNames: (label) => new Error(`${label} is reserved`),
  notAllowed: (label) => new Error(`${label} is not allowed`),
  multiple: (label, multiple) => new Error(`${label} should be multiples of the ${multiple}`),
  getSpecialCharsErrorMsg,
  notMatching: (label) => new Error(`${label} do not match`),
  distinct: (label) => new Error(`${label} must be distinct`),
};
