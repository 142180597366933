import * as ArrayUtils from '../ArrayUtils';

export function isSelectionlaceHolderReady(selection){
  return selection?.networkElementDataStructure?.id;
}

export function isPlaceholderValid(placeholderValue, validateSelectionFun){
  if (placeholderValue 
    && (placeholderValue.value
    || (placeholderValue.values?.length) 
    || validateSelectionFun(placeholderValue.selection, {}))) {      
    return true;
  } 

  return false;
}

// DEPRECATED - USED ONLY FOR KAFKA BACKWARD COMPATIBILITY
export function getPlaceholdersValuesIfReadyByIds(widgetPlaceholdersIds, 
  templatesPlaceholdersValues){
  const widgetPlaceholdersValues = [];
  
  let areWidgetPlaceholdersReady = true;

  widgetPlaceholdersIds.forEach(placeholderId => {
    const placeholderValue = templatesPlaceholdersValues[placeholderId];

    areWidgetPlaceholdersReady &= isPlaceholderValid(placeholderValue, isSelectionlaceHolderReady);
    if(areWidgetPlaceholdersReady){
      widgetPlaceholdersValues.push(placeholderValue);
    }else{
      return {areWidgetPlaceholdersReady};
    }
  });

  return {widgetPlaceholdersValues, areWidgetPlaceholdersReady};
}


export function getPlaceholdersValuesIfReadyByPlaceholders(widgetPlaceholders = [], 
  allPlaceholders, validateSelectionFun, requiredUsages){
  const widgetPlaceholdersValues = [];
  
  let areWidgetPlaceholdersReady = true;

  widgetPlaceholders?.forEach(placeholder => {
    const placeholderValue = allPlaceholders[placeholder.id];

    const placeholderInRequiredUsages = ArrayUtils.getCommonList(requiredUsages, 
      placeholder.usages);

    areWidgetPlaceholdersReady &= (placeholderInRequiredUsages?.length == 0
      || isPlaceholderValid(placeholderValue, validateSelectionFun));
    if(areWidgetPlaceholdersReady){
      widgetPlaceholdersValues.push(placeholderValue);
    }else{
      return {areWidgetPlaceholdersReady};
    }
  });

  return {widgetPlaceholdersValues, areWidgetPlaceholdersReady};
}