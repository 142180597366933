/* Copyright 2019 Greyskies. All Rights Reserved. */

import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import * as StringFormatToSpecifierFormat from './StringFormatToSpecifierFormat';
import * as RelativeDateConverter from './RelativeDateConverter';
import { MINUTES, HOURS, DAYS, SECONDS, TIME_RESOLUTION_STEPS, RESOLUTIONS_WITH_CALENDAR_INTERVAL, 
  RESOLUTIONS_LESS_THAN_DAYS, getEpoch } from './ResolutionUtils';

const moment = extendMoment(Moment);
moment.fn.strftime = StringFormatToSpecifierFormat.strftime;

export const WEEK_DAYS_DEFAULT = ['Sunday', 'Monday', 'Tuesday', 'Wednesday',
  'Thursday', 'Friday', 'Saturday'];

export const SHORT_WEEK_DAYS_DEFAULT = ['Sun', 'Mon', 'Tue',
  'Wed', 'Thu', 'Fri', 'Sat'];

moment.updateLocale(Intl.DateTimeFormat().resolvedOptions().locale.toLowerCase(),{
  dateTime: '%x, %X',
  date: '%-m/%-d/%Y',
  time: '%-I:%M:%S %p',
  periods: ['AM', 'PM'],
  days: WEEK_DAYS_DEFAULT,
  shortDays: ['Sun', 'Mon', 'Tue',
    'Wed', 'Thu', 'Fri', 'Sat'],
  months: ['January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August', 'September',
    'October', 'November', 'December'],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May',
    'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
});

if(process?.env?.FIRST_DAY_OF_WEEK && !isNaN(Number(process.env.FIRST_DAY_OF_WEEK))){
  moment.updateLocale(Intl.DateTimeFormat().resolvedOptions().locale.toLowerCase(), { week: {
    dow: Number(process.env.FIRST_DAY_OF_WEEK),
  }});
}

if(process?.env?.TIMEZONE_DATA 
  && process?.env?.TIMEZONE_NAME){
  addTimezone(process?.env?.TIMEZONE_DATA, process?.env?.TIMEZONE_NAME)
}

export const DATE_TIME_WITH_TIME_ZONE_FORMAT = '%d/%m/%Y %I:%M:%S %p';

export const DATE_PICKER_INPUT_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_PICKER_INPUT_DATETIME_FORMAT = 'DD/MM/YYYY HH:mm';

export const DATE_PICKER_LABEL_DATE_FORMAT = '%d/%m/%Y';

export const TIME_TO_MOMENT_FORMAT = 'HH:mm:ss';

export const TWELVE_HRS_TIME_MOMENT_FORMAT = 'hh:mm A';

export const MOMENT_TO_TIME_FORMAT = '%H:%M:%S';

//example: 05/30/2018 11:03:00 AM
//         mm/dd/yyyy HH:MM:SS AM/PM
export const DEFAULT_DATE_FORMAT = '%d/%m/%y %H:%M';

//example: 05/30/2018 11:03:00 AM
//         mm/dd/yyyy HH:MM:SS AM/PM
// export const DEFAULT_DATE_PICKER_FORMAT = '%x %H:%M:%S';

//example: 01 January 2020
export const READABLE_DATE_FORMAT = '%d %B %Y';

//example: 11:03:00 AM
//         HH:MM:SS AM/PM
export const DEFAULT_TIME_FORMAT = '%X';

export const NOW = 'now';
export const DATE_TYPE_RELATIVE = 'relative';

export const MILLI_SEC_FACTOR = 1000;

export const LAST_HOUR = 'lastHour';
export const LAST_24HOURS = 'last24Hours';
export const LAST_7DAYS = 'last7Days';
export const THIS_MONTH = 'thisMonth';

export function dateToEpoch(date) {
  return ~~(+date / 1000);
}

export function epochToMillieSeconds(epoch) {
  return epoch * MILLI_SEC_FACTOR;
}

export function millieSecondsToEpoch(millieSeconds) {
  return millieSeconds / MILLI_SEC_FACTOR;
}

export function dateToCustomDateFormat(format, date) {
  if (format && format.length > 0) {

    return  moment(date).strftime(format);
  } else if (format == '') {
    return dateToEpoch(date);
  }

  return moment(date).strftime(DEFAULT_DATE_FORMAT);
}

export function addTimezone(zoneData, zoneName){
  moment.tz.add(zoneData);
  moment.tz.setDefault(zoneName);
}

export function epochToCustomDateFormat(format, epoch) {
  if (epoch == null) {
    return '';
  }
  const date = new Date(parseInt(epoch) * 1000);

  return dateToCustomDateFormat(format, date);
}

export function getDateTimeZoneFormatted(epochMs) {
  return epochToCustomDateFormat(DATE_TIME_WITH_TIME_ZONE_FORMAT, epochMs);
}

export function dateArrayToEpochArray(dateArray) {
  return [dateToEpoch(dateArray[0]), dateToEpoch(dateArray[1])];
}

export function startOfSpecificDay(momentParam) {
  return momentParam.startOf('day');
}
export function endOfSpecificDay(momentParam) {
  return momentParam.endOf('day');
}

export function startOfSpecificEpoch(epoch) {
  return startOfSpecificDay(epochToMoment(epoch));
}
export function endOfSpecificEpoch(epoch) {
  return endOfSpecificDay(epochToMoment(epoch));
}

export function startOfDay() {
  return startOfSpecificDay(moment());
}
export function endOfDay() {
  return endOfSpecificDay(moment());
}

export function startOfNow() {
  return moment().startOf('minute');
}
export function endOfNow() {
  return moment().endOf('minute');
}
export function startOfLastHour() {
  return startOfNow().subtract({ minute: 59 });
}
export function endOfLastHour() {
  return moment().endOf('minute');
}

export function startOfLast3Hours() {
  return startOfNow().subtract({ minute: 59, hour: 2 });
}

export function startOfLast6Hours() {
  return startOfNow().subtract({ minute: 59, hour: 5 });
}

export function startOfLast12Hours() {
  return startOfNow().subtract({ minute: 59, hour: 11 });
}

export function startOfLast24Hours() {
  return moment().startOf('hour').subtract({ hour: 23 });
}

export function endOfLast24Hours() {
  return moment().endOf('hour');
}

export function startOfYesterday() {
  return startOfDay().subtract(1, 'days');
}
export function endOfYesterday() {
  return endOfDay().subtract(1, 'days');
}
export function startOfLast2Days() {
  return startOfDay().subtract(1, 'days');
}

export function endOfLast2Days() {
  return moment().endOf('day');
}

export function startOfLast7Days() {
  return startOfDay().subtract(6, 'days');
}

export function startOfLast30Days() {
  return startOfDay().subtract(29, 'days');
}

export function startOfThisMonth() {
  return moment().startOf('month');
}
export function endOfThisMonth() {
  return moment().endOf('month');
}
export function startOfLastMonth() {
  return moment().subtract(1, 'month').startOf('month');
}
export function endOfLastMonth() {
  return moment().subtract(1, 'month').endOf('month');
}

export function getTodayRange() {
  return [startOfDay(), endOfDay()];
}
export function getLastHourRange() {
  return [startOfLastHour(), endOfNow()];
}

export function getLast3HoursRange() {
  return [startOfLast3Hours(), endOfNow()];
}

export function getLast6HoursRange() {
  return [startOfLast6Hours(), endOfNow()];
}

export function getLast12HoursRange() {
  return [startOfLast12Hours(), endOfNow()];
}

export function getLast24HoursRange() {
  return [startOfLast24Hours(), endOfLast24Hours()];
}
export function getYesterdayRange() {
  return [startOfYesterday(), endOfYesterday()];
}
export function getLast2DaysRange() {
  return [startOfLast2Days(), endOfDay()];
}
export function getLast7DaysRange() {
  return [startOfLast7Days(), endOfDay()];
}
export function getLast30DaysRange() {
  return [startOfLast30Days(), endOfDay()];
}
export function getThisMonthRange() {
  return [startOfThisMonth(), endOfThisMonth()];
}
export function getLastMonthRange() {
  return [startOfLastMonth(), endOfLastMonth()];
}

export const RANGES = {
  today: {
    displayName: 'Today',
    dateRange: () => dateArrayToEpochArray(getTodayRange()),
    dateRangeMoment: () => getTodayRange(),
    equivalentRange: ['-0d/d', '+0d*d'],
  },
  lastHour: {
    displayName: 'Last Hour',
    dateRange: () => dateArrayToEpochArray(getLastHourRange()),
    dateRangeMoment: () => getLastHourRange(),
    inLiveMode: true,
    equivalentRange: ['-59m/m', '+0m*m'],
  },
  last3Hours: {
    displayName: 'Last 3 Hours',
    dateRange: () => dateArrayToEpochArray(getLast3HoursRange()),
    dateRangeMoment: () => getLast3HoursRange(),
    inLiveMode: true,
    equivalentRange: ['-179m/m', '+0m*m'],
  },
  last6Hours: {
    displayName: 'Last 6 Hours',
    dateRange: () => dateArrayToEpochArray(getLast6HoursRange()),
    dateRangeMoment: () => getLast6HoursRange(),
    inLiveMode: true,
    equivalentRange: ['-359m/m', '+0m*m'],
  },
  last12Hours: {
    displayName: 'Last 12 Hours',
    dateRange: () => dateArrayToEpochArray(getLast12HoursRange()),
    dateRangeMoment: () => getLast12HoursRange(),
    inLiveMode: true,
    equivalentRange: ['-719m/m', '+0m*m'],
  },
  last24Hours: {
    displayName: 'Last 24 Hours',
    dateRange: () => dateArrayToEpochArray(getLast24HoursRange()),
    dateRangeMoment: () => getLast24HoursRange(),
    inLiveMode: true,
    equivalentRange: ['-23h/h', '+0h*h'],
  },
  last7Days: {
    displayName: 'Last 7 Days',
    dateRange: () => dateArrayToEpochArray(getLast7DaysRange()),
    dateRangeMoment: () => getLast7DaysRange(),
    inLiveMode: true,
    equivalentRange: ['-6d/d', '+0d*d'],
  },
  yesterday: {
    displayName: 'Yesterday',
    dateRange: () => dateArrayToEpochArray(getYesterdayRange()),
    dateRangeMoment: () => getYesterdayRange(),
    equivalentRange: ['-1d/d', '-1d*d'],
  },
  last2Days: {
    displayName: 'Last 2 Days',
    dateRange: () => dateArrayToEpochArray(getLast2DaysRange()),
    dateRangeMoment: () => getLast2DaysRange(),
    inLiveMode: true,
    equivalentRange: ['-1d/d', '+0d*d'],
  },
  last30Days: {
    displayName: 'Last 30 Days',
    dateRange: () => dateArrayToEpochArray(getLast30DaysRange()),
    dateRangeMoment: () => getLast30DaysRange(),
    inLiveMode: true,
    equivalentRange: ['-29d/d', '+0d*d'],
  },
  thisMonth: {
    displayName: 'This Month',
    dateRange: () => dateArrayToEpochArray(getThisMonthRange()),
    dateRangeMoment: () => getThisMonthRange(),
    equivalentRange: ['-0d/month', '+0d*month'],
  },
  lastMonth: {
    displayName: 'Previous Month',
    dateRange: () => dateArrayToEpochArray(getLastMonthRange()),
    dateRangeMoment: () => getLastMonthRange(),
    equivalentRange: ['-1month/month', '-1month*month'],
  },
};

export function getRanges(inLiveMode) {
  let ranges = RANGES;

  if (inLiveMode) {
    ranges = Object.keys(ranges).reduce((newObj, key) => {
      if (ranges[key].inLiveMode) {
        newObj[key] = ranges[key];
      }

      return newObj;
    }, {});
  }

  return ranges;
}

export function getEpochsFromRelative(relativeDate) {
  return RANGES[relativeDate].dateRange();
}

export function epochToDateFormat(epoch) {
  const date = new Date(parseInt(epoch) * 1000);

  return dateToCustomDateFormat(null, date);
}

export function epochToReadableDateFormat(epoch) {
  return epochToCustomDateFormat(READABLE_DATE_FORMAT, epoch);
}

export function epochToTimeFormat(epoch) {
  return epochToCustomDateFormat(MOMENT_TO_TIME_FORMAT, epoch);
}

export function dateToTimeFormat(date) {
  const epoch = dateToEpoch(date);

  return epochToCustomDateFormat(MOMENT_TO_TIME_FORMAT, epoch);
}

export function millieSecondsToCustomDateFormat(format, ms) {
  const date = new Date(parseInt(ms));

  return dateToCustomDateFormat(format, date);
}

export function milliSecondsToReadableDateFormat(ms) {
  return millieSecondsToCustomDateFormat(READABLE_DATE_FORMAT, ms);
}

export function customDateFormatToMilleSeconds(format, date) {
  let momentFormat = StringFormatToSpecifierFormat.fromSpecifierToString(format);

  return moment(date, momentFormat).valueOf();
}

export function customDateFormatToEpoch(format, date) {

  let momentFormat = StringFormatToSpecifierFormat.fromSpecifierToString(DEFAULT_DATE_FORMAT);

  if (format && format.length > 0) {
    momentFormat = StringFormatToSpecifierFormat.fromSpecifierToString(format);
  }

  return moment(date, momentFormat).unix();
}

export function timeFormatToMoment(time) {
  return moment(time, TIME_TO_MOMENT_FORMAT);
}

export function epochToMoment(epoch) {
  return moment(epoch * 1000);
}

export function getCurrentDateTimeinMoment() {
  return moment();
}

export function getCurrentDateTimeinMillieSeconds() {
  return +moment();
}

export function getCurrentDateTimeinCustomDateFormat(format) {
  return dateToCustomDateFormat(format, moment());
}


export function dateToMs(date) {
  return +date;
}

export function datePickerRanges(inLiveMode) {
  const ranges = getRanges(inLiveMode);
  const resultDatePickerRanges = {};

  for (const range in ranges) {
    const currentRange = ranges[range];
    resultDatePickerRanges[currentRange.displayName] = currentRange.dateRangeMoment();
  }

  return resultDatePickerRanges;
}

export function getDefaultDate(dataType) {
  var resultDate = moment(new Date());

  if (dataType == 'start') {
    resultDate.set({ h: 0, m: 0 });
  } else if (dataType == 'end') {
    resultDate.set({ h: 23, m: 59 });
  }

  return resultDate;
}
export function dateRangeObjectToAbsoluteEpochs(dateRange) {
  let range;

  if (dateRange) {
    if (dateRange.relativeDate) {
      range = getEpochsFromRelative(dateRange.relativeDate);
    } else {
      let start = dateRange.from;
      let end = dateRange.to;

      if (dateRange.relativeFrom) {
        start = dateToEpoch(RelativeDateConverter.relativeTime(dateRange.relativeFrom));
      }
      if (dateRange.relativeTo) {
        end = dateToEpoch(RelativeDateConverter.relativeTime(dateRange.relativeTo));
      }
      range = [start, end];
    }

    return { from: range[0], to: range[1] };
  }

  return { from: 0, to: 0 };
}
export function getEpochFromRelativeDate(relativeDate, startReferenceDate) {
  return dateToEpoch(RelativeDateConverter.relativeTime(relativeDate, startReferenceDate ? startReferenceDate : null));
}

export function getDateFromRelativeRanges(dateRange, referenceStartDate) {
  let range;

  if (!dateRange) {
    return { from: 0, to: 0 };
  }

  if (dateRange.type === DATE_TYPE_RELATIVE) {
    if (referenceStartDate && referenceStartDate.date) {
      range = getEpochsFromRelativeWithReference(dateRange, referenceStartDate);
    } else {
      range = getEpochsFromRelative(dateRange.step);
    }
  } else {
    range = getEpochsFromAbsolute(dateRange, referenceStartDate);
  }

  return { from: range[0], to: range[1] };
}

function getEpochsFromAbsolute(dateRange, referenceStartDate) {
  let start = dateRange.from;
  let end = dateRange.to;

  if (dateRange.relativeFrom) {
    start = dateToEpoch(RelativeDateConverter.relativeTime(dateRange.relativeFrom, referenceStartDate ? referenceStartDate.date : null));
  }
  if (dateRange.relativeTo) {
    end = dateToEpoch(RelativeDateConverter.relativeTime(dateRange.relativeTo, referenceStartDate ? referenceStartDate.date : null));
  }
  return [start, end];
}

function getEpochsFromRelativeWithReference(dateRange, referenceStartDate) {
  const equivalentRange = RANGES[dateRange.step].equivalentRange;

  const from = getEpochFromRelativeDate(equivalentRange[0], referenceStartDate.date);
  const to = getEpochFromRelativeDate(equivalentRange[1], referenceStartDate.date);

  return [from, to];
}

export function getParsedRelativeDate(relativeDate) {
  const { direction, interval, unit, startOrEnd, startOrEndUnit }
    = RelativeDateConverter.getUnitAndIntervalFromString(relativeDate);
  const parsedRelativeDate = {
    interval: direction == '-' ? interval * -1 : parseInt(interval),
    unit: unit.toUpperCase(),
  };

  if (startOrEnd && startOrEndUnit) {
    parsedRelativeDate.alignment = {
      direction: startOrEnd == '*' ? 'END' : 'START',
      unit: startOrEndUnit.toUpperCase(),
    };
  }

  return parsedRelativeDate;
}

export function getDateRangeServerObj(dateRange) {
  const dateRangeServerObj = { ...dateRange };

  dateRangeServerObj.parsedRelativeFrom = null;
  dateRangeServerObj.parsedRelativeTo = null;
  if (dateRange.relativeDate) {
    const equivalentRange = RANGES[dateRange.relativeDate].equivalentRange;

    dateRangeServerObj.parsedRelativeFrom = getParsedRelativeDate(equivalentRange[0]);
    dateRangeServerObj.parsedRelativeTo = getParsedRelativeDate(equivalentRange[1]);
  } else {
    if (dateRange.relativeFrom && dateRange.relativeFrom != 'now') {
      dateRangeServerObj.parsedRelativeFrom = getParsedRelativeDate(dateRange.relativeFrom);
    }
    if (dateRange.relativeTo && dateRange.relativeTo != 'now') {
      dateRangeServerObj.parsedRelativeTo = getParsedRelativeDate(dateRange.relativeTo);
    }
  }

  return dateRangeServerObj;
}

export function updateDateRangeFromAndTo(dateRange) {
  const fromAndTo = dateRangeObjectToAbsoluteEpochs(dateRange);

  return { ...dateRange, ...fromAndTo };
}

export function setDateRangeForQuery(query, dateRange) {
  const fromAndTo = dateRangeObjectToAbsoluteEpochs(dateRange);

  query.from = fromAndTo.from;
  query.to = fromAndTo.to;
}

export function getTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function convert24hrToTime12hrFormat(time) {
  return moment(time, [TIME_TO_MOMENT_FORMAT]).format(TWELVE_HRS_TIME_MOMENT_FORMAT);
}

export function convertUserZoneTimeToGMT(userTime) {
  const offsetInMinutes = new Date().getTimezoneOffset();
  const gmtTime = moment("2017-03-13" + ' ' + userTime).add(offsetInMinutes, 'minute');

  return gmtTime.format(TIME_TO_MOMENT_FORMAT);
}

export function convertGmtToUserZoneTime(gmtTime) {
  const offsetInMinutes = new Date().getTimezoneOffset();
  const userTime = moment("2017-03-13" + ' ' + gmtTime).subtract(offsetInMinutes, 'minute');

  return userTime.format(TIME_TO_MOMENT_FORMAT);
}

export function getEpochFromEpochString(epochString) {
  return isNaN(~~epochString)
    || !epochString ? null : ~~epochString;
}

export function getFullDateRange(start, end, step, unit, inMilli){
  const range = getRange(start, end);
  
  return Array.from(range.by(unit, {step})).map(date => 
    inMilli ? epochToMillieSeconds(dateToEpoch(date.startOf(unit))) : dateToEpoch(date.startOf(unit)));
}

export function getRange(start, end){
  return moment.range(epochToMoment(start), epochToMoment(end));
}

export function getTimezone(){
  return moment.tz.guess();
}

export function getTimezoneOffsetForEpoch(epoch){
  return moment.tz.zone(getTimezone()).offset(epochToMillieSeconds(epoch));
}

export function getTimezoneDiff(startEpoch, endEpoch){
  return getTimezoneOffsetForEpoch(startEpoch) 
    - getTimezoneOffsetForEpoch(endEpoch);
}

export function addResolutionToEpochUsingMoment(startEpoch, step, unit){
  const startMoment = epochToMoment(startEpoch);
  const isStartisDSTShift = startMoment.isDST() && !epochToMoment(startEpoch - 1).isDST();

  if(unit.toUpperCase() == DAYS || RESOLUTIONS_WITH_CALENDAR_INTERVAL.includes(unit.toUpperCase())){
    return dateToEpoch(startMoment.add(step, unit).startOf(unit.toLowerCase()));
  }

  const stepEpoch = getEpoch(step, unit.toUpperCase());
  const newEpoch = startEpoch + stepEpoch;
  const newEpochShift = getTimezoneDiff(newEpoch - 1, newEpoch) * 60;
  let nextEpoch = newEpoch - getTimezoneDiff(startEpoch, newEpoch) * 60;
  
  if(newEpochShift > 0){
    nextEpoch += newEpochShift;
  }

  return isStartisDSTShift && stepEpoch > TIME_RESOLUTION_STEPS.HOURS ?
    nextEpoch - TIME_RESOLUTION_STEPS.HOURS : nextEpoch;
}

export function getDateRange(start, end, step, unit, inMilli){
  const isDividenOf24Hrs = step <= 30 && (24 * 60) % step == 0;
  const shouldAddDuplicateHrInWinterShift = unit.toUpperCase() == MINUTES
    && getTimezoneDiff(start - 1, end) < 1 && isDividenOf24Hrs;
  const maxDate = inMilli ? epochToMillieSeconds(end) : end;
  let dateRange;

  if(step != 1 && RESOLUTIONS_LESS_THAN_DAYS.includes(unit.toUpperCase()) 
    && !shouldAddDuplicateHrInWinterShift){
    dateRange =  getDateRangeForResolutionsLessThanDays(start, end, step, unit, inMilli);
  } else {
    dateRange = getFullDateRange(start, end, step, unit, inMilli);
  }

  if(dateRange[dateRange.length - 1] != maxDate){
    dateRange.push(maxDate);
  }
  
  return dateRange;
}

export function getDateRangeForResolutionsLessThanDays(dateMin, dateMax, step, unit, inMilli){
  let counter = 1;
  const dateRange = [inMilli ? epochToMillieSeconds(dateMin) : dateMin];
  let nextEpoch = addResolutionToEpochUsingMoment(dateMin,  step * counter++, unit); 

  while(nextEpoch <= dateMax){
    dateRange.push(inMilli ? epochToMillieSeconds(nextEpoch) : nextEpoch);
    nextEpoch = addResolutionToEpochUsingMoment(dateMin, step * counter++, unit);
  }

  return dateRange;
}

export function getDSTShift(mainSeriesDomainMin, mainSeriesDomainMax){
  return (getTimezoneDiff(mainSeriesDomainMin / 1000, 
    mainSeriesDomainMax / 1000)) * 60;
}

export function getNextEpoch(start, step, unit, isResolutionGreaterThan1Hour){
  const startDate = epochToMoment(start);

  const nextEpoch = startDate.clone().add(step, unit);

  if(!RESOLUTIONS_WITH_CALENDAR_INTERVAL.includes(unit.toUpperCase())){
    // adjust for data point containing dst crossover for res < 1day
    if(unit.toUpperCase() != DAYS) {
      const dstShift = getDSTShift(nextEpoch.clone().subtract(1, SECONDS.toLowerCase()),
        startDate);

      if(dstShift != 0){
        nextEpoch.add(dstShift, SECONDS.toLowerCase());
      }
    }

    // start date is the first moment in summer time and 1hr < res < 1week
    if(startDate.isDST() 
      && !startDate.clone().subtract(1, SECONDS.toLowerCase()).isDST()
      && isResolutionGreaterThan1Hour){
      nextEpoch.subtract(1, HOURS.toLowerCase());
    }

    // next epoch start date is the first moment in winter time and 1hr < res < 1day
    if(nextEpoch.clone().subtract(1, SECONDS.toLowerCase()).isDST() 
      && !nextEpoch.isDST()
      && isResolutionGreaterThan1Hour
      && unit.toUpperCase() != DAYS){
      nextEpoch.add(1, HOURS.toLowerCase());
    }
  }

  return +nextEpoch;
}
