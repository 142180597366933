/* Copyright 2019 Greyskies. All Rights Reserved. */
export const TIME_DATA_TYPE = 'TIME';
export const COUNT_DATA_TYPE = 'COUNT';
export const INTEGER_DATA_TYPE = 'INTEGER';
export const DOUBLE_DATA_TYPE = 'DOUBLE';
export const LONG_DATA_TYPE = 'LONG';
export const NUMBER_DATA_TYPE = 'NUMBER';
export const STRING_DATA_TYPE = 'STRING';
export const OBJECT_DATA_TYPE = 'OBJECT';
export const GEO_LOCATION_DATA_TYPE = 'GEO_LOCATION';
export const PERCENT_DATA_TYPE = 'PERCENT';
export const DEFAULT_TIME_UNIT = 'seconds';
export const DEFAULT_PERCENT_UNIT = '%';
export const ATTRIBUTE = 'ATTRIBUTE';
export const ATTRIBUTETYPE = 'ATTRIBUTE_TYPE';
export const BOOLEAN_DATA_TYPE = 'BOOLEAN';
export const REF_DATA_TYPE = 'REF';

export function isGeoLocationType(type) {
  return GEO_LOCATION_DATA_TYPE == type;
}

export function isObjectFieldType(field){
  return field && field.attributeType && isObjectType(field.attributeType.dataType);
}

export function isObjectType(type){
  return OBJECT_DATA_TYPE == type
}

export function isTimeType(type) {
  return TIME_DATA_TYPE == type;
}

export function isCountType(type) {
  return COUNT_DATA_TYPE == type;
}

export function isStringType(type) {
  return STRING_DATA_TYPE == type;
}

export function isBooleanDataType(type){
  return BOOLEAN_DATA_TYPE == type;
}

export function isIntegerNumberType(type){
  return INTEGER_DATA_TYPE == type 
    || LONG_DATA_TYPE == type ;
}

export function isDoubleNumberType(type){
  return DOUBLE_DATA_TYPE == type 
    || isTimeType(type)
    || isCountType(type)
	  || isPercentDataType(type);
}

export function isNumericType(type) {
  return (isIntegerNumberType(type) || isDoubleNumberType(type))
    && !isGeoLocationType(type);
}

export function getTypeKey(type){
  if(isIntegerNumberType(type)){
    return INTEGER_DATA_TYPE;
  } else if(isDoubleNumberType(type)){
    return DOUBLE_DATA_TYPE;
  } else if(isBooleanDataType(type)){
    return BOOLEAN_DATA_TYPE;
  }

  return STRING_DATA_TYPE;
}

export function getDataTypeKey(type) {
  if(isTimeType(type) || isCountType(type)){
    return type;
  } else if(isNumericType(type)){
    return NUMBER_DATA_TYPE;
  }

  return type;
}

export const isPercentDataType = (dataType) => {
  return PERCENT_DATA_TYPE == dataType;
};

export const getAttributeTypeUnit = (attributeType) => {
  let unit = attributeType.unit;

  if (attributeType.dataType == TIME_DATA_TYPE) {
    unit = DEFAULT_TIME_UNIT;
  } else if (attributeType.dataType == PERCENT_DATA_TYPE) {
    unit = DEFAULT_PERCENT_UNIT;
  }

  return unit;
};

export const isFilterableAttributeType = (attributeType, filtersOptions = {}) => {
  return attributeType != GEO_LOCATION_DATA_TYPE; 
};

export function getRawType(type) {
  if (isNumericType(type)) {
    return 'NUMERIC';
  }

  return 'NONNUMERIC';
}
