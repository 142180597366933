/* Copyright 2019 Greyskies. All Rights Reserved. */

import 'react-template/lib/css/main.css';
import 'web-style-guide/css/login.less';
import React from 'react';

import {FormWithFooter, Loader, dialogs} from 'react-template';
import LoginAPI from 'api/LoginAPI';
import inputValidator from 'validations/AccountManagementValidationSchema';
import { withRouter } from 'react-router';
import ExternalPageContainer from './ExternalPageContainer';
import * as AccountManagementUtils from 'utils/AccountManagementUtils';
import ExternalPageAlert from "./ExternalPageAlert";
import { RequestUtils } from 'js-utils';

import 'styles/login/resetPassword.less';

@withRouter
export default class ResetPassword extends React.Component {

  constructor(props){
    super(props);
    const token = props.location?.query?.token;

    this.state = {
      password: '',
      confirmPassword: '',
      token,
      showPassword: false,
      showConfirmPassword: false,
      showCurrentPassword: false,
      loading: true,
      resetLoading: false,
      error: false,
    };
    this.submitNewPassword = this.submitNewPassword.bind(this);
    this.inputValidation = this.inputValidation.bind(this);
    this.validateURLToken = this.validateURLToken.bind(this);    
  }

  componentDidMount() {
    this.validateURLToken();
  }
  
  validateURLToken(){
    LoginAPI.validateURLToken(this.state.token, 'FORGET_PASSWORD').then(value => {
      if(value){
        this.setState({
          loading: false,
        });
      }else{
        this.props.router.push({
          pathname: '/error',
        });
      }
    });
  }

  submitNewPassword(){
    const resetPasswordData = {
      token: this.state.token,
      newPassword: this.state.password,
    };
    this.setState({resetLoading: true, error: false}, () => {
      LoginAPI.savePassword(resetPasswordData).then(() => {
        this.props.router.push({
          pathname: '/',
          state: {
            successMessage: 'RESET_PASSWORD',
          },
        });
      })
        .catch((error) => {
          if(error && error.errorStatus == RequestUtils.ERR_CODES.FORBIDDEN) {
            this.props.router.push({
              pathname: '/error',
            });
          }else {
            this.setState({resetLoading: false, error: true});
          }
        });
    });
  }

  inputValidation(key, value, state){
    if(key == 'confirmPassword'){
      return AccountManagementUtils.validateConfirmPassword(value, state?.password || this.state.password);
    }
    return inputValidator(key, value);
  }
  
  render(){
    if(this.state.loading){
      return <Loader />;
    }

    return (
      <ExternalPageContainer title='Reset Password'>
        {this.state.error ? <ExternalPageAlert isError title='Error While Resetting Password' 
          message='Something went wrong. Please refresh the page and try again'/> : null}

        <FormWithFooter
          inputs={AccountManagementUtils.getPasswordsFormInputs(this.state, this.props.hasCurrentPassword, this.setState.bind(this))}
          validationFunction={this.inputValidation}
          hideCancelButton
          expandFullWidth
          noFooterBorder
          onChange={(result) => this.setState({...result})}
          onSubmit={this.submitNewPassword}
          disableSaveButton={this.state.resetLoading}
        />
      </ExternalPageContainer>
    );
	}
}
