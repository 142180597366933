/* Copyright 2019 Greyskies. All Rights Reserved. */

export const getContrast = (hexcolor) => {

  if(!hexcolor){
    return null;
  }
  if (hexcolor.slice(0, 1) === '#') {
    hexcolor = hexcolor.slice(1);
  }
  
  if (hexcolor.length === 3) {
    hexcolor = hexcolor.split('').map(hex => hex + hex).join('');
  }

  const r = parseInt(hexcolor.substr(0, 2), 16);
  const g = parseInt(hexcolor.substr(2, 2), 16);
  const b = parseInt(hexcolor.substr(4, 2), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  
  return (yiq >= 128) ? 'black' : 'white';
};