/* Copyright 2019 Greyskies. All Rights Reserved. */

export function addNodeAdditionalParams(networkElementId, data, node){
  if(networkElementId != null){
    data.networkElementId = networkElementId;
  }
  if(node != null){
    data.selectedGroup = node.nodeType;
    data.dataStructureId = node.dataStructureId;
  }
}