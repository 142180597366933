/* Copyright 2019 Greyskies. All Rights Reserved. */

import  greyskies from './COLORS2';
import eventum from './COLORS';

const GREYSKIES_PALETTE = 'greyskies';
const EVENTUM_PALETTE = 'eventum';

export const GREYSKIES_COLOR = greyskies;
export const COLOR = eventum;

export function getColorPaletteType(paletteType) {
  return paletteType || EVENTUM_PALETTE;
}

export function getColorPalette(paletteType) {
  switch(getColorPaletteType(paletteType)){
    case GREYSKIES_PALETTE: 
      return GREYSKIES_COLOR;
    default:
      return COLOR;
  }
}

export function getMainColor(paletteType) {
  const selected = getColorPalette(paletteType);

  return selected[0];
}

export function getNoFillColor() {
  return 'transparent';
}

export function getRangesColor(paletteType) {
  switch(getColorPaletteType(paletteType)){
    case GREYSKIES_PALETTE: 
      return [GREYSKIES_COLOR[8], GREYSKIES_COLOR[2], GREYSKIES_COLOR[5]];
    default:
      return [COLOR[6], COLOR[2], COLOR[0]];
  }
}

export function getAvailableColors(paletteType, usedColors){
  return getColorPalette(paletteType).filter(individualColor =>
    !usedColors.includes(individualColor));
}

export function getColorPickerPalette(paletteType, numOfColors = 10){
  return getColorPalette(paletteType).slice(0, numOfColors);
}

export function getPanelActionBarColorPickerPalette(paletteType, numOfColors = 9){
  return ['#FFFFFF', ...getColorPalette(paletteType).slice(0, numOfColors)];
}

export function getNoFillColorPickerPalette(paletteType, numOfColors = 9){
  return [getNoFillColor(), ...getColorPalette(paletteType).slice(0, numOfColors)];
}
