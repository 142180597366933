/* Copyright 2019 Greyskies. All Rights Reserved. */
import * as DateTimeUtils from './DateTimeUtils';
import * as UnitConversionUtils from './UnitConversionUtils';
import _ from 'lodash';

const DATE = 'DATE';
const NETWORK_ELEMENT = 'NETWORK_ELEMENT';
const NETWORK_ELEMENT_TREE_PATH = 'NETWORK_ELEMENT_TREE_PATH';
const WHOLE_INTEGERS_REGEX = new RegExp(/^\d+$/);

export const isBlank = (object) => {
  return object === undefined || object === null;
};

export const isEmpty = (object) => {
  return object === undefined || object === null || object === '' || (Array.isArray(object) && !object.length);
};

export const isEmptyArray = (arr) => {
  return isEmpty(arr) || !arr.length;
};

export const isEmptyObject = (object) => {
  return isEmpty(object) || _.isEmpty(object);
};

export const isEmptyString = (object) => {
  return isEmpty(object) || object.trim() == '';
};

export const isStringBoolean = (string) => {
  return string === 'true' || string === 'false';
};

export const isIntegerValue = (value) => { 
  return WHOLE_INTEGERS_REGEX.test(value);
};

export const stringToBoolean = (string) => {
  if(string === 'true'){
    return true;
  }else if(string === 'false'){
    return false;
  }

  return string;
};

export const isValidNumber = (number) => {
  return typeof Number(number) === 'number';
};

export function scaleBetween(unscaledNum, minAllowed, maxAllowed, min, max) {
  return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
}

export function capitalize(string) {
  return string.replace(/\w\S*/g, (str) => {
    return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
  });
}

export function constructMatchedBucketExtraFields(matchedBucketExtraFields, aggregatedData, precision){
  const metricExtraFields = [];
  
  if(matchedBucketExtraFields && matchedBucketExtraFields.length > 0 
    && aggregatedData){
    matchedBucketExtraFields.forEach(bucketField => {
      if(bucketField.field && bucketField.field.id){
        const fieldName = bucketField.field.fieldName;
        const value = getFieldValue(fieldName, aggregatedData, precision);
        
        metricExtraFields.push(capitalize(fieldName.replace('_', ' '))+": "+value);
      }
    });
  }
  return metricExtraFields;
}

function getFieldValue(fieldName, dataPoint, precision){
  switch(fieldName){
    case DATE:
      const defaultDateFormat = DateTimeUtils.DEFAULT_DATE_FORMAT;

      return DateTimeUtils.epochToCustomDateFormat(defaultDateFormat, dataPoint[fieldName]);
    case NETWORK_ELEMENT:
      return dataPoint[fieldName]?.[NETWORK_ELEMENT_TREE_PATH];
    default:
      if(precision && !isNaN(dataPoint[fieldName])) {
        return UnitConversionUtils.getFinalValueForm(Number(dataPoint[fieldName]), undefined, '%', precision, undefined);
      }
      return dataPoint[fieldName];
  }
}

export function isPropertyValid(obj, property){
  return obj.hasOwnProperty(property) && obj[property] && !isNaN(obj[property]);
}

export function getObjectValueByPropertyPath(obj, path){
  return path.split('.').reduce((res, prop) => res[prop], obj);
}