/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {NavDropdown, MenuItem} from 'react-bootstrap';
import ChangeContextButton from './ChangeContextButton';
import Downloads from './Downloads';
import {Icons} from 'react-template';
import {MAIN_ROUTES} from 'config/routesDefaults';
import SwitchThemeMode from './SwitchThemeMode';

const NavbarButtons = (props) => {
  let contextButton;
  
  if(props.showContextBtn) {
    contextButton = <ChangeContextButton
      context={props.context} changeContext = {props.changeContext} 
      />;
  }     

  return (
    <ul className="nav navbar-nav hidden-xs">
      {props.context == MAIN_ROUTES.REPORTER ?
        <li className="navbar-notification navbar-action">
          <Downloads/>
        </li>
       : null 
      }
      <li className="navbar-notification navbar-action">
        <SwitchThemeMode className = 'switchThemeMode-btn'/>
      </li>
      {contextButton}
      <NavDropdown id="logout" className="admin-logout" title={
        <span>
            Welcome, <span>{props.username}</span>
            <i className= {`margLft5 ${Icons.user}`}></i>
          </span>
        }>
        <MenuItem href={`#/${props.context}/userProfile`}>
          <i className={Icons.user}></i> Profile
        </MenuItem>
        <MenuItem href="logout">
          <i className={Icons.signOut}></i> Logout
        </MenuItem>
      </NavDropdown>
    </ul>
  );
};

export default NavbarButtons;
