/* Copyright 2019 Greyskies. All Rights Reserved. */
import {fromJS} from 'immutable';

export const TIME_RESOLUTION_STEPS = {
  YEARS:   60 * 60 * 24 * 365,
  MONTHS:  60 * 60 * 24 * 30,
  WEEKS:   60 * 60 * 24 * 7,
  DAYS:    60 * 60 * 24,  
  HOURS:   60 * 60,
  MINUTES: 60,
  SECONDS: 1,
};

export const INDEX_INTERVAL_ORDER = {
  ONE_HOUR: 0,
  QUARTER_A_DAY: 1,
  HALF_DAY: 2,
  ONE_DAY: 3,
  ONE_WEEK: 4,
  ONE_MONTH: 5,
  ONE_YEAR: 6,
};

export const INDEX_INTERVALS = [
  {value: 'ONE_HOUR', text:'One Hour'},
  {value: 'QUARTER_A_DAY', text:'Quarter Day'},
  {value: 'HALF_DAY', text:'Half Day'},
  {value: 'ONE_DAY', text:'One Day'},
  {value: 'ONE_WEEK', text:'One Week'},
  {value: 'ONE_MONTH', text:'One Month'},
  {value: 'ONE_YEAR', text:'One Year'},
];

export const MAX_INDEX_INTERVAL_ORDER = 6;
export const INITIAL_TIME_INTERVAL = 1;
export const SECONDS = 'SECONDS';
export const MINUTES = 'MINUTES';
export const HOURS = 'HOURS';
export const DAYS = 'DAYS';
export const WEEKS = 'WEEKS';
export const MONTHS = 'MONTHS';
export const YEARS = 'YEARS';

export const TIME_RESOLUTION_OPTIONS = [SECONDS, MINUTES, HOURS,
  DAYS, WEEKS, MONTHS, YEARS];

export const RESOLUTIONS_WITH_CALENDAR_INTERVAL = [YEARS, MONTHS, WEEKS];
export const RESOLUTIONS_LESS_THAN_DAYS = [HOURS, MINUTES, SECONDS];

export const MONTHLY_DEFAULT_RESOLUTION = 1;
export const AUTO_RESOLUTION = 'AUTO';

export const getEpoch = (interval, unit) => {
  return interval * TIME_RESOLUTION_STEPS[unit]; 
};

export const epochToBestResolution = (epoch) => {
  let bestResolution = {
    step: 1,
    unit: 'seconds',
  };
  const revesedResolutionOptions = fromJS(TIME_RESOLUTION_OPTIONS).toJS();

  revesedResolutionOptions.reverse().every((unit) =>{
    const interval = TIME_RESOLUTION_STEPS[unit];

    if(epoch % interval == 0 && !(epoch / interval != 1 && RESOLUTIONS_WITH_CALENDAR_INTERVAL.includes(unit))){
      bestResolution =  {
        step: epoch / interval,
        unit: unit.toLowerCase(),
      }
      return false;
    }    
    return true;
  });

  return bestResolution;
}

export const epochToDateRange = (epoch) => {
  let rest = epoch;
  const dateRange = [];

  Object.keys(TIME_RESOLUTION_STEPS).forEach( (step) =>{
    const interval = TIME_RESOLUTION_STEPS[step];

    if(rest >= interval){
      const numberOfSteps = Math.floor(rest / interval);
      const stepStr = numberOfSteps > 1 ? step.toLowerCase()
        : step.substr(0, step.length - 1).toLowerCase();

      rest -= numberOfSteps * interval;      
      dateRange.push(`${numberOfSteps} ${stepStr}`);
    }    
  });

  return dateRange.join(', ');
};


export function isResolutionIntervalMonths(resolutionInterval){
  return resolutionInterval.toUpperCase() === 'MONTHS';
}