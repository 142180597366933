/* Copyright 2019 Greyskies. All Rights Reserved. */

export const VIEW = 'view';
export const CREATE = 'create';
export const ACTIVATE = 'activate';
export const TRANSFER = 'transfer';
export const PERSISTENCE = 'persistence';
export const TAGS_MAPPING = 'tags mapping';


export const EVENTS = 'events';
export const TAGS = 'tag_definition';
export const COLLECTION_PROFILE = 'collection_profile';
export const SYNTHETIC_COLLECTION_PROFILE = 'synthetic_collection_profile';
export const CONFIGURATION_COLLECTION_PROFILE = 'configuration_collection_profile';
export const SQL_COLLECTION_PROFILE = 'sql_collection_profile';
export const NETWORK_ELEMENT_MANAGEMENT = 'network_element_management';
export const ROLL_UP_PLAN = 'roll_up_plan';
export const LAKEHOUSE_RETENTION_PLAN = 'lakehouse_retention';
export const LOOKUP = 'lookup';
export const DATA_BINDING = 'data_binding';
export const SYNTHETIC_BINDING = 'synthetic_binding';
export const CONFIGURATION_BINDING = 'configuration_binding';
export const SQL_BINDING = 'sql_binding';
export const PUSH_BASED_DATA_BINDING_MAPPING = 'manage_push_based_data_binding_mapping';
export const MANAGE_USERS = 'manage_users';
export const MANAGE_NBI_APPLICATION_CLIENTS = 'manage_nbi_application_clients';
export const MANAGE_GROUPS = 'manage_groups';
export const ACCESS_MANAGEMENT = 'access_management';
export const EVENT_HEALING = 'event_healing';
export const RECORDS = 'records';
export const AUDITING_TEMPLATES = 'auditing_templates';
export const TEMPLATES_PLACEHOLDERS = 'templates_placeholders';
export const TOPOLOGY_SETTINGS = 'topology_settings';
export const NBI = 'nbi';
export const DESIGN_SPACE_ORGANIZER = 'design_space_organizer';
export const DASHBOARD = 'dashboard';
export const REPORT = 'report';
export const OG = 'organization_groups';
export const DATA_MODEL_TREE = 'data_model_tree';

export const COLLECTION_METHOD = 'collection_method';

export const ROUTING_GROUP_KEY = 'routingGroups';
export const ROUTING_GROUP = 'routing_group';

export const DATA_FLOW_KEY = 'dataFlows';
export const DATA_FLOW = 'data_flow';

export const UNREACHABILITY_PLAN_KEY = 'unreachabilityPlans';
export const UNREACHABILITY_PLAN = 'unreachability_plan';

export const ML_JOBS = 'ml_jobs';
export const CP_BLOCK_LIST = 'cp_block_list';
export const CONFIGURATION_LISTING = 'archived_files';
export const CONFIGURATION_VIEWER = 'configuration_view';
export const CONFIGURATION_DIFF_VIEWER = 'configuration_difference';
export const AUTOMATION_WORKFLOW = 'automation_workflow';
export const ATTRIBUTE_TYPE = 'attribute_types';
export const LDAP_CONFIG = 'ldap_config';
export const FILE_EXPORT = 'file_export';
export const SCHEDULED_FILE_EXPORTS = 'scheduled_file_exports';
export const CUSTOM_ANALYTICS = 'custom_analytics_project';
export const ANALYTIC_JOBS = 'custom_analytics_job';
export const ANALYTIC_LOGS = 'custom_analytics_logs';
export const FFAG = 'fga';
export const OTEL = 'otel';
export const TRACE = 'trace';
