/* Copyright 2019 Greyskies. All Rights Reserved. */

import keyMirror from 'keymirror';
export default keyMirror({
  LIST_ALL_Severities: null,
  ADD_EVENT: null,
  LIST_ALL_TYPES: null,
  LIST_ALL_Resolutions: null,
  LIST_EVENT_ARGUMENTS: null,
  LIST_ALL_RECORD_TYPES: null,
  LIST_ALL_ML_JOBS: null,
  GET_EVENT: null,
  GET_EVENT_LIST: null,
  DELETE_EVENT: null,
  RESET_EVENT: null,
  LIST_ALL_EVENT_CATEGORIES: null,
  LIST_ALL_EVENT_WORKFLOW_NAMES: null,
  LIST_ALL_SNMP_VARIABLE_TYPES: null,
  LIST_ALL_TAGS: null,
});
