/* Copyright 2019 Greyskies. All Rights Reserved. */
import * as DateTimeUtils from './DateTimeUtils';

export const blobToUrl = blob => URL.createObjectURL(blob);

export function saveAs(url, fileName){
  const a = document.createElement('a');

  a.download = fileName;
  a.href = url;
  a.target = '_blank';
  a.dispatchEvent(new MouseEvent('click'));
}

export function revokeUrl(url){
  setTimeout(() => {
    URL.revokeObjectURL(url);
  }, 100);
}

export function transformDataToUrl(message, type){
  const binary = atob(message.replace(/\s/g, ''));
  const buffer = new ArrayBuffer(binary.length);
  const array = new Uint8Array(buffer);

  for (let i = 0; i < binary.length; i++) {
    array[i] = binary.charCodeAt(i);
  }
  const urlType = `application/${type}`;
  const blob = new Blob([ array ], {
    type : urlType,
  });

  return blobToUrl(blob);
}

export function getFileExtension(fname){
  return fname.slice((Math.max(0, fname.lastIndexOf('.')) || Infinity) + 1);
}


export function downloadFileURL(myBlob, fileName, contentType){
  let blob;
  
  if(contentType){
    blob = new Blob([myBlob], {
      type: contentType,
    });
  } else {
    blob = new Blob(["\ufeff", myBlob]);
  }
  const objectURL = URL.createObjectURL(blob);

  saveAs(objectURL, fileName);
  revokeUrl(objectURL);
}

export function downloadFileByType(fileContent, fileName, contentType){
  const url = transformDataToUrl(btoa(fileContent), contentType);

  saveAs(url, fileName);
  revokeUrl(url);
}

export function getFileSize(size) {
  if (size === 0) {
    return '0 Bytes';
  }
  const k = 1000;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(size) / Math.log(k));

  return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
}

export function generateFileName(name){
  const FILE_NAME_TIMESTAMP = '%Y_%-d_%-m_%-H_%M_%S';
  const newName = name.replace(/:/g, '-');

  return `${newName}-${DateTimeUtils.getCurrentDateTimeinCustomDateFormat(
    FILE_NAME_TIMESTAMP)}`;
}
