/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as WPDefaults from 'config/PermissionsDefaults';
import {PAGES} from 'config/routesDefaults';
import *  as COMPONENTS from 'utils/getComponents';
import AnalystActivation from 'components/pages/Events/AnalystActivation';
import AutomationWorkflowActivation 
  from 'components/pages/AutomationWorkflow/AutomationWorkflowActivation';
import * as AutomationRunsInfoDefaults from 'config/AutomationRunsInfoDefaults';
import * as RollUpPlansDefaults from 'config/RollUpPlansDefaults';

export const ROUTES_COMPONENETS_SCHEME = {
  eventHealingVerificationPage:{
    path: 'event/analystSelfHealingActionActivation',
    component: AnalystActivation,
  },
  automationWorkflowVerificationPage:{
    path: 'workflow/workflowActionActivation',
    component: AutomationWorkflowActivation,
  },
  collectionProfiles: {
    component: COMPONENTS.CollectionProfile,
    permission: {
      page: WPDefaults.COLLECTION_PROFILE,
      action: WPDefaults.VIEW,
    },
    query: { collectionCategory : 'COLLECTION'},
    subRoutes:[
      {
        path:'setCollection',
        query: { collectionCategory : 'COLLECTION'},
        permission: {
          page: WPDefaults.COLLECTION_PROFILE,
          action: WPDefaults.CREATE,
        },
        component: COMPONENTS.CollectionConfiguration,
      },
    ],
  },
  syntheticCollectionProfiles: {
    component: COMPONENTS.CollectionProfile,
    permission: {
      page: WPDefaults.SYNTHETIC_COLLECTION_PROFILE,
      action: WPDefaults.VIEW,
    },
    query: { collectionCategory : 'SYNTHETIC'},
    subRoutes:[
      {
        path:'setSyntheticCollection',
        query: { collectionCategory : 'SYNTHETIC'},
        permission: {
          page: WPDefaults.SYNTHETIC_COLLECTION_PROFILE,
          action: WPDefaults.CREATE,
        },
        component: COMPONENTS.CollectionConfiguration,
      },
    ],
  },
  configurationCollectionProfiles: {
    component: COMPONENTS.CollectionProfile,
    permission: {
      page: WPDefaults.CONFIGURATION_COLLECTION_PROFILE,
      action: WPDefaults.VIEW,
    },
    query: { collectionCategory : 'ARCHIVING'},
    subRoutes:[
      {
        path:'setConfigurationCollection',
        query: { collectionCategory : 'ARCHIVING'},
        permission: {
          page: WPDefaults.CONFIGURATION_COLLECTION_PROFILE,
          action: WPDefaults.CREATE,
        },
        component: COMPONENTS.CollectionConfiguration,
      },
    ],
  },
  sqlCollectionProfiles: {
    component: COMPONENTS.CollectionProfile,
    permission: {
      page: WPDefaults.SQL_COLLECTION_PROFILE,
      action: WPDefaults.VIEW,
    },
    query: { collectionCategory : 'SQL'},
    subRoutes:[
      {
        path:'setSQLCollection',
        query: { collectionCategory : 'SQL'},
        permission: {
          page: WPDefaults.SQL_COLLECTION_PROFILE,
          action: WPDefaults.CREATE,
        }, 
        component: COMPONENTS.CollectionConfiguration,
      },
    ],
  },
  eventLogs: {
    component: COMPONENTS.EventLogs,
    permission: {
      page: 'system_logs',
      action: WPDefaults.VIEW,
    }, 
  },
  userLogs: {
    component: COMPONENTS.UserLogs,
    permission: {
      page: 'user_logs',
      action: WPDefaults.VIEW,
    },
  },
  systemSettings:{
    component: COMPONENTS.SystemSettings,
    permission: {
      page: 'system_settings',
      action: WPDefaults.CREATE,
    },
  },
  userManagement: {
    component: COMPONENTS.UserManagement,
    query: { type: 'user' },
    permission: {
      page: WPDefaults.MANAGE_USERS,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path: 'setUser',
        query: { type: 'user' },
        component: COMPONENTS.SetUser,
        permission: {
          page: WPDefaults.MANAGE_USERS,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'inviteUsers',
        query: { type: 'user', invite: true },
        component: COMPONENTS.SetUser,
        permission: {
          page: WPDefaults.MANAGE_USERS,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  applicationClientManagement: {
    component: COMPONENTS.UserManagement,
    query: { type: 'client' },
    permission: {
      page: WPDefaults.MANAGE_NBI_APPLICATION_CLIENTS,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path: 'setClient',
        query: { type: 'client' },
        component: COMPONENTS.SetUser,
        permission: {
          page: WPDefaults.MANAGE_NBI_APPLICATION_CLIENTS,
          action: WPDefaults.CREATE,
        },
        role: 'ADMIN',
      },
    ],
  },
  groupManagement: {
    component: COMPONENTS.GroupManagement,
    permission: {
      page: WPDefaults.MANAGE_GROUPS,
      action: WPDefaults.VIEW,
    }, 
    subRoutes:[
      {
        path:'setGroup',
        component: COMPONENTS.SetGroup,
        permission: {
          page: WPDefaults.MANAGE_GROUPS,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  manageOwnership: {
    component: COMPONENTS.ManageOwnership,
    permission: {
      page: WPDefaults.ACCESS_MANAGEMENT,
      action: WPDefaults.VIEW,
    }, 
  },
  adminDefined: {
    component: COMPONENTS.AdminDefined,
    permission: {
      page: 'admin_defined',
      action: WPDefaults.VIEW,
    },
  },
  networkElementManagement : {
    component: COMPONENTS.NetworkElementManagement,
    permission: {
      page: WPDefaults.NETWORK_ELEMENT_MANAGEMENT,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path: 'configureNetworkElement',
        component: COMPONENTS.NetworkElementConfiguration,
        permission: {
          page: WPDefaults.NETWORK_ELEMENT_MANAGEMENT,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  events: {
    component: COMPONENTS.EventManagement,
    permission: {
      page:WPDefaults.EVENTS,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'setEvent',
        component: COMPONENTS.SetEvent,
        permission: {
          page:WPDefaults.EVENTS,
          action: WPDefaults.CREATE,
        },
      },
      {
        path:'automationRunsInfo',
        component: COMPONENTS.AutomationRunsInfo,
        permission: {
          page:WPDefaults.EVENTS,
          action: WPDefaults.VIEW,
        },
      },
    ],
  },
  tags: {
    component: COMPONENTS.Tags,
    permission: {
      page:WPDefaults.TAGS,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'addTag',
        component: COMPONENTS.AddTag,
        permission: {
          page:WPDefaults.TAGS,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  triggeredEvents: {
    component: COMPONENTS.TriggeredEvents,
    permission: {
      page: 'triggered_events',
      action: WPDefaults.VIEW,
    },
  },
  license: {
    component: COMPONENTS.License,
    permission: {
      page: 'license',
      action: WPDefaults.VIEW,
    },
  },
  lookup: {
    component: COMPONENTS.Lookup,
    permission: {
      page: WPDefaults.LOOKUP,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'addLookupTable',
        component: COMPONENTS.AddLookupTable,
        permission: {
          page: WPDefaults.LOOKUP,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  eventHealing: {
    component: COMPONENTS.EventHealing,
    permission: {
      page: WPDefaults.EVENT_HEALING,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'AddEventHealing',
        component: COMPONENTS.AddEventHealing,
        permission: {
          page: WPDefaults.EVENT_HEALING,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  [PAGES.COLLECTION_METHOD]:{
    component: COMPONENTS.CollectionMethodManagement,
    query: { type: 'COLLECTION' },
    permission: {
      page: WPDefaults.COLLECTION_METHOD,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path: PAGES.SET_COLLECTION_METHOD,
        component: COMPONENTS.SetCollectionMethod,
        query: { collectionCategory: 'SQL' },
        permission: {
          page: WPDefaults.COLLECTION_METHOD,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: PAGES.IMPORT_COLLECTION_METHOD,
        component: COMPONENTS.ImportCollectionMethod,
        permission: {
          page: WPDefaults.COLLECTION_METHOD,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  dataBindingManagement:{
    component: COMPONENTS.DataBindingManagement,
    permission: {
      page: WPDefaults.DATA_BINDING,
      action: WPDefaults.VIEW,
    },
    query: { collectionCategory: 'COLLECTION' },
    subRoutes:[
      {
        path:'setDataBinding',
        query: { collectionCategory: 'COLLECTION' },
        component: COMPONENTS.SetDataBinding,
        permission: {
          page: WPDefaults.DATA_BINDING,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'importDataBinding',
        query: { collectionCategory: 'COLLECTION' },
        component: COMPONENTS.ImportDataBinding,
        permission: {
          page: WPDefaults.DATA_BINDING,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  syntheticBindingManagement:{
    component: COMPONENTS.DataBindingManagement,
    permission: {
      page: WPDefaults.SYNTHETIC_BINDING,
      action: WPDefaults.VIEW,
    },
    query: { collectionCategory: 'SYNTHETIC' },
    subRoutes:[
      {
        path:'setSyntheticBinding',
        query: { collectionCategory: 'SYNTHETIC' },
        component: COMPONENTS.SetDataBinding,
        permission: {
          page: WPDefaults.SYNTHETIC_BINDING,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'importSyntheticBinding',
        query: { collectionCategory: 'SYNTHETIC' },
        component: COMPONENTS.ImportDataBinding,
        permission: {
          page: WPDefaults.SYNTHETIC_BINDING,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  configurationBindingManagement:{
    component: COMPONENTS.DataBindingManagement,
    permission: {
      page: WPDefaults.CONFIGURATION_BINDING,
      action: WPDefaults.VIEW,
    },
    query: { collectionCategory: 'ARCHIVING' },
    subRoutes:[
      {
        path:'setConfigurationBinding',
        query: { collectionCategory: 'ARCHIVING' },
        component: COMPONENTS.SetDataBinding,
        permission: {
          page: WPDefaults.CONFIGURATION_BINDING,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'importConfigurationBinding',
        query: { collectionCategory: 'ARCHIVING' },
        component: COMPONENTS.ImportDataBinding,
        permission: {
          page: WPDefaults.CONFIGURATION_BINDING,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  sqlBindingManagement:{
    component: COMPONENTS.DataBindingManagement,
    permission: {
      page: WPDefaults.SQL_BINDING,
      action: WPDefaults.VIEW,
    },
    query: { collectionCategory: 'SQL' },
    subRoutes:[
      {
        path:'setSqlBinding',
        query: { collectionCategory: 'SQL' },
        component: COMPONENTS.SetDataBinding,
        permission: {
          page: WPDefaults.SQL_BINDING,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'importSqlBinding',
        query: { collectionCategory: 'SQL' },
        component: COMPONENTS.ImportDataBinding,
        permission: {
          page: WPDefaults.SQL_BINDING,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  PushBasedDataBindingMappingManagement:{
    component: COMPONENTS.PushBasedEntityMappingManagement,
    permission: {
      page: WPDefaults.PUSH_BASED_DATA_BINDING_MAPPING,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'setPushBasedDataBindingMapping',
        component: COMPONENTS.SetPushBasedEntityMapping,
        permission: {
          page: WPDefaults.PUSH_BASED_DATA_BINDING_MAPPING,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  fga: {
    path: 'fga',
    component: COMPONENTS.FFAG,
    permission: {
      page: WPDefaults.FFAG,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'addFga',
        component: COMPONENTS.AddFFAG,
        permission: {
          page: WPDefaults.FFAG,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  templatesPlaceholders: {
    path: 'templatesPlaceholders',
    component: COMPONENTS.TemplatesPlaceholders,
    permission: {
      page: WPDefaults.TEMPLATES_PLACEHOLDERS,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'setTemplatesPlaceholder',
        component: COMPONENTS.SetTemplatesPlaceholder,
        permission: {
          page: WPDefaults.TEMPLATES_PLACEHOLDERS,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  topologySettings: {
    path: 'topologySettings',
    component: COMPONENTS.TopologySettings,
    permission: {
      page: WPDefaults.TOPOLOGY_SETTINGS,
      action: WPDefaults.CREATE,
    },
  },
  attributeTypes: {
    path: 'attributeTypes',
    component: COMPONENTS.AttributeTypes,
    permission: {
      page: WPDefaults.ATTRIBUTE_TYPE,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'addAttributeType',
        component: COMPONENTS.SetAttributeType,
        permission: {
          page: WPDefaults.ATTRIBUTE_TYPE,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  nbi: {
    component: COMPONENTS.NBI,
    permission: {
      page: WPDefaults.NBI,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'setNBI',
        component: COMPONENTS.SetNBI,
        permission: {
          page: WPDefaults.NBI,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  designSpaceOrganizer:{
    path: 'designSpaceOrganizer',
    component: COMPONENTS.DesignSpaceOrganizer,
    permission: {
      page: WPDefaults.DESIGN_SPACE_ORGANIZER,
      action: WPDefaults.VIEW,
    },
  },
  recordsManagement: {
    component: COMPONENTS.RecordsManagement,
    permission: {
      page: WPDefaults.RECORDS,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'setRecord',
        component: COMPONENTS.SetRecord,
        permission: {
          page: WPDefaults.RECORDS,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  auditingTemplatesManagement: {
    component: COMPONENTS.AuditingTemplatesManagement,
    permission: {
      page: WPDefaults.AUDITING_TEMPLATES,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'setAuditingTemplate',
        component: COMPONENTS.SetAuditingTemplate,
        permission: {
          page: WPDefaults.AUDITING_TEMPLATES,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  [WPDefaults.ROUTING_GROUP_KEY]: {
    component: COMPONENTS.RoutingGroups,
    permission: WPDefaults.ROUTING_GROUP,
    subRoutes:[
      {
        path:'SetRoutingGroup',
        component: COMPONENTS.SetRoutingGroup,
        permission: WPDefaults.ROUTING_GROUP,
      },
    ],
  },
  [WPDefaults.DATA_FLOW_KEY]: {
    component: COMPONENTS.DataFlows,
    permission: WPDefaults.DATA_FLOW,
    subRoutes:[
      {
        path:'SetDataFlow',
        component: COMPONENTS.SetDataFlow,
        permission: WPDefaults.DATA_FLOW,
      },
    ],
  },
  rollUpPlansManagement: {
    component: COMPONENTS.RollUpPlansManagement,
    query: { type: RollUpPlansDefaults.TYPES.ELASTIC_SEARCH },
    permission: {
      page: WPDefaults.ROLL_UP_PLAN,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'setRollUpPlan',
        component: COMPONENTS.SetRollUpPlan,
        permission: {
          page: WPDefaults.ROLL_UP_PLAN,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  lakehouseRetentionPlansManagement: {
    component: COMPONENTS.RollUpPlansManagement,
    query: { type: RollUpPlansDefaults.TYPES.LAKEHOUSE },
    permission: {
      page: WPDefaults.LAKEHOUSE_RETENTION_PLAN,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'setLakehouseRetentionPlan',
        component: COMPONENTS.SetLakehouseRetentionPlan,
        permission: {
          page: WPDefaults.LAKEHOUSE_RETENTION_PLAN,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  organizationGroupManagement: {
    component: COMPONENTS.OrganizationGroupManagement,
    permission: {
      page: WPDefaults.OG,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'setOrganizationGroup',
        component: COMPONENTS.SetOrganizationGroup,
        permission: {
          page: WPDefaults.OG,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  dataModelTree: {
    component: COMPONENTS.DataModelTree,
    permission: {
      page: WPDefaults.DATA_MODEL_TREE,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'setAttribute',
        component: COMPONENTS.SetDataModelTreeAttribute,
        permission: {
          page: WPDefaults.DATA_MODEL_TREE,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'SetContainer',
        component: COMPONENTS.SetDataModelTreeContainer,
        permission: {
          page: WPDefaults.DATA_MODEL_TREE,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'SetList',
        component: COMPONENTS.SetDataModelTreeList,
        permission: {
          page: WPDefaults.DATA_MODEL_TREE,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  dashboard: {
    component: COMPONENTS.DashboardLayout,
    permission: {
      page: WPDefaults.DASHBOARD,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'dashboardConfig',
        component:COMPONENTS.DashboardConfig,
        permission: {
          page: WPDefaults.DASHBOARD,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'manageDashboards',
        component: COMPONENTS.ManageDashboards,
        permission: {
          page: WPDefaults.DASHBOARD,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'importDashboards',
        component: COMPONENTS.ImportDashboards,
        permission: {
          page: WPDefaults.DASHBOARD,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'associate',
        component: COMPONENTS.AssociateWidget,
        permission: {
          page: WPDefaults.DASHBOARD,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  report: {
    component: COMPONENTS.ReportDesignerLayout,
    permission: {
      page: WPDefaults.REPORT,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path: 'manageReports',
        component: COMPONENTS.ManageReports,
        permission: {
          page: WPDefaults.REPORT,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'reportConfig',
        component: COMPONENTS.ReportConfig,
        permission: {
          page: WPDefaults.REPORT,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'importReports',
        component: COMPONENTS.ImportReports,
        permission: {
          page: WPDefaults.REPORT,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'associate',
        component: COMPONENTS.AssociateWidget,
        permission: {
          page: WPDefaults.REPORT,
          action: WPDefaults.CREATE,
        },
      },
      {
        path: 'downloadReport',
        component: COMPONENTS.DownloadReport,
        permission: {
          page: WPDefaults.REPORT,
          action: WPDefaults.VIEW,
        },
      },
      {
        path: 'Analysis',
        component: COMPONENTS.Analysis,
        permission: {
          page: WPDefaults.REPORT,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  scheduleReports: {
    component: COMPONENTS.ScheduledReports,
    permission: {
      page: WPDefaults.REPORT,
      action: 'schedule',
    },
    subRoutes: [
      {
        path: 'addScheduledReport',
        component: COMPONENTS.AddScheduledReport,
        permission: {
          page: WPDefaults.REPORT,
          action: 'schedule',
        },
      },
      {
        path: 'historicalExports',
        component: COMPONENTS.HistoricalExports,
        permission: {
          page: WPDefaults.REPORT,
          action: 'schedule',
        },
      },
    ],
  },
  forecasting:{
    path: 'forecasting',
    query: { type: 'forecasting' },
    component: COMPONENTS.Analysis,
    permission: {
      page: 'analysis',
      action: WPDefaults.VIEW,
    },
  },
  mlJobs:{
    path: 'mlJobs',
    component: COMPONENTS.MLJobsManagement,
    permission: {
      page: WPDefaults.ML_JOBS,
      action: WPDefaults.VIEW,
    },
    subRoutes: [
      {
        path: 'setMLJob',
        component: COMPONENTS.SetMLJob,
        permission: {
          page: WPDefaults.ML_JOBS,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  [WPDefaults.UNREACHABILITY_PLAN_KEY]: {
    component: COMPONENTS.UnreachabilityPlanManagement,
    permission: {
      page: WPDefaults.UNREACHABILITY_PLAN,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'setUnreachabilityPlan',
        component: COMPONENTS.SetUnreachabilityPlan,
        permission: {
          page: WPDefaults.UNREACHABILITY_PLAN,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  [WPDefaults.CP_BLOCK_LIST]: {
    component: COMPONENTS.CPBlockListing,
    permission: {
      page: WPDefaults.CP_BLOCK_LIST,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
    ],
  },
  [WPDefaults.CONFIGURATION_LISTING]: {
    component: COMPONENTS.ConfigurationListing,
    permission: {
      page: 'archived_files',
      action: WPDefaults.VIEW,
    },
    path: 'archived_files',
    subRoutes:[
      {
        path: WPDefaults.CONFIGURATION_VIEWER,
        component: COMPONENTS.ConfigurationFileView,
        permission: {
          page: WPDefaults.CONFIGURATION_VIEWER,
          action: WPDefaults.VIEW,
        },
      },
    ],
  },
  [WPDefaults.CONFIGURATION_DIFF_VIEWER]: {
    path: WPDefaults.CONFIGURATION_DIFF_VIEWER,
    component: COMPONENTS.ConfigurationDifference,
    permission: {
      page: WPDefaults.CONFIGURATION_DIFF_VIEWER,
      action: WPDefaults.VIEW,
    },
  },
  automationWorkflow: {
    component: COMPONENTS.AutomationWorkflow,
    permission: {
      page: WPDefaults.AUTOMATION_WORKFLOW,
      action: WPDefaults.VIEW,
    },
    path:'automationWorkflow',
    subRoutes:[
      {
        path:'addAutomationWorkflow',
        component: COMPONENTS.AddAutomationWorkflow,
        permission: {
          page: WPDefaults.AUTOMATION_WORKFLOW,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  LDAPConfigManagement: {
    component: COMPONENTS.LDAPConfigManagement,
    permission: {
      page: WPDefaults.LDAP_CONFIG,
      action: WPDefaults.VIEW,
    },
    subRoutes:[
      {
        path:'setLDAPConfig',
        component: COMPONENTS.SetLDAPConfig,
        permission: {
          page: WPDefaults.LDAP_CONFIG,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  fileExport:{
    component: COMPONENTS.FileExport,
    permission: {
      page: WPDefaults.FILE_EXPORT,
      action: WPDefaults.VIEW,
    },
    path: 'fileExport',
    subRoutes:[
      {
        path:'setFileExport',
        component: COMPONENTS.SetFileExport,
        permission: {
          page: WPDefaults.FILE_EXPORT,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  ScheduledFileExports: {
    component: COMPONENTS.ScheduledFileExports,
    permission: {
      page: WPDefaults.SCHEDULED_FILE_EXPORTS,
      action: WPDefaults.VIEW,
    },
    path:'ScheduledFileExports',
    subRoutes: [
      {
        path: 'addScheduledFileExport',
        component: COMPONENTS.AddScheduledFileExport,
        permission: {
          page: WPDefaults.SCHEDULED_FILE_EXPORTS,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  customAnalytics: {
    component: COMPONENTS.CustomAnalyticsListing,
    permission: {
      page: WPDefaults.CUSTOM_ANALYTICS,
      action: WPDefaults.VIEW,
    },
    path:'customAnalytics',
    subRoutes: [
      {
        path: 'addCustomAnalyticsProject',
        component: COMPONENTS.AddCustomAnalytics,
        permission: {
          page: WPDefaults.CUSTOM_ANALYTICS,
          action: WPDefaults.CREATE,
        },
      },
      {
        path:'analyticsRunsInfo',
        component: COMPONENTS.AnalyticsRunsInfo,
        permission: {
          page:WPDefaults.ANALYTIC_LOGS,
          action: WPDefaults.VIEW,
        },
      },
    ],
  },
  analyticsJobsManagement: {
    component: COMPONENTS.CollectionMethodManagement,
    query: { type: 'CUSTOM_ANALYTICS' },
    permission: {
      page: WPDefaults.ANALYTIC_JOBS,
      action: WPDefaults.VIEW,
    },
    path:'analyticsJobsManagement',
    subRoutes: [
      {
        path:'setAnalyticsJob',
        component: COMPONENTS.SetCollectionMethod,
        query: { collectionCategory: 'CUSTOM_ANALYTICS' },
        permission: {
          page: WPDefaults.ANALYTIC_JOBS,
          action: WPDefaults.CREATE,
        },
      },
    ],
  },
  analyticsRunsInfo: {
    component: COMPONENTS.AnalyticsRunsInfo,
    query: { infoType: AutomationRunsInfoDefaults.INFO_LOGGING_KEYS.ANALYTICS_LOGS },
    permission: {
      page:WPDefaults.ANALYTIC_LOGS,
      action: WPDefaults.VIEW,
    },
    path:'analyticsRunsInfo',
  },
  [WPDefaults.OTEL]: {
    path: WPDefaults.OTEL,
    component: COMPONENTS.Otel,
    permission: {
      page: WPDefaults.OTEL,
      action: WPDefaults.VIEW,
    },
    subRoutes:[{
      path: WPDefaults.TRACE,
      component: COMPONENTS.TraceDetails,
      permission: {
        page: WPDefaults.OTEL,
        action: WPDefaults.VIEW,
      },
    }],
  },
};
