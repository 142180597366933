/* Copyright 2019 Greyskies. All Rights Reserved. */
import 'react-template/lib/css/main.css';
import 'web-style-guide/css/app.less';
import 'web-style-guide/css/netflowExport.less';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { Sidebar, Navbar, Footer, ErrorPage } from 'react-template';
import Downloads from './Downloads';
import NavbarButtons from './NavbarButtons';
import FooterBuildProps from './FooterBuildProps';
import TemplateStore from 'stores/TemplateStore';

import { withRouter } from 'react-router';
import { ImageConversionUtils } from 'js-utils';
import { MAIN_ROUTES } from 'config/routesDefaults';
import SwitchThemeMode from './SwitchThemeMode';
import ChangeContextButton from './ChangeContextButton';



const { node, object, string, shape } = PropTypes;

@withRouter
export default class Template extends Component {

  static propTypes = {
    children: node,
    location: object,
    router: object,
    userData: shape({
      username: string,
      stompUrls: string,
      applicationTitle: string,
    }),
  };

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);    
    this.currentPage = props.location.pathname;

    this.changeContext = this.changeContext.bind(this);
    this.onChangeBodyClasses = this.onChangeBodyClasses.bind(this);
    this.bodyObserver = new MutationObserver(this.onChangeBodyClasses);
  }

  state = {
    liveNotifications: true,
    showNavbars: TemplateStore.getShowNavbars(),
  };

  componentDidMount() {
    const bodyObserverConfig = {
      attributes: true, attributeOldValue: true, attributeFilter: ['class'],
    };

    document.title = this.props.userData.applicationTitle;
    TemplateStore.addChangeListener(this.onChange);
    this.bodyObserver.observe(document.body, bodyObserverConfig);
  }

  componentDidUpdate(nextProps) {
    this.currentPage = this.props.location.pathname;
  }

  componentWillUnmount() {
    TemplateStore.removeChangeListener(this.onChange);
    this.bodyObserver.disconnect();
  }

  onChange = () => {
    this.setState({
      showNavbars: TemplateStore.getShowNavbars(),
    });
  };

  changeContext() {
    this.props.router.push({
      pathname: this.props.navigationButtonURL,
    });
  }

  onChangeBodyClasses(mutationList, observer) {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
        const previousClassList = mutation.oldValue ? mutation.oldValue.split(' ') : [];
        const currentClassList = mutation.target.className.split(' ');

        const modalOpenAdded = !previousClassList.includes('modal-open') && currentClassList.includes('modal-open');
        const modalOpenRemoved = previousClassList.includes('modal-open') && !currentClassList.includes('modal-open');
  
        if (modalOpenAdded) {
          mutation.target.classList.add('overflow-auto');
          const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
          mutation.target.style.paddingRight = `${scrollBarWidth}px`;
          mutation.target.classList.remove('overflow-auto');
        } else if (modalOpenRemoved) {
          mutation.target.style.paddingRight = '0';
        }
      }
    }
  };

  render() {
    if (this.props.userData) {
      const selectedPage = this.currentPage;
      const sidebar
        = this.state.showNavbars ? <Sidebar
          selectedPage={selectedPage}
          sidebarElements={this.props.sidebarElements}
          context={this.props.context}
        /> : null;
      let contextButton = null;
  
      if(this.props.navigationButtonURL) {
        contextButton = <ChangeContextButton
          context={this.props.context} changeContext = {this.changeContext} 
          inNavbar
          />;
      }   
      const switchThemeMode = (
        <li>
          <SwitchThemeMode className = 'switchThemeMode-btn' inNavbar/>
        </li>
      );
      const responsiveSidebar = this.state.showNavbars ? React.cloneElement(sidebar, {
        inNavbar: true,
        switchThemeMode,
        contextButton,

      }) : null;
      const footer
        = this.state.showNavbars ?
          <Footer
            footerProps={
              <FooterBuildProps footerProps={this.props.footerProps} />
            }
          /> : null;

      const DownloadCenter = this.props.context == MAIN_ROUTES.REPORTER ? <Downloads /> : null;

      return (
        <div id='wrapper' className={` toggled  ${(!this.state.showNavbars) ? 'expanded' : ''}`}>
          <NotificationContainer />
          {sidebar}
          {(this.state.showNavbars) ? <Navbar
            appName={this.props.userData.applicationTitle}
            downloadCenter={DownloadCenter}
            sidebar={responsiveSidebar}
            logo={<img src={ImageConversionUtils.pngToBase64URL(this.props.userData.headerLogo)} height="35px" />}
            navbarButtons={
              <NavbarButtons username={this.props.userData.username} changeContext={this.changeContext} context={this.props.context} showContextBtn={this.props.navigationButtonURL} />
            }
          /> : null}
          <div id="page-content-wrapper" role="main" style={(!this.state.showNavbars) ? { paddingTop: '10px' } : {}}>
            {
              !this.props.children ? <ErrorPage params={{ error: 1000 }} /> : this.props.children
            }
          </div>
          {footer}
        </div>
      );
    }

    return null;
  }
}