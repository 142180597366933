/* Copyright 2019 Greyskies. All Rights Reserved. */

import {fromJS} from 'immutable';
import _ from 'lodash';

export function convertObjectToString(objList, key){
  const stringArray = [];

  if (objList) {
    objList.map((obj) => {
      if (typeof obj == 'object') {
        stringArray.push(obj[key]);
      } else if (typeof obj == 'string') {
        stringArray.push(obj);
      }
    });
  }

  return stringArray;
}

export function orderUp(list, index) {
  if(list){
    const newList = fromJS(list).toJS();

    if (index > 0 && index < list.length) {
      const temp = fromJS(newList[index]).toJS();

      newList[index] = newList[index - 1];
      newList[index - 1] = temp;
    }

    return newList;
  }

  return list;
}

export function orderDown(list, index){
  if(list){
    const newList = fromJS(list).toJS();

    if (index < newList.length - 1 && index >= 0) {
      const temp = fromJS(newList[index]).toJS();

      newList[index] = newList[index + 1];
      newList[index + 1] = temp;
    }

    return newList;
  }

  return list;
}

export function deleteItem(list, index){
  if(list){
    const newList = fromJS(list).toJS();

    if (newList.length > 0) {
      newList.splice(index, 1);
    }

    return newList;
  }

  return list;
}

export function deleteItemByValue(list, value){
  if(list){
    const foundIndex =  list.findIndex(item => item == value);

    if(foundIndex != -1){
      list.splice(foundIndex, 1);
    }
  }
}

export function deleteItemByObjectKey(list, object, key){
  if(list && object){
    const index = list.findIndex(item => item[key] === object[key]);

    return deleteItem(list, index);
  }

  return list;
}

export const insertOrUpdateByObjectKey = (list, object, key) => {
  let added = false;
  let index;
  if(list && object){
    index = list.findIndex(item => item[key] === object[key]);

    if(index > -1) {
      list[index] = object;
    } else {
      list.push(object);
      added = true;
    }
  }

  return {added, index};
};

export const sortByKeyword = (keyword, ascending) => (a, b) => {
  const compareValue = ascending ? -1 : 1;

  if (a[keyword] > b[keyword]) return compareValue;
  if (b[keyword] > a[keyword]) return -1 * compareValue;

  return 0;
};

export const hasItems = (array) => {
  return !!array && array.length > 0;
};

// This function is concerned if array elements are unique.
export function isUniqueEquals(array1, array2){
  if(array1 && array2){
    // if(array1 && array2 && array1.length == array2.length){
    //   const sortedArr1 = array1.slice().sort();
    //   const sortedArr2 = array2.slice().sort();
  
    //   return sortedArr1.every((value, index) => value === sortedArr2[index]);
    // }
  
    // return false;
    return array1.length === array2.length && new Set([...array1, ...array2]).size === array1.length;
  }

  return false;
}

export function getCommonList(originalList, chosenItems) {
  if (originalList?.length && chosenItems?.length) {
    const common = originalList.filter((element) => {
      return chosenItems.includes(element);
    });
  
    return common;
  }

  return null;
}

export function getFilteredList(originalList, chosenItems) {
  if (originalList && chosenItems){
    return originalList.filter((element) => {
      return !chosenItems.includes(element);
    });
  }

  return originalList;
}

export function hasItemByObjectKeyValue (list, key, value) {
  if(list){
    return list.findIndex(item => item[key] === value);
  }

  return -1;
}

export function hasItem (list, value) {
  if(list){
    return list.indexOf(value) > -1;
  }

  return false;
}

export function addItemIfDoesNotExist (list, value) {
  if(!hasItem(list, value)) {
    list.push(value);
  }
}
