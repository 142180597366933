/* Copyright 2019 Greyskies. All Rights Reserved. */
import {USER_MANAGEMENT, REST_API} from 'config/pagesHeaderTitle';

const USER = 'User';
const CLIENT = 'Client';

const USERNAME_ALREADY_EXISTS = 'Username already Exists';
const EMAIL_ALREADY_EXISTS = 'Email already Exists';
const NO_SELECTION_OPTION = 'NO_SELECTION';
const MULTIPLE_SELECTION_OPTION = 'MULTIPLE_SELECTION';
const SINGLE_SELECTION_OPTION = 'SINGLE_SELECTION';
const CLIENT_COPY_INFO_WARNING_MESSAGE = 'Please save this passphrase in a secure place,' 
  + 'If you do not copy it now, you will not be able to retrieve your passphrase again.';
const getUserPanelTitle = (isUser) => isUser ? USER_MANAGEMENT : REST_API;
const PROFILE = 'profile';
const PASSWORD = 'password';
const PASSWORD_VALIDATION = 'Your password should contain at least a number, a special character, a lowercase and an uppercase letter,'
+ 'should be at least 8 characters and at most 32 characters with no spaces'; 

const INVITE = "Invite";
const INVITATION_MESSAGE_PLACEHOLDER = "Invitation message";
const EMPTY_NAME = "-";

const ACTIVE = 'Active';
const isActive = (status) => status == ACTIVE;

const userDefaults = {
  NO_SELECTION_OPTION,
  MULTIPLE_SELECTION_OPTION,
  SINGLE_SELECTION_OPTION,
  USERNAME_ALREADY_EXISTS,
  EMAIL_ALREADY_EXISTS,
  CLIENT_COPY_INFO_WARNING_MESSAGE,
  getUserPanelTitle,
  USER,
  CLIENT,
  EMPTY_NAME,
  INVITATION_MESSAGE_PLACEHOLDER,
  INVITE,
  isActive,
  PROFILE,
  PASSWORD,
  PASSWORD_VALIDATION,
};


export default userDefaults;
