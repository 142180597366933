/* Copyright 2019 Greyskies. All Rights Reserved. */

import {messages} from './validation/ErrorMsgs';
import ValidationError from './validation/ValidationError';
import * as validationDefaults from './validation/validationDefaults';
import * as UnitConversionUtils from './UnitConversionUtils';

export const getLimitValue = (error) => {
  // limit will be object in case reference value used in ex: max or min is Ref
  const val = error.local.limit instanceof Object ? error.state.ancestors[0][error.local.limit.key] : error.local.limit;
  
  return val ;
};

export const VARCHAR_LENGTH = 255;

export const REGEX_VARCHAR_LENGTH = 1000;

export const ERR_MSGS = messages ;

export const defaults = validationDefaults;

export const INT_MAX_VALUE = 2147483647;

export function getErrMsgString(errors){
  if(errors[0].code == 'string.max'){
    return ERR_MSGS.stringMaxLength(errors[0].local.label, 
      errors[0].local.limit);
  }else if(errors[0].code == 'string.min'){
    return ERR_MSGS.stringMinLength(errors[0].local.label, 
      errors[0].local.limit);
  }else if(errors[0].code == 'string.alphanum'){
    return ERR_MSGS.stringAlphaNum(errors[0].local.label, 
      errors[0].local.limit);
  }else if(errors[0].code == 'string.pattern.base'){
    return ERR_MSGS.stringRegex(errors[0].template.source);
  }else if(errors[0].code == 'string.lowercase'){
    return ERR_MSGS.stringLowercase(errors[0].local.label);
  }else if(errors[0].code == 'any.invalid'){
    return ERR_MSGS.invalidValue(errors[0].local.label, errors[0].value);
  }
  
  return ERR_MSGS.required(errors[0].local.label);
}


export const getErrMsgArray = (label) => (errors) =>{
  if(errors[0].code == 'array.min' || errors[0].code == 'array.required'){
    return ERR_MSGS.arrayMinLength(errors[0].local.label, 
      getLimitValue(errors[0]));
  }else if(errors[0].code == 'array.max'){
    return ERR_MSGS.arrayMaxLength(errors[0].local.label, 
      getLimitValue(errors[0]));
  } else if(errors[0].code == 'array.unique'){
    return ERR_MSGS.arrayUniqueItems(errors[0].local.label);
  }
  const invalidItemsLabel = errors[0].local ? errors[0].local.label : label;
  
  return ERR_MSGS.arrayInvalidItem(invalidItemsLabel) ;
};

export function getErrMsgNumber(errors){
  if((errors[0].code == 'number.base' && errors[0].local.value)
    || errors[0].code == 'number.unsafe'){
    return ERR_MSGS.base(errors[0].local.label, 'number');
  }else if(errors[0].code == 'number.integer'){
    return ERR_MSGS.integerNumber(errors[0].local.label);
  }else if(errors[0].code == 'number.max'){
    return ERR_MSGS.numberMaxValue(errors[0].local.label, 
      errors[0].local.limit);
  }else if(errors[0].code == 'number.min'){
    return ERR_MSGS.numberMinValue(errors[0].local.label, 
      errors[0].local.limit);
  }else if(errors[0].code == 'number.greater'){
    return ERR_MSGS.greater(errors[0].local.label, errors[0].template.source);
  }else if(errors[0].code == 'number.greaterOrEqual'){
    return ERR_MSGS.greaterOrEqual(errors[0].local.label, errors[0].template.source);
  }else if (errors[0].code === 'number.isMultiple') {
    return ERR_MSGS.multiple(errors[0].local.label, errors[0].local.target);
  }
  
  return ERR_MSGS.required(errors[0].local.label);
}

export function getErrMsgBoolean(errors){
  if((errors[0].code == 'boolean.base' && errors[0].local.value)
    || errors[0].code == 'boolean.unsafe'){
    return ERR_MSGS.base(errors[0].local.label, 'boolean');
  }

  return ERR_MSGS.required(errors[0].local.label);
}

export function getErrMsgFile(errors){
  const err = errors[0];

  if(err.code == 'number.max' && err.local.key == 'size'){
    const size = UnitConversionUtils.getFinalValueForm(errors[0].local.limit, err.flags.unit);

    return ERR_MSGS.fileSize(size, err.flags.unit);
  }else if(err.code == 'any.only'){
    return ERR_MSGS.fileExtension(errors[0].local.valids);
  }

  return ERR_MSGS.required(errors[0].local.label || 'File');
}

export function labelToValidationObject(label, itemsArray, isValid){
  return {
    label,
    itemsArray,
    style: isValid == undefined || !isValid ? 'error' : 'success',
  };
}

export function isValid(validationResult){
  return !validationResult || validationResult.style !== 'error';
}

export function errorToValidationObject(err, isValid){
  if(!err || isValid){
    return {style: 'success', itemsArray: []};
  }
  const label = err.details ?  err.details[0].message : err.message;

  return labelToValidationObject(label, err.itemsArray || []);
}

export function validationJoiSchema(joiSchema, value){
  return joiSchema.validate(value);
}

export function validateAttributeSchema(schema, value) {
  const validation = schema.validate(value);
  const err = validation.error;

  return errorToValidationObject(err);
}

export function validateItem(error){
  const label = error.local.label;
  let type = error.code.split('.')[0];
  if(type != 'any' && error.path.length > 2 ){
    type = 'array';
  }
  let result = null;
  switch (type) {
    case 'array':
      result = errorToValidationObject(getErrMsgArray(label)([error]));
      break;
    case 'number':
      result = errorToValidationObject(getErrMsgNumber([error]));
      break; 
    default:
      result = errorToValidationObject(getErrMsgString([error]));
      break;
  }
  return result;
}

export const errorArrayToErrorsObject = (label) => (errors) =>{
  const errorLabel = `Errors found at 1 or more ${label}`;
  let validationArray = [];
  let isGeneralError = false;
  errors.forEach(error => {
    const arrayIndex = error.path[0];

    if(arrayIndex != undefined ){
      if(!validationArray[arrayIndex]){
        validationArray[arrayIndex] = validateItem(error);
      }
    }else{
      isGeneralError = true;
    }
  });
  const error = validateItem(errors[0]);
  return isGeneralError ?  new ValidationError(error.label, []) :
    new ValidationError(errorLabel, validationArray);
}

export function errorRangesArrayObject(label, compareValue){
  return ERR_MSGS.greater(label, compareValue);
};

export const validateSchema = (SCHEMA) => (attribute, value) => {
  const validation = validationJoiSchema(SCHEMA[attribute], value);
  const err = validation.error;

  return errorToValidationObject(err);
};

export const validateSingleSchema = (singleSchema, value) => {
  const validation = validationJoiSchema(singleSchema, value);
  const err = validation.error;

  return errorToValidationObject(err);
};
