export const USER_LOGS_NAME = 'User Logs';
export const USER_LOGS_FILE_NAME = 'User Logs.csv';
export const LOGS = 'logs';
export const EXPORT_LOGS = 'Export Logs';
export const HELP = 'This page shows detailed user logs, you can search, filter and Export logs.';


// Actions
export const CHANGE_DATE_RANGE = 'CHANGE_DATE_RANGE';
export const REFRESH_NOW = 'REFRESH_NOW';
export const AUTO_REFRESH = 'AUTO_REFRESH';
export const SLIDE_SHOW = 'SLIDE_SHOW';