/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component} from 'react';
import DownloadsStore from 'stores/DownloadsStore';
import DownloadsActions from 'actions/DownloadsActions';
import {OverlayTrigger, Popover, Tooltip} from 'react-bootstrap';
import DownloadItem from './DownloadItem';
import Scrollbar from 'react-scrollbar';
import {TooltippedButton, Icons} from 'react-template';

export default class Downloads extends Component{
  constructor(props) {
    super(props);
    this.collapse = this.collapse.bind(this);
    this.handleResize = ::this.handleResize;
    this.clearAll = ::this.clearAll;
    this.onChange = ::this.onChange;
    this.state = {
      downloads:DownloadsStore.getDownloads(),
      collapsed:false,
    };
  }

  componentDidMount(){
    window.addEventListener('resize', this.handleResize);
    DownloadsStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    DownloadsStore.removeChangeListener(this.onChange);
  }

  onChange(){
    this.setState({downloads:DownloadsStore.getDownloads()});
    if(this.scrollArea){
      this.scrollArea.scrollArea.refresh();
    }
  }
  handleResize() {
    this.overlay.hide();
  }
  clearAll(e){
    e.preventDefault();
    DownloadsActions.clearAllDownloads(this.state.downloads);
  }
  collapse(e){
    e.preventDefault();
    this.setState({collapsed:!this.state.collapsed});
  }

  render(){
    let notifications = null;
    let downloadNumber = 0;

    const downloads = this.state.downloads.map(download => {
      if(download.progress != 100){
        downloadNumber ++;
      }

      return <DownloadItem key={download.id} {...download}/>;
    });

    notifications = (downloadNumber > 0) ?
    <div className="circle notifcations-counter">
          {downloadNumber}
    </div> : null;

    const title = <div className="downloadCenter">
      <div className='pull-left'>
        Download Center
      </div>
      <div className='pull-right'>
      <TooltippedButton tooltip='Clear all finished Downloads'
      className='btn-action'
      onClick={this.clearAll}>
        <i className={Icons.delete}></i>
      </TooltippedButton>
      </div>
    </div>;

    return(
      <OverlayTrigger  ref={ ref => this.overlay = ref}
        rootClose placement="bottom" trigger="click"
        overlay={<Popover
          id={"download-center-popover"}
          title={title}>
          <Scrollbar
            ref={ref => this.scrollArea = ref}
            speed={0.8}
            className="downloads-wrapper"
            contentClassName="content"
            smoothScrolling
            horizontal={false}
            >
            {downloads}
          </Scrollbar>
        </Popover>
      }
      >
        <OverlayTrigger
          rootClose placement="bottom" trigger={['hover', 'focus']}
          overlay={
            <Tooltip placement="bottom" className="in" id="tooltip-bottom">
            Download Center
            </Tooltip>
          }
        >
        <div className="navbar-action-icon">
          <i className={Icons.download + Icons.styles.evLg}></i>
          {notifications}
        </div>
        </OverlayTrigger>
      </OverlayTrigger>

    );
  }
}
