/* Copyright 2019 Greyskies. All Rights Reserved. */

import 'react-template/lib/css/main.css';
import 'web-style-guide/css/login.less';

import React from 'react';
import LoginAPI from 'api/LoginAPI';
import { ImageConversionUtils} from 'js-utils';
import {Col, Image, Row } from 'react-bootstrap';
import { Footer } from 'react-template';
import FooterBuildProps from 'components/layout/FooterBuildProps';
import AppStore from 'stores/AppStore';
import 'styles/login/externalPageContainer.less';

export default class ExternalPageContainer extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      loginTitle: '',
    };
  }

  componentDidMount() {
    LoginAPI.getLoginTemplateData().then(value => {
      document.title = value.loginTitle;

      this.setState({
        loginLogo: ImageConversionUtils.pngToBase64URL(value.loginLogo),
        loginTitle: value.loginTitle,
      });
    });
  }

  render() {
    return (
      <div id="wrapper" className="login-wrapper">
        <div id="page-content-wrapper" role="main">
          <Row>
            <Col xs={4} className='block-center'>
              {!this.props.hideLogo ? 
              <Row>
                <Col lg={12} className='block-center'>
                    <Image className="logo-img center-block"
                    src={this.state.loginLogo}/>
                </Col>
              </Row> : null}
              <div className='loginFormWrapper'>
                <Row>
                  <Col xs={12}>
                    <h5 className="welcome-text break-word">
                      {this.props.title || this.state.loginTitle}
                    </h5>
                    {this.props.subtitle ?
                      <h6 className="welcome-text break-word">
                        {this.props.subtitle}
                      </h6> : null}
                  </Col>
                  <Col xs={12}>
                    {this.props.children}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Footer textColor="white"
						footerProps={
							<FooterBuildProps footerProps={AppStore.getFooterProps()}/>
						}
				/>
      </div>
    );
  }
}
