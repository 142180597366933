/* Copyright 2019 Greyskies. All Rights Reserved. */

import BaseStore from './BaseStore';
import TemplateConstants from 'constants/TemplateConstants';

class TemplateStore extends BaseStore{

  constructor(){
    super();
    this.showNavbars = true;
    this.addActionHandler(TemplateConstants.SHOW_NAVBARS,
      this.changeShowNavbars.bind(this));

    this.registerWithDispatcher();
  }

  changeShowNavbars(action){
    if(action.data){
      this.showNavbars = action.data;
    }else{
      this.showNavbars = !this.showNavbars;
    }
    this.emitChange();
  }

  getShowNavbars(){
    return this.showNavbars;
  }

}

export default new TemplateStore();
