/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {withRouter} from 'react-router';
import {FormWithFooter, ErrorPage, dialogs} from 'react-template';
import {MsgDefaults} from 'js-utils';
import inputValidator from 'validations/AccountManagementValidationSchema';
import AppStore from 'stores/AppStore';
import  * as UserProfileActions from 'actions/UserProfileActions';
import {NotificationManager} from 'react-notifications';
import userDefaults from 'config/userDefaults';
import * as AccountManagementUtils from 'utils/AccountManagementUtils';
import ExternalPageContainer from '../Login/ExternalPageContainer';
import ExternalPageAlert from '../Login/ExternalPageAlert';
import 'styles/userProfile/resetPassword.less';

@withRouter
export default class ResetPassword extends React.Component {
  constructor(props){
    super(props);

    this.userData = AppStore.getUserData();

    this.state = {
      password: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      showCurrentPassword: false,
      error: false,
    };
    this.resetPassword = ::this.resetPassword;
    this.onSubmit = ::this.onSubmit;
    this.inputValidation = ::this.inputValidation;
    this.onBack = ::this.onBack;
  }

  resetPassword(){
    const resetPasswordData = {
      userId: this.userData.userId,
      currentPassword: this.state.currentPassword,
      newPassword: this.state.password,
    };
    UserProfileActions.resetPassword(resetPasswordData).then(response => {
      if(response == false){
        this.setState({
          error: true,
        })
      }else{
        UserProfileActions.logout().catch(() => {
          window.location.href = window.location.origin + window.location.pathname;
        }
        );
      }
    })
    .catch((error) => {
      if(error) {
        NotificationManager.error(MsgDefaults.getErrorResetMsg(userDefaults.PASSWORD), MsgDefaults.getResetTitle(userDefaults.PASSWORD), 10000);
      }
    });
  }

  onSubmit(){
    this.setState({error: false}, () => {
      dialogs.confirm({
        cb: () => {
          this.resetPassword();
        },
        message: 'Are you sure you want to reset your password ? You will be logged out once your password is reset successfully.',
      });
    })
  }

  inputValidation(key, value, state){
    if(key == 'confirmPassword'){
      return AccountManagementUtils.validateConfirmPassword(value, state?.password || this.state.password);
    }
    return inputValidator(key, value);
  }

  onBack() {  
    this.props.router.goBack();
  }
  
  render(){
    if(this.userData.ldap){
      return (
        <ErrorPage params={{error:403}}/>
      );
    }else{
      return (
        <ExternalPageContainer title='Reset Password' hideLogo>
          {this.state.error ? <ExternalPageAlert isError title='Error While Resetting Password' 
            message='Current password does not match your old password'/> : null}
  
          <FormWithFooter
            inputs={AccountManagementUtils.getPasswordsFormInputs(this.state, true, this.setState.bind(this))}
            validationFunction={this.inputValidation}
            expandFullWidth
            noFooterBorder
            onChange={(result) => this.setState({...result})}
            onCancel={this.onBack}
            onSubmit={this.onSubmit}
            submitButtonText='Reset Password'
          />
        </ExternalPageContainer>
      );
    }
	}
}