/* Copyright 2019 Greyskies. All Rights Reserved. */

import {AbilityContext} from './Ability';
import React from 'react';

const WithAbilityContext = Comp => props => (
  <AbilityContext.Consumer>
    {ability => <Comp ability={ability} {...props} />}
  </AbilityContext.Consumer>
);

export default WithAbilityContext;