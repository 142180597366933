/* Copyright 2019 Greyskies. All Rights Reserved. */

import BigDecimal from 'bigdecimal';
import defaults from 'config/defaults';
import * as associationConfigDefaults from 'config/associationConfigDefaults';
import * as topologyDefaults from 'config/topologyDefaults';
import React from 'react';
import { DateTimeUtils, WidgetTypes, FieldTypes} from 'js-utils';
import {DataSelectionCommonUtils, Icons} from 'react-template';
import { MAIN_ROUTES } from 'config/routesDefaults';
import {MenuItem, DropdownButton} from 'react-bootstrap';
import * as designSpaceDefaults from 'config/designSpaceDefaults';

const MAX_64_DECIMAL = 18446744073709551615;
const MAX_JS_DECIMAL_STRING = '18446744073709551616';
const breakpoints = { lg: 0 };

function formatSerie(serie, dateIndex, format) {
  if (dateIndex > -1) {
    const serieFields = serie.split(' - ');

    if(dateIndex < serieFields.length){
      serieFields[dateIndex] = DateTimeUtils.epochToCustomDateFormat(
        (format || defaults.CHART_FORMAT).dateFormat, serieFields[dateIndex]);
    }

    return serieFields.join(' - ');
  }

  return serie;
}

export default{
  isFontSizeValid(fontSize) {
    if (defaults.WHOLE_INTEGERS_REGEX.test(fontSize)
      && (parseInt(fontSize) >= associationConfigDefaults.MIN_FONT_SIZE)) {
      return true;
    }

    return false;
  },
  addFavicon(brandLogo) {
    if(document.querySelector('link[rel="icon"]')){
      document.querySelector('link[rel="icon"]').setAttribute('href',brandLogo);
    } else {
      const link = document.createElement('link');

      link.rel = 'icon';
      link.id='favicon';
      link.type = 'image/png;base64';
      link.href = brandLogo;
      document.querySelector('head').appendChild(link);
    }
  },
  getCurrentScreenSize() { // to be removed
    const screenWidth = window.innerWidth;
    let result = null;
    const sortedBreakpoints
      = Object.keys(breakpoints).sort((a, b) => breakpoints[b] - breakpoints[a]);

    for (let index = 0; index < sortedBreakpoints.length; index++) {
      const breakpoint = sortedBreakpoints[index];

      if (screenWidth > breakpoints[breakpoint]) {
        result = breakpoint;
        break;
      }
    }

    return result;
  },

  getBreakpoints() { // to be removed 
    return breakpoints;
  },
  capitalize(string) {
    return string.replace(/\w\S*/g, (str) => {
      return str.charAt(0).toUpperCase() + str.substr(1).toLowerCase();
    });
  },
  getQueryForChartSeries(seriesFields, currentSeries, chartType,
    dateRangefrom, dateRangeto, dataSelectionArgs, widgetId, templatesPlaceHoldersValues, drillDownFilters, quickFilters) {
    if (widgetId) {
      return {
        seriesFields,
        currentSeries,
        chartType,
        dateRangefrom,
        dateRangeto,
        widgetId,
        templatesPlaceHoldersValues,
        drillDownFilters,
        quickFilters,
      };
    }

    return {
      seriesFields,
      currentSeries,
      chartType,
      dateRangefrom,

      dateRangeto,
      ffag: DataSelectionCommonUtils.getDataSelectionServerObj(dataSelectionArgs),
      templatesPlaceHoldersValues,
      drillDownFilters,
    };
  },
  getDateRangeForQuery(config, startReferenceDate) {
    return DateTimeUtils.getDateFromRelativeRanges(config.dateRange, startReferenceDate);
  },
  formatChartSeries(series, seriesFields, format, isTriggeredByDrillDown) {
    if(!isTriggeredByDrillDown){
      seriesFields.sort();
    }
    const dateIndex = seriesFields.indexOf(FieldTypes.DATE_FIELD_NAME);

    if (dateIndex > -1) {
      const formattedSeries = {};

      Object.keys(series).forEach((serie) => {
        formattedSeries[formatSerie(serie, dateIndex, format)] = series[serie];
      });

      return formattedSeries;
    }

    return series;
  },
  isBlank(object) {
    return object == undefined || object == null;
  },
  getMaxNumberOfSelections() {
    return defaults.MAX_NUMBER_OF_SELECTIONS;
  },
  getInitalizationMessage(widgetType, widgetName) {
    const message = WidgetTypes.isTopology(widgetType) ?
      topologyDefaults.NEW_WIDGET_ASSOCIATION :
      `Please set the ${widgetName} settings.`;

    return (<div> {message} </div>);
  },
  sortXaxesByGroupByOrder(xAxes, groupByFields) {
    const sortedXaxes = [];

    if (xAxes) {
      groupByFields.forEach((groupByField) => {
        const fullFieldName = DataSelectionCommonUtils.getFullFieldName(groupByField.selectedField);

        if (xAxes.includes(fullFieldName)) {
          sortedXaxes.push(fullFieldName);
        }
      });
    }

    return sortedXaxes;
  },
  formatSerie,
  validateBigDecimals(value){
    let val;
    const ERROR_MSG = 'This value should produce the validation error message';
    
    try {
      const bigDecimalValue = new BigDecimal.BigDecimal(value);
      const max64 = new BigDecimal.BigDecimal(MAX_64_DECIMAL);
      const zero = new BigDecimal.BigDecimal(0);

      if( bigDecimalValue.compareTo(zero) === -1 || bigDecimalValue.compareTo(max64) === 1){
        val = ERROR_MSG;
      }else if (value === MAX_JS_DECIMAL_STRING){
        val = ERROR_MSG;
      }else{
        val = value;
      }
    } catch (e) {
      val = ERROR_MSG;
    } finally {
      return val;
    }
  },
  navigateToErrorPath(errorCode, router){
    if(errorCode === 403){
      router.replace({
        pathname: MAIN_ROUTES.ADMIN_ERROR,
        query: {
          code: errorCode,
        },
      });
    }
  },
  getKeyFromId(entity){
    return entity.idCounter != undefined ? `${entity.idCounter}_counter` : `${entity.id}`;
  },
  dispatchEvent(eventName){
    const event = document.createEvent('Event');

    event.initEvent(eventName, true, true);
    window.dispatchEvent(event);
  },
  dispatchCustomEvent(eventName, detail){
    const event = new CustomEvent(eventName, {detail});

    window.dispatchEvent(event);
  },
  organizeWidgetsAccordingToCategory(Widgets, onClickFunction, designSpaceTypeFlag, dropDownButtonClassName){
    const categorizedMenu = {};
    
    Object.keys(Widgets).forEach(
      (key, index) => {
        if(!categorizedMenu[Widgets[key].category]){
          categorizedMenu[Widgets[key].category] = [];
        }
        categorizedMenu[Widgets[key].category].push(
          Widgets[key][designSpaceTypeFlag] && Widgets[key].available ?
          <MenuItem key={index}
          className={`widget-${Widgets[key].name.replace(/ +/g, "").toLowerCase()}-menuItem`}
          onClick={ () => onClickFunction(key)}>
          <i className={Widgets[key].icon}></i>
          {Widgets[key].name}
        </MenuItem> : null
        );
      });
    const availableWidgets = [];

    Object.keys(categorizedMenu).forEach(
      (key) => {
        if(key == designSpaceDefaults.UNCATEGORIZED){
          availableWidgets.push(...categorizedMenu.uncategorized);
        }else{
          availableWidgets.push(
            <MenuItem key={key}
              className={'widget-submenu-menuItem'}>
              <DropdownButton noCaret className={dropDownButtonClassName}
              title={
                <span>
                  <i className={Icons.chevronLeft}></i>
                  {key}
                </span>
              }>
                {categorizedMenu[key]}
              </DropdownButton>
            </MenuItem>
          );
        }
      }
    );

    return availableWidgets;
  },

};
