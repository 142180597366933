/* Copyright 2019 Greyskies. All Rights Reserved. */

import keyMirror from 'keymirror';

export default keyMirror({
  GET_USER_LIST: null,
  SET_FIELD: null,
  VALIDATE_EMAIL: null,
  GET_RANKS: null,
  GET_GROUP: null,
  ADD_USER: null,
  GET_USER : null,
});
