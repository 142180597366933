/* Copyright 2019 Greyskies. All Rights Reserved. */

import {ValidationUtils, ColorPalette, FieldTypes} from 'js-utils';
import {Icons} from 'react-template';
import AppStore from 'stores/AppStore';

export const DATE_FIELDS = [
  FieldTypes.DATE_FIELD_NAME,
  FieldTypes.DATE_FIELD_ES_NAME,
];
const SOURCE_LINK_OPTION = 'SOURCE';
const DESTINATION_LINK_OPTION = 'DESTINATION';
const LINK_LINK_OPTION = 'LINK';
const SOURCE_DESTINATION_LINK_OPTION = 'SOURCE_DESTINATION';
const CANNOT_RETRIEVE_DATA = 'Can\'t retrieve data';
const HORIZONTAL = 'horizontal';
const VERTICAL = 'vertical';

const legendDirectionDefaults = {
  [VERTICAL]: 'right',
  [HORIZONTAL]: 'bottom',
};

const legendLabelWidthDefaults = {
  [VERTICAL]: null,
  [HORIZONTAL]: 150,
};

export const chartWidgetDefaults = {
  HORIZONTAL,
  VERTICAL,
  legendDirectionDefaults,
  legendLabelWidthDefaults,
  defaultRecordFields: {
    DATE :  FieldTypes.DATE_FIELD_ES_NAME,
    Aggregate: FieldTypes.AGGREGATE_FIELD_NAME,
  },
};

export const TopologyWidgetDefaults = {
  SOURCE_LINK_OPTION,
  DESTINATION_LINK_OPTION,
  LINK_LINK_OPTION,
  SOURCE_DESTINATION_LINK_OPTION,
  LINK_OPTIONS: [
    {
      name: 'Source',
      value: SOURCE_LINK_OPTION,
    },
    {
      name: 'Destination',
      value: DESTINATION_LINK_OPTION,
    },
    {
      name: 'Link',
      value: LINK_LINK_OPTION,
    },
  ],
  SOURCE_DESTINATION_OPTION: {
    name: 'Source and Destination',
    value: SOURCE_DESTINATION_LINK_OPTION,
  },
  TOPOLOGY_LAYOUTS: {
    TOPOLOGY_DEFAULT_LAYOUT : 'concentric',
    TOPOLOGY_SAVED_LAYOUT : 'random',
  },
};

const LINEAR = 'linear';
const LINEAR_SCALE_TITLE = 'Linear';
const LOG_SCALE_TITLE = 'Log';
const LOG = 'log';
const FORMAT_ERROR = 'There are errors in the format';

const LOG_BASE_DEFAULT = 10;

const getDefaultLogScaleRanges = (colorPaletteType) => {
  const rangesColor = ColorPalette.getRangesColor(colorPaletteType);

  return [
    {
      color: rangesColor[0],
      from: 1,
      to: 10,
    },
    {
      color: rangesColor[1],
      from: 10,
      to: 100,
    },
    {
      color: rangesColor[2],
      from: 100,
      to: 1000,
    },
  ];
};

const getDefaultRanges = (colorPaletteType) => {
  const rangesColor = ColorPalette.getRangesColor(colorPaletteType);

  return [
    {
      color: rangesColor[0],
      from: 0,
      to: 80,
    },
    {
      color: rangesColor[1],
      from: 80,
      to: 90,
    },
    {
      color: rangesColor[2],
      from: 90,
      to: 100,
    },
  ];
};
const ASC = 'ASC';
const DESC = 'DESC';

export const RESIZE_REFRESH_RATE = 500;

export const GaugeDefaults = {
  LINEAR,
  LINEAR_SCALE_TITLE,
  LOG_SCALE_TITLE,
  LOG,
  getDefaultLogScaleRanges,
  LOG_BASE_DEFAULT,
};

export const commonWidgetDefaults = {
  getDefaultRanges,
  ASC,
  DESC,
};

export const WIDGET_MESSAGES = {
  TABLE_INVALID_SPLITTER: ValidationUtils.ERR_MSGS.required('Splitter').message,
  ML_NOT_MATCHED_RESOLUTION: 'Missing selected time resolution',
  TOPN_CHART_FIELDS_EXCEED: 'Can\'t retrieve data, please select only one aggregate',
  INVALID_XAXIS: CANNOT_RETRIEVE_DATA,
  TOPN_TABLE_INVALID_TOPNFIELD: 'Can\'t retrieve data, please select a field to sort by',
  CANNOT_RETRIEVE_DATA,
  FORMAT_ERROR,
  WIDGET_TOO_MANY_POINTS: 'Displayed data exceeds the allowed limit, please re-adjust your settings',
  CANNOT_APPLY_PRODUCT_VALUE_AGGREGATION: 'Cannot retrieve data with this selection of arguments',
  NO_DATA_MESSAGE: 'No data in time range to be shown',
  MISSING_PLACEHOLDERS: 'There are missing placeholders',
  WEIGHTED_AVG_HAS_MULTI_VALUED_FIELD: 'Weighted average function does not support multi-valued fields. Please select another field.',
};

export const DEFAULT_DRILLDOWN_CHART_TYPE_OPTIONS = [
  {value: 'LINE', content: Icons.lineChart + Icons.styles.evLg, contentType: 'icon', name: 'line'},
  {value: 'BAR', content: Icons.barChart + Icons.styles.evLg, contentType: 'icon', name: 'bar'}];

export const LINE_CHART_STROKE_TYPE_OPTIONS = [
  {value: 'SOLID', content: Icons.solid, contentType: 'icon', name: 'Solid'},
  {value: 'DASHED', content: Icons.dashed, contentType: 'icon', name: 'Dashed'},
  {value: 'DOTTED', content: Icons.dotted, contentType: 'icon', name: 'Dotted'},
];

export const LINE_CHART_LABELS = {
  THICKNESS: 'Line Thickness',
  STROKE_TYPE: 'Line Style',
};

export const LINE_CHART_DEFAULTS = {
  MIN_LINE_THICKNESS: 1,
  MAX_LINE_THICKNESS: 8,
  DEFAULT_LINE_THICKNESS: 2,
  DEFAULT_STROKE_TYPE: 'SOLID',
};
export const CHART_OVERLAY_OPTIONS = [
  {value: 'LINE', content: Icons.dottedLayer, contentType: 'icon', name: 'Line'},
  {value: 'BOX', content: Icons.areaLayer, contentType: 'icon', name: 'Box'}];

export const PIE_CHART_DEFAULTS = {
  PERCENTAGE_VALUE: 'Percentage',
  ABSOLUTE_VALUE: 'Absolute',
};

export const WIDGET_TOO_MANY_POINTS = 'WIDGET_TOO_MANY_POINTS';
export const DEFAULT_DRILLDOWN_LABEL = 'Last level chart type';

export const EXTRA_FIELDS = 'extraFields';
export const TREND_LINE = 'trendLine';

export function getTrendLineDefault(){
  return {
    color: ColorPalette.getMainColor(AppStore.getColorPaletteType())
  };
}

export function getOverlayColorDefault(){
  return {
    color: ColorPalette.getMainColor(AppStore.getColorPaletteType())
  };
}

export const SERIES_FIELDS = [
  FieldTypes.ATTRIBUTE,
  FieldTypes.NETWORK_ELEMENT,
];

export function getDefaultThreshold(){
  const palleteColor = ColorPalette.getMainColor(AppStore.getColorPaletteType());

  return {
    title:'',
    value: 0,
    color: palleteColor,
    axis:'y',
  };
}

export function getDefaultSeriesForTimeComparison(){
  const palleteColor = ColorPalette.getMainColor(AppStore.getColorPaletteType());

  return {
    name:'',
    color: palleteColor,
  };
}
export function getNewExpression(){
  const palleteColor = ColorPalette.getMainColor(AppStore.getColorPaletteType());

  return {
    color: palleteColor,
    new: true,
  };
}

