/* Copyright 2019 Greyskies. All Rights Reserved. */

const CHANGE_EVENT = 'change';

import AppDispatcher from 'dispatcher/AppDispatcher';
const EventEmitter = require('events').EventEmitter;

export default class BaseStore extends EventEmitter{

  constructor(){
      super();
      this.actionHandlers = {};
  }

  emitChange(){
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  }


  addActionHandler(action, handler){
    this.actionHandlers[action] = handler;
  }

  registerWithDispatcher(){
    AppDispatcher.register( action => this.fireActionHandler(action) );
  }

  fireActionHandler(action){
    const {actionType} = action;
    if(typeof this.actionHandlers[actionType] === 'function')
      this.actionHandlers[actionType](action);
  }

};
