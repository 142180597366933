/* Copyright 2019 Greyskies. All Rights Reserved. */

// Basic Info defaults
const SEVERITIES = {BLOCKER: 'Blocker', CRITICAL: 'Critical', MAJOR: 'Major', MINOR: 'Minor', WARNING: 'Warning', INFO: 'Info'};
const TYPES = {PAIRED: 'Paired Event', NON_PAIRED: 'Non-Paired Event'};
const EVENT_INFO_FIELDS = {
  'runStatus': 'Status',
  'runTime': 'Run Time',
  'coAt': 'Created At',
  'generateConfigTime' : 'Generated Config Time',
  'userName' : 'Approved By',
  'downloadPath' : 'Logs',
};
const EVENT_ACTIONS_CONDITIONS = {
  'RAISE': 'Raise',
  'CLEAR': 'Clear',
};
const EVENT_TITLE = 'Event';
const EVENT_PLURAL_EXISTENCE = 'event(s)';
const PAIRED_TYPE = Object.keys(TYPES)[0];
const NON_PAIRED_TYPE = Object.keys(TYPES)[1];
const ML_JOB = 'ML_JOB';
const ML_EVENT = 'ML';
const BULK = 'BULK';
const EVENT_ARGUMENTS_TITLE = 'Event Arguments';
const BULK_TITLE = 'Bulk ';
const INSTANCE = 'INSTANCE';
const ATTRIBUTE = 'ATTRIBUTE';
const RECORD = 'RECORD';
const DEFAULT_STOP_AFTER_STEPS = 2;
const DEFAULT_TRIGGER_AFTER_STEPS = 1;
const NO_RUN_ID_MSG = 'No Run ID selected';
const NO_DATA_AVAILABLE_MSG = 'no data available';
// Action Step default
const RAISE = 'RAISE';
const CLEAR = 'CLEAR';
const HIGH = 'HIGH';
// ActivationTime step defaults

const ALWAYS_ACTIVE = 'always_active';
const SPECIFIC_PERIOD = 'specific_period';
const ACTIVATION_CALENDAR = 'ACTIVATION_CALENDAR';

const ACTIVATION_STATUSES = [{key : ALWAYS_ACTIVE, label: 'Always Active'},
                    {key : SPECIFIC_PERIOD, label: 'Specific Period'},
                    {key : ACTIVATION_CALENDAR, label: 'Activation Calendar'}];

const TIME_REQUIRED_MESSAGE = 'At least one time period is required';
const DATE_REQUIRED_MESSAGE = 'At least one date range is required';
const INVALID_TIME_MESSAGE = 'This time is not valid';
const INVALID_DATE_MESSAGE = 'This date is not valid';
const VALID_START_END_TIME_MESSAGE = 'End time should be after start time';
const VALID_START_END_DATE_MESSAGE = 'End date should be after start date';
const EVENT_ADD_BUTTON_TOOLTIP = 'New Event';
const MAX_INTEGER_VALUE = 2147483647;
const START_AFTER_LABEL = 'Start After*';
const TRIGGER_AFTER_LABEL = "Trigger After*";
const STOP_AFTER_LABEL = "Stop After*";
const ANALYST_ACTION = 'Analyst_Action';
const SIMPLE_SELF_HEALING_ACTION = 'Simple_Self_Healing';
const ANALYST_QUERY_VALUE = 'ANALYST';
const SIMPLE_QUERY_VALUE = 'SIMPLE';
const NETWORK_ELEMENT_PLACEHOLDER = 'Network Element';
const TEMPLATE_NAME_PLACEHOLDER = 'Template Name';
const SPECIFIC_TIME = 'Specific Time';
const SPECIFIC_DATE = 'Specific Date';
const DATE = 'Date';
const SHOW_SEVERITY_HINT = 'Enabling lower severities will automatically include higher severities';
const AUTOMATION_ACTION_OPTIONS = [{label:'Simple', value: SIMPLE_SELF_HEALING_ACTION},
                                    {label:'Analyst In The Loop', value: ANALYST_ACTION}];
const BASIC_STEP_LABELS = {
  NAME: 'Name',
  SEVERITY: 'Severity',
  RECORD_TYPE: 'Record Type',
  NETWORK_ELEMENT: 'Network Element',
};
const CONDITION_STEP_LABELS = {
  CONDITION: 'Condition',
  EVALUATION: 'Evaluation',
  CONDITION_GROUP: 'Condition Group',
};
const TAGS_STEP_LABELS = {
  TAG: 'Tag',
  TAGS: 'Tags',
  NAME: 'Name',
  TYPE: 'Type',
  VALUES: 'Values',
  VALUE: 'Value',
  STATIC : 'Static',
  INHERITED_TAGS: 'Inherited tags',
};
const TAGS_TYPES = {
  STATIC: 'STATIC',
  INHERITED: 'INHERITED',
};
const TAGS_INPUt_PLACEHOLDER = 'Add values separated by semi-colon or enter.';
const ERR_MSGS = {
  RELATION_BETWEEN_CONDITIONS: "Relation to next condition",
  RELATION_BETWEEN_CONDITION_GROUPS: "Relation between Condition groups",
  INTEGER_VALUES: 'Values should be Integer',
  DOUBLE_VALUES: 'Values should be real numbers with max precision 10',
  BOOLEAN_VALUES: 'Values should be boolean',
};
const ML_ALERT_OPTIONS_VALS = {
  BOTH: 'BOTH',
  LOWER: 'LOWER',
  UPPER: 'UPPER',
  HIGH: 'HIGH',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
};
const ML_ALERT_OPTIONS_LABELS = {
  APPLY: 'Apply',
  LOWER: 'Lower',
  UPPER: 'Upper',
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
  ALERT_OPTIONS: 'Alert Options',
  BOTH: 'Upper and Lower',
};
const TAGS_SEPERATORS = [';', '\n', '\r', '\t'];

const ACTIONS = {ACTIVE: 'Activate', INACTIVE: 'Deactivate'};
const STATUS = {ACTIVE: 'Active', INACTIVE: 'Inactive'};

const CLONE = 'Clone';

const eventDefaults = {
  BASIC_STEP_LABELS,
  CONDITION_STEP_LABELS,
  ERR_MSGS,
  SEVERITIES,
  EVENT_ACTIONS_CONDITIONS,
  TYPES,
  NON_PAIRED_TYPE,
  PAIRED_TYPE,
  ML_JOB,
  ML_EVENT,
  BULK,
  EVENT_TITLE,
  BULK_TITLE,
  INSTANCE,
  EVENT_ARGUMENTS_TITLE,
  ALWAYS_ACTIVE,
  SPECIFIC_PERIOD,
  ACTIVATION_CALENDAR,
  ACTIVATION_STATUSES,
  NETWORK_ELEMENT_PLACEHOLDER,
  TEMPLATE_NAME_PLACEHOLDER,
  TIME_REQUIRED_MESSAGE,
  DATE_REQUIRED_MESSAGE,
  VALID_START_END_TIME_MESSAGE,
  VALID_START_END_DATE_MESSAGE,
  INVALID_TIME_MESSAGE,
  INVALID_DATE_MESSAGE,
  MAX_INTEGER_VALUE,
  RAISE,
  CLEAR,
  HIGH,
  EVENT_ADD_BUTTON_TOOLTIP,
  RECORD,
  DEFAULT_TRIGGER_AFTER_STEPS,
  DEFAULT_STOP_AFTER_STEPS,
  ATTRIBUTE,
  START_AFTER_LABEL,
  TRIGGER_AFTER_LABEL,
  STOP_AFTER_LABEL,
  AUTOMATION_ACTION_OPTIONS,
  ANALYST_ACTION,
  SIMPLE_SELF_HEALING_ACTION,
  ANALYST_QUERY_VALUE,
  SIMPLE_QUERY_VALUE,
  EVENT_INFO_FIELDS,
  NO_RUN_ID_MSG,
  NO_DATA_AVAILABLE_MSG,
  SPECIFIC_TIME,
  SPECIFIC_DATE,
  DATE,
  ML_ALERT_OPTIONS_VALS,
  ML_ALERT_OPTIONS_LABELS,
  STATUS,
  CLONE,
  ACTIONS,
  SHOW_SEVERITY_HINT,
  EVENT_PLURAL_EXISTENCE,
  TAGS_STEP_LABELS,
  TAGS_TYPES,
  TAGS_SEPERATORS,
  TAGS_INPUt_PLACEHOLDER,
};


export default eventDefaults;
