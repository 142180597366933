/* Copyright 2019 Greyskies. All Rights Reserved. */

import 'react-template/lib/css/main.css';
import 'web-style-guide/css/login.less';
import React from 'react';
import AppActions from 'actions/AppActions';

import {FormWithFooter, Icons, TooltippedButton} from 'react-template';
import LoginAPI from 'api/LoginAPI';
import { withRouter } from 'react-router';
import ExternalPageContainer from './ExternalPageContainer';
import { Row } from 'react-bootstrap';
import { MsgDefaults } from 'js-utils';

import 'styles/login/resetPassword.less';
import ExternalPageAlert from './ExternalPageAlert';

@withRouter
export default class Login extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      loginStatus: 'default',
      loading: false,
      showPassword: false,
      firstLoad: true,
    };
    this.getFormInputs = ::this.getFormInputs;
    this.login = ::this.login;
    this.onForgetPassword = ::this.onForgetPassword;
    this.getAlert = ::this.getAlert;
  }

  componentDidMount(){
    if(!this.props.expired){
      document.getElementsByName('username')[0].focus();;
    }
  }

  login(){
    const formData = {
      username: this.state.username,
      password: this.state.password,
    };

    this.setState({ loading : true, firstLoad: false }, () => { 
      LoginAPI.submitLogin(formData).then(value => {
        if(value.loginStatus === true){
          AppActions.getUserData();
          AppActions.getAppConfigs();
        }        
        this.setState({
          loginStatus: value.loginStatus,
          loading: value.loginStatus,
        });
      });
    });
  }

  onForgetPassword(){
    this.props.router.push({
      pathname:'/forgetpassword',
    });
  }

  getShowHidePasswordBtn(stateKey){
    return (
      <TooltippedButton 
        onClick={() => this.setState({[stateKey]: !this.state[stateKey]})}
        tooltip={MsgDefaults.getShowHideTitle('Password', this.state[stateKey])} className='btn-primary togglePasswordBtn'
      >
        <i className={this.state[stateKey] ? Icons.hide : Icons.view}></i> 
      </TooltippedButton>
    );
  }

  getFormInputs(){
    return [{
      type: 'text',
      keyword: 'username',
      placeholder: 'Username',
      validationLabel: 'Username',
      validation : false,
      required: true, 
      trimmed: true,
      forceUpdateOnChange: true,
      initialValue: this.state.username,
      variableSizes: {
        inputSize: 12,
      },
    },
    {
      type: 'text',
      keyword: 'password',
      placeholder: 'Password',
      validationLabel: 'Password',
      validation : false,
      required: true, 
      trimmed: true,
      forceUpdateOnChange: true,
      password: !this.state.showPassword,
      initialValue: this.state.password,
      variableSizes: {
        inputSize: 12,
      },
      buttons: this.getShowHidePasswordBtn('showPassword'),
    }];
  }

  getAlert() {
    if(!this.state.loginStatus) {
      return <ExternalPageAlert isError title='Login Failed!' message='Insert a valid username and password.'/>;
    }      
    
    const successMessage = this.props.location.state?.successMessage;
    if(this.state.firstLoad && successMessage) {
      if(successMessage == 'RESET_PASSWORD') {
        return <ExternalPageAlert title='Success' message='Your password has been reset successfully.'/>;
      } else if(successMessage == 'USER_REGISTRATION') {
        return <ExternalPageAlert title='Success' message='Your account has been created successfully.'/>;
      }
    }
  }

  render() {
    return (
      <ExternalPageContainer>
        {this.getAlert()}
        { this.props.expired ? <Row>
            <div className="alert alert-warning" >
              Your license has expired. Please contact support.
            </div>
          </Row>
          :<FormWithFooter
            inputs={this.getFormInputs()}
            validateOnFirstLoad
            submitOnEnter
            hideCancelButton
            expandFullWidth
            noFooterBorder
            submitButtonText='Login'
            onChange={(result) => this.setState({...result})}
            disableSaveButton={
              !this.state.isFormValidated 
              || this.state.loading
            }
            onSubmit={this.login}
            footerButtons={
              <div className="pull-left">
                <div onClick={this.onForgetPassword} className='evc-default-link'>
                  <a>Forgot Password?</a>
                </div>
              </div>
            }
          />
        }
      </ExternalPageContainer>
    );
  }
}
