/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as Utils from '../Utils';
import {parseValueByType, PARSING_LINE_TYPES} from './FileTransformer';
import {insertOrUpdateByObjectKey} from '../ArrayUtils';
const LINE_DELIMITER = '\n';
const CELL_DELIMITER = ',';

const mapValueUsingMappingObject = (value, fieldMapper) => {
  if(fieldMapper.mappingObject.hasOwnProperty(value)){
    return fieldMapper.mappingObject[value];
  }

  return value;
};

const validateHeaders = (headers, headersDescriptor) => {
  for(const key in headersDescriptor) {
    if(headersDescriptor.hasOwnProperty(key) 
      && headersDescriptor[key].required 
      && !headers.includes(key)){
      return false;
    }
  }

  return true;
};

const parseAndMapValue = (value, fieldMapper) => {
  const parsedValue = parseValueByType(value, fieldMapper.type);

  if(fieldMapper.mappingFunction){
    return fieldMapper.mappingFunction(parsedValue);
  } else if(fieldMapper.mappingObject) {
    return mapValueUsingMappingObject(parsedValue, fieldMapper);
  }

  return parsedValue;
};

const readLineAsObject = (element, headers, headersDescriptor) => {
  const values = element.split(CELL_DELIMITER);
  const row = headers.reduce((fieldData, header, index) => {
    const fieldMapper = headersDescriptor[header];

    if(fieldMapper && !fieldData.hasOwnProperty(fieldMapper.property)){
      fieldData[fieldMapper.property] = parseAndMapValue(values[index], fieldMapper);
    }

    return fieldData;
  }, {});

  return row;
};

const readLineAsSingleValue = (element, headers, headersDescriptor) => {
  const header = headers[Object.keys(headers)[0]];
  const fieldMapper = headersDescriptor[header];

  return parseAndMapValue(element, fieldMapper);
};

const readLine = (element, headers, transformParams) => {
  const headersDescriptor = transformParams.headersDescriptor;

  switch (transformParams.parsingLineType) {
    case PARSING_LINE_TYPES.OBJECT:
      return readLineAsObject(element, headers, headersDescriptor);  
    default:
      return readLineAsSingleValue(element, headers, headersDescriptor);
  }
};

const writeLine = (descriptor, element) => {
  const row = [];
  const headers =  Object.keys(descriptor);

  headers.forEach((header) => {
    let value = Utils.getObjectValueByPropertyPath(element,
      descriptor[header].property);
    const fieldMapper = descriptor[header];

    if(fieldMapper.mappingFunction){ // this path is not used
      value = fieldMapper.mappingFunction(element);
    } else if(fieldMapper.mappingObject) {
      value = mapValueUsingMappingObject(value, fieldMapper);
    }

    row.push(value);
  });

  return row;
};

export const parseFile = (csv, transformParams) => {
  const headers = csv.slice(0, csv.indexOf(LINE_DELIMITER))
    .split(CELL_DELIMITER)
    .map(header => header.trim());

  if(!validateHeaders(headers, transformParams.headersDescriptor)){
    return null;
  }

  let totalCount = 0;
  const rows = [];
  const rowsText = csv.slice(csv.indexOf(LINE_DELIMITER) + 1)
    .split(LINE_DELIMITER);

  rowsText.forEach(rowText => {
    if(!Utils.isEmptyString(rowText.replaceAll(CELL_DELIMITER, ''))){
      const row = readLine(rowText, headers, transformParams);
      
      if(transformParams.rowFormatter){
        transformParams.rowFormatter(row);
      }
      if(!transformParams.postFormattingRowValidator 
        || transformParams.postFormattingRowValidator(row)){
        if(transformParams.insertOrUpdateByObjectKey){
          insertOrUpdateByObjectKey(rows, row, transformParams.insertOrUpdateByObjectKey);
        } else {
          rows.push(row);
        }
      }

      totalCount++;
    }
  });

  return {
    data: rows,
    totalCount,
  };
};

export const writeFile = (data, transformParams) => {
  const headers = Object.keys(transformParams.headersDescriptor);
  let fileContent = headers.toString();
  let exportedData = data;

  if(transformParams.preMappingFiltering){
    exportedData = transformParams.preMappingFiltering(data);
  }

  exportedData.forEach((element) => {
    const row = writeLine(
      transformParams.headersDescriptor,
      element
    ).toString();

    fileContent = fileContent + LINE_DELIMITER + row;
  });

  return fileContent;
};