export const WIDGET_TYPES = {
  CHART: 'Chart',
  PIE_CHART: 'PieChart',
  TOPN_CHART:'TopNChart',
  TIME_COMPARISON_CHART: 'TimeComparisonChart',
  MLANOMALY:'MLAnomaly',
  MLFORECAST:'MLForecast',
  TOPN_TABLE: 'TopNTable',
  TABLE:'Table',
  RAWDATA:'RawDataTable',
  METRIC:'Metric',
  GAUGE:'Gauge',
  MAP: 'Map',
  TOPOLOGY:'Topology',
  PAGEBREAK : 'PageBreak',
  IFRAME: 'iFrame',
  MARKDOWN: 'Markdown',
};
  
  /// Widget checkers
export function isChart(widgetType){
  return widgetType === WIDGET_TYPES.CHART;
}

export function isPieChart(widgetType){
  return widgetType === WIDGET_TYPES.PIE_CHART;
}

export function isTopNChart(widgetType){
  return widgetType === WIDGET_TYPES.TOPN_CHART;
}

export function isTopNTable(widgetType){
  return widgetType === WIDGET_TYPES.TOPN_TABLE;
}

export function isTable(widgetType){
  return widgetType === WIDGET_TYPES.TABLE;
}

export function isRawData(widgetType){
  return widgetType === WIDGET_TYPES.RAWDATA;
}

export function isMetric(widgetType){
  return widgetType === WIDGET_TYPES.METRIC;
}

export function isGauge(widgetType){
  return widgetType === WIDGET_TYPES.GAUGE;
}

export function isAggregatedTypeWidget(widgetType){
  return isMetric(widgetType) || isGauge(widgetType);
}

export function isTopology(widgetType) {
  return widgetType == WIDGET_TYPES.TOPOLOGY;
}
export function isMLAnomaly(widgetType){
  return widgetType === WIDGET_TYPES.MLANOMALY;
}

export function isMLForecast(widgetType){
  return widgetType === WIDGET_TYPES.MLFORECAST;
}

export function isPageBreak(widgetType){
  return widgetType === WIDGET_TYPES.PAGEBREAK;
}

export function isIFrame(widgetType){
  return widgetType === WIDGET_TYPES.IFRAME;
}

export function isMarkdown(widgetType){
  return widgetType === WIDGET_TYPES.MARKDOWN;
}

export function isMap(widgetType){
  return widgetType === WIDGET_TYPES.MAP;
}

export function isTimeComparisonChart(widgetType){
  return widgetType === WIDGET_TYPES.TIME_COMPARISON_CHART;
}

// Type Checkers

export function isMLBased(widgetType){
  return isMLAnomaly(widgetType) 
    || isMLForecast(widgetType);
}

export function isTopNBased(widgetType){
  return isTopNChart(widgetType)
    || isTopNTable(widgetType);
}

export function isTableBased(widgetType){
  return isTable(widgetType) 
    || isTopNTable(widgetType)
    || isRawData(widgetType);
}

export function isChartBased(widgetType){
  return isChart(widgetType) 
    || isPieChart(widgetType) 
    || isTopNChart(widgetType)
    || isTimeComparisonChart(widgetType);
}

export function isGeneralChartBased(widgetType){
  return isChartBased(widgetType) || isMLBased(widgetType);
}

export function isSingleBased(widgetType){
  return isGauge(widgetType)
    || isMetric(widgetType);
}

export function isNoDataBased(widgetType){
  return isPageBreak(widgetType)
    || isIFrame(widgetType)
    || isMarkdown(widgetType);
}