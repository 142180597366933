/* Copyright 2019 Greyskies. All Rights Reserved. */

import request from './request';

export default{
  fetchMarkdownHelpFile:()=>{
    return request('user/dashboard/widget/helpfile/markdown',{
      responseType: 'text',
    });
  },

  fetchDateFormateHelpFile:()=>{
    return request('user/dashboard/widget/helpfile/dateformat',{
      responseType: 'text',
    });
  },

  fetchPrivacyPolicyHelpFile:()=>{
    return request('app/footerProps/helpfile/privacyAndPolicy',{
      responseType: 'text',
    });
  },

  fetchTermsHelpFile:()=>{
    return request('app/footerProps/helpfile/terms',{
      responseType: 'text',
    });
  },
}
