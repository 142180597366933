/* Copyright 2019 Greyskies. All Rights Reserved. */
import { AUTOMATION_WORKFLOWS } from 'config/pagesHeaderTitle';

export const LABELS = {
  AUTOMATION_WORKFLOW: 'Workflow',
  NAME: 'Name',
  WORKFLOW_NAME: 'Workflow Name',
  WORKFLOW_VARIABLES: 'Workflow Variables',
  WORKFLOW_VARIABLE: 'Workflow Variable',
  EXTERNAL_VARIABLES: 'External Variables',
  EXTERNAL_VARIABLE: 'External Variable',
  INTERNAL_VARIABLES: 'Internal Variables',
  INTERNAL_VARIABLE: 'Internal Variable',
  VARIABLE: 'Variable',
  VARIABLES: 'Variables',
  WORKSPACE_INTERNAL_VARIABLE: 'workspace',
  STAGE_NAME_INTERNAL_VARIABLE: 'stageName',
  ACTIVATION_LINK_INTERNAL_VARIABLE: 'activationLink',
  ANSIBLE_PROJECT: 'Ansible Project',
  ENTRY_POINT: 'Entry Point',
  VARIABLE_NAME: 'Variable Name',
  PLACEHOLDER: 'Placeholder',
  SAVE: 'Save',
};

export const VARIABLE_TYPES = {
  EXTERNAL: 'EXTERNAL',
  INTERNAL: 'INTERNAL',
  STATIC: 'STATIC'
};

export const TITLES = {
  AUTOMATION_WORKFLOWS: AUTOMATION_WORKFLOWS,
  SETTINGS: 'Settings',
  EXTERNAL_VARIABLE_POPUP_ADD: 'Add Variable',
  EXTERNAL_VARIABLE_POPUP_EDIT: 'Edit Variable',
};

export const VARIABLE_TYPES_KEYS = {
  EXTERNAL_VARIABLE: "externalVariable",
  INTERNAL_VARIABLE: "internalVariable"
};

export const MSGS = {
  RESUMING_CONFIRMATION: "Are you sure you want to continue running this workflow?",
  ACTION_RESUMED: "Workflow will continue running",
  LINK_EXPIRED: "This link has expired or already run by another user",
};

export const ACTIVATION_KEYS = {
  RESUMED: 'resumed',
  EXPIRED: 'expired',
};
