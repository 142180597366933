import * as errorCodes from './request/requestErrorCodes';

export const ERR_CODES = errorCodes;
const FULFILLED = 'fulfilled';

export const routeOnRequestFailure = (router, pathname) => (error) => {
  if([ERR_CODES.FORBIDDEN, ERR_CODES.NOT_FOUND].includes(error.errorStatus)){
    router.replace({
      pathname,
      query: {
        code: ERR_CODES.FORBIDDEN,
      },
    });
  }
};

