/* Copyright 2019 Greyskies. All Rights Reserved. */

import request from './request';
const BASE_URL = 'user/userProfile';

export const saveProfileInfo = (info) => 
  request(`${BASE_URL}/saveProfileInfo`, {
    method: 'POST',
    data: info,
  });

export const getProfileInfo = (reqUserId) => 
  request(`${BASE_URL}/getProfileInfo`, {
    data: {reqUserId}
  });

export const resetPassword = (data) =>
  request(`${BASE_URL}/resetPassword`, {
    method: 'POST',
    responseType : 'json',
    data,
  });

export const logout = () =>
  request('logout');