/* Copyright 2019 Greyskies. All Rights Reserved. */
import { fromJS } from "immutable";

function addToChildRoutes(
  sidebarElement,
  childRoutes,
  userData,
  components,
  checkAndSetRouteComponentToAuthorized,
  getIndexRoute,
  setIndexRoute
) {
  const route = {
    path: sidebarElement.id,
    customProps: userData,
  };

  const componentInfo = components[sidebarElement.id];

  if (componentInfo) {
    const isComponentAuthorized = checkAndSetRouteComponentToAuthorized(
      route,
      componentInfo.component,
      componentInfo
    );

    if (
      isComponentAuthorized &&
      (!getIndexRoute() || sidebarElement.isLandingPage)
    ) {
      setIndexRoute(route);
    }
    if (componentInfo.subRoutes) {
      addSubRoutes(
        sidebarElement,
        childRoutes,
        components,
        userData,
        checkAndSetRouteComponentToAuthorized
      );
    }
  } else {
    route.component = UIConstructionUtils.NOT_FOUND_COMPONENET;
  }

  childRoutes.push(route);
}

function addSubRoutes(
  sidebarElement,
  childRoutes,
  components,
  userData,
  checkAndSetRouteComponentToAuthorized
) {
  const subRoutes = components[sidebarElement.id].subRoutes.map((child) => {
    const route = {
      path: `${sidebarElement.id}/${child.path}`,
      customProps: userData,
    };

    checkAndSetRouteComponentToAuthorized(
      route,
      child.component,
      child
    );

    return route;
  });

  childRoutes.push(...subRoutes);
}

function addToSidebarElements(element, sidebarElements, hasPermission) {
  let hasAccess = hasPermission(element.id);

  if (hasAccess) {
    if (element.children.length == 0) {
      sidebarElements.push(element);
    } else {
      const children = [];

      element.children.forEach((childElement) => {
        hasAccess = hasPermission(childElement.id);
        if (hasAccess) {
          children.push(childElement);
        }
      });
      element.children = children;
      if (element.children.length > 0) {
        sidebarElements.push(element);
      }
    }
  }
}

export function addRoutes(
  elements,
  sidebarElements,
  childRoutes,
  userData,
  components,
  checkAndSetRouteComponentToAuthorized,
  getIndexRoute,
  setIndexRoute,
  hasPermission
) {
  elements.forEach((sidebarElement) => {
    addToSidebarElements(
      fromJS(sidebarElement).toJS(),
      sidebarElements,
      hasPermission
    );
    if (sidebarElement.children.length === 0) {
      addToChildRoutes(
        sidebarElement,
        childRoutes,
        userData,
        components,
        checkAndSetRouteComponentToAuthorized,
        getIndexRoute,
        setIndexRoute
      );
    } else {
      sidebarElement.children.forEach((childSidebarElement) => {
        addToChildRoutes(
          childSidebarElement,
          childRoutes,
          userData,
          components,
          checkAndSetRouteComponentToAuthorized,
          getIndexRoute,
          setIndexRoute
        );
      });
    }
  });
}
