/* Copyright 2019 Greyskies. All Rights Reserved. */

import 'react-template/lib/css/main.css';
import 'web-style-guide/css/login.less';

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {FormWithFooter} from 'react-template';
import LoginAPI from 'api/LoginAPI';
import inputValidator from 'validations/AccountManagementValidationSchema';
import { withRouter } from 'react-router';
import ExternalPageContainer from './ExternalPageContainer';
import ExternalPageAlert from './ExternalPageAlert';
import * as AccountManagementUtils from 'utils/AccountManagementUtils';

@withRouter
export default class ForgetPassword extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      email: '',
      loading: false,
      success: false,
      error: false,
    };
    
    this.sendEmail = ::this.sendEmail;
    this.getFormInputs = ::this.getFormInputs;
    this.getAlert = ::this.getAlert;
  }

  sendEmail(){
    this.setState({loading: true, success:false, error: false}, () => {
      LoginAPI.resetPassword(this.state.email)
      .then(() => {
       this.setState({loading: false, success: true});
      })
      .catch(() => {
        this.setState({loading: false, error: true});
      });
    });
  }

  getFormInputs(){
    return [
      AccountManagementUtils.getEmailFormInput(false, this.state.email),
    ];
  }
  
  getAlert() {
    if(this.state.error) {
      return <ExternalPageAlert isError title='Error While Sending Mail' message='Something wrong happened in the server. Please contact the Support Team.'/>
    }
    if(this.state.success) {
      return <ExternalPageAlert title='Success' message='You will receive the password reset email (applicable only for local users)'/>
    }
  }

  render() {
    return (
      <ExternalPageContainer title='Please Enter Your Email address'>
        {this.getAlert()}
        <FormWithFooter
          inputs={this.getFormInputs()}
          validationFunction={inputValidator}
          expandFullWidth
          noFooterBorder
          onChange={(result) => this.setState({...result})}
          onSubmit={this.sendEmail}
          disableSaveButton={this.state.loading}
          onCancel={()=>this.props.router.push({pathname: '/'})}
        >
          <Row>
            <Col xs={12}>
              <div className={`alert alert-info`} >
                  <strong> Note: </strong>
                  <span>'Password Reset' feature is not available for LDAP users. It is intended for local users. If you're a local user and need to reset your password, please submit your email address and you'll receive further instructions via email.</span>
              </div>
            </Col>
          </Row>
        </FormWithFooter>
      </ExternalPageContainer>
    );
  }
}
