/* Copyright 2019 Greyskies. All Rights Reserved. */

import keyMirror from 'keymirror';

export default keyMirror({
  ADD_DOWNLOAD: null,
  REMOVE_DOWNLOAD: null,
  CLEAR_DOWNLOADS: null,
  EDIT_NAME: null,
});
