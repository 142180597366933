/* Copyright 2019 Greyskies. All Rights Reserved. */

const replacements = {
  'a': 'ddd',
  'A': 'dddd',
  'b': 'MMM',
  'B': 'MMMM',
  'c': 'l, LTS',
  'd': 'DD',
  '-d': 'D',
  'e': 'D',
  'F': 'YYYY-MM-DD',
  'H': 'HH',
  '-H': 'H',
  'I': 'hh',
  '-I': 'h',
  'j': 'DDDD',
  '-j': 'DDD',
  'k': 'H',
  'l': 'h',
  'L': 'SSS',
  'm': 'MM',
  '-m': 'M',
  'M': 'mm',
  '-M': 'm',
  'p': 'A',
  'P': 'a',
  'Q': 'x',
  's': 'X',
  'S': 'ss',
  '-S': 's',
  'u': 'E',
  'U': 'ww',
  'V': 'WW',
  'w': 'd',
  'W': 'WW',
  'x': 'l',
  'X': 'LTS',
  'y': 'YY',
  'Y': 'YYYY',
  'Z': 'ZZ',
  'f': 'SSSSSS',
  '%': '%'
};
export function strftime(format){

    // Break up format string based on strftime tokens
    var tokens = format.split(/(%\-?.)/);
    var momentFormat = tokens.map(function (token) {
      // Replace strftime tokens with moment formats
      if (token[0] === '%' && replacements.hasOwnProperty(token.substr(1))) {
        return replacements[token.substr(1)];
      }
      // Escape non-token strings to avoid accidental formatting
      return token.length > 0 ? '[' + token + ']' : token;
    }).join('');

    return this.format(momentFormat);
  };

  export function fromSpecifierToString(format){

    // Break up format string based on strftime tokens
    var tokens = format.split(/(%\-?.)/);
    var momentFormat = tokens.map(function (token) {
      // Replace strftime tokens with moment formats
      if (token[0] === '%' && replacements.hasOwnProperty(token.substr(1))) {
        return replacements[token.substr(1)];
      }
      // Escape non-token strings to avoid accidental formatting
      return token.length > 0 ? '[' + token + ']' : token;
    }).join('');

    return momentFormat;
  };



