/* Copyright 2019 Greyskies. All Rights Reserved. */

import request from './request';
import {PAIRED_TYPE, ML_JOB} from 'config/eventDefaults';
import {addNodeAdditionalParams} from 'utils/APIUtils';

export default {

  listAllSeverities: () => request('admin/event/getSeverities'),
  listAllMLJobs: () => request('admin/event/getMLJobs'),
  listAllTypes: () => request('admin/event/getTypes'),
  listAllResolutions: () => request('admin/event/getResolutions'),
  listEventArguments: (recordTypeId, isMLEvent) => request('admin/event/getEventArguments', {
    data: {recordTypeId, isMLEvent},
    method: 'GET',
  }),
  listAllRecordSources: () => request('admin/event/record/sources'),

  addEvent: (newEvent, isNewEvent) => {
    const requestMethod = isNewEvent ? 'POST' : 'PUT';
    let eventType = 'mlEvent';

    if(newEvent.type != ML_JOB){
      eventType = newEvent.type == PAIRED_TYPE ? 'pairedEvent' : 'nonPairedEvent';
    }
    const requestURL = "admin/event/" + eventType;

    return request(requestURL, {
      data: newEvent,
      method: requestMethod,
    });
  },

  initiateEventsTable: () => request('admin/event/tableData/initiate'),

  getEventList: (query) => request('admin/event/tableData/*', {
    data: query,
    method: 'GET',
  }),
  getEvent: (id) => request(`admin/event/${id}`, {
    method: 'GET',
  }),
  getAnalystActivationResponse: (key) => request(`admin/event/analystSelfHealingActionActivation`, {
    data: {key},
    method: 'GET',
  }),
  cloneEvents: (eventIds) => request('admin/event/clone', {
    data: eventIds,
    method: 'POST',
  }),
  deleteEvent: (id) => request(`admin/event/${id}`, {
    method: 'DELETE',
  }).then( (response) => ({response}) ).catch( () => ({response: false}) ),
  getNodes: (networkElementId, query, node) => {
    addNodeAdditionalParams(networkElementId, query, node);

    return request('admin/event/findAssociationTreeNodes', {
      method: 'POST',
      data: query,
    });
  },
  getEventWorkflowDataList: () => request(`admin/automationWorkflow/getAutomationWorkflowList`, {
    method: 'GET',
  }),

  getSNMPTrapVariableTypes: () => request('admin/event/getSNMPVariableTypes', {
    method: 'GET',
  }),

  activateListOfEvents: (eventIds) => request('admin/event/activate', {
    data: eventIds,
    method: 'POST',
  }),
  deactivateListOfEvents: (eventIds) => request('admin/event/deactivate', {
    data: eventIds,
    method: 'POST',
  }),
  getAllTagDefinitions: () => request('admin/event/getAllTagDefinitions'),
};
