/* Copyright 2019 Greyskies. All Rights Reserved. */

import request from './request';
const BASE_URL = 'admin/automationWorkflow/';
import axios from 'axios';

export default {

  saveAutomationWorkflow: (data) => {
    return axios.post(BASE_URL, data);
  },
  getAutomationWorkflow: (id) => request(BASE_URL, {
    method: 'GET',
    data: {id}
  }),
  checkWorkflowNameExists: (name) => request (BASE_URL + 'checkWorkflowNameExists', {
    method: 'GET',
    data: {name}
  }),
  getAutomationWorkflowList: (query) => request(BASE_URL + 'tableData', {
    method: 'POST',
    data: query
  }),
  initiateAutomationWorkflowTable: () => request(BASE_URL + 'tableData/initiate', {
    method: 'GET',
  }),
  deleteAutomationWorkflow: (id) => request(`${BASE_URL}${id}`, {
    method: 'DELETE',
  }),
  checkAutomationWorkflowActivation: (tId) => request(BASE_URL + 'checkAutomationWorkflowActivation', {
    data: {tId},
    method: 'GET',
  }),
  resumeAutomationWorkflowAction: (processId, taskId, lastStage) => request(BASE_URL + 'resumeWorkflowAction', {
    data: {
      pId: processId,
      tId: taskId,
      lastStage,
    },
    method: 'GET',
  }),
  fireAutomationWorkflow: (id, parameters) => request(BASE_URL + 'fireAutomationWorkflow', {
    method: 'POST',
    data: {
      id,
      parameters
    }
  }),
  getAutomationWorkflowParameters:  (id) => request(BASE_URL + 'getAutomationWorkflowParameters', {
    method: 'GET',
    data: {
      id,
    }
  }),
};
