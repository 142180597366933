/* Copyright 2019 Greyskies. All Rights Reserved. */

import HelpAPI from 'api/HelpAPI';

export default{
  fetchMarkdownHelpFile(){
    return HelpAPI.fetchMarkdownHelpFile().then((data) => {
      window.open(
        data,
        '_blank'
      );
    });
  },

  fetchDateFormateHelpFile(){
    return HelpAPI.fetchDateFormateHelpFile().then((data) => {
      window.open(
        data,
        '_blank'
      );
    });
  },

  fetchPrivacyPolicyHelpFile(){
    return HelpAPI.fetchPrivacyPolicyHelpFile().then((data) => {
      window.open(
        data,
        '_blank'
      );
    });
  },

  fetchTermsHelpFile(){
    return HelpAPI.fetchTermsHelpFile().then((data) => {
      window.open(
        data,
        '_blank'
      );
    });
  },

};
