/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as DateTimeUtils from './DateTimeUtils';

export const fieldTypes = {
  Numeric: 'NUMERIC',
  NonNumeric: 'NONNUMERIC',
  NetworkElement:'NETWORK_ELEMENT',
};

export const REGEX = 'regex';
export const IN = 'In';
export const NOT_IN = 'Not_In';
export const comparatorsNeedsToBeReset = [REGEX, IN, NOT_IN];
export const LIST_OPERATORS = [IN, NOT_IN];

export const BASIC_COMPARATORS = [
  {
    name: 'Equal',
    value: 'eq',
    operatorSymbol: '=',
  },
  {
    name: 'Not Equal',
    value: 'neq',
    operatorSymbol: '!=',
  },
  {
    name: 'Greater Than',
    value: 'gt',
    operatorSymbol: '>',
  },
  {
    name: 'Greater Than or Equal',
    value: 'gte',
    operatorSymbol: '>=',
  },
  {
    name: 'Less Than',
    value: 'lt',
    operatorSymbol: '<',
  },
  {
    name: 'Less Than or Equal',
    value: 'lte',
    operatorSymbol: '<=',
  },
];

export const LIST_COMPARATORS = [
  {
    name: 'In',
    value: 'In',
    operatorSymbol: 'In',
  },
  {
    name: 'Not In',
    value: NOT_IN,
    operatorSymbol: 'Not In',
  },
];

export const COMPARATORS = [
  ...BASIC_COMPARATORS,
  {
    name: 'Regular Expression',
    value: 'regex',
    operatorSymbol: 'regex',
  },
  {
    name: 'Contains',
    value: 'contains',
    operatorSymbol: 'contains',
  },
  {
    name: 'Does Not Contain',
    value: 'notContains',
    operatorSymbol: 'does not contain',
  },
];

export const COMPARATORS_LIST = [
  ...COMPARATORS,
  ...LIST_COMPARATORS,
];

export const TypesComparator = {
  gt: new Set([fieldTypes.Numeric]),
  gte: new Set([fieldTypes.Numeric]),
  lt: new Set([fieldTypes.Numeric]),
  lte: new Set([fieldTypes.Numeric]),
  eq: new Set([fieldTypes.Numeric, fieldTypes.NonNumeric, fieldTypes.NetworkElement]),
  neq: new Set([fieldTypes.Numeric, fieldTypes.NonNumeric, fieldTypes.NetworkElement]),
  regex: new Set([fieldTypes.Numeric, fieldTypes.NonNumeric, fieldTypes.NetworkElement]),
  [IN]: new Set([fieldTypes.Numeric, fieldTypes.NonNumeric]),
  [NOT_IN]: new Set([fieldTypes.Numeric, fieldTypes.NonNumeric]),
  contains: new Set([fieldTypes.NonNumeric]),
  notContains: new Set([fieldTypes.NonNumeric]),
};

export function checkInvalidValue(value){
  if(value === null || value === undefined 
    || value === '' || typeof(value) == 'object'){
    return true;
  }

  return false;
}

function gt(comparingValue, comparedTo) {
  return comparingValue > comparedTo;
}

function gte(comparingValue, comparedTo) {
  return comparingValue >= comparedTo;
}

function lt(comparingValue, comparedTo) {
  return comparingValue < comparedTo;
}

function lte(comparingValue, comparedTo) {
  return comparingValue <= comparedTo;
}

function eq(comparingValue, comparedTo) {
  return comparingValue == comparedTo;
}

function neq(comparingValue, comparedTo) {
  return comparingValue != comparedTo;
}

function regex(comparingValue, regexString){
  let result = false;

  try{
    result = new RegExp((regexString || '')).test(comparingValue);
  }catch(e){
    console.error(e);
    result = false;
  }

  return result;
}

function contains(comparingValue, substr){
  let result = false;

  try{
    result = comparingValue.toLowerCase().includes(substr.toString().toLowerCase());
  }catch(e){
    console.error(e);
    result = false;
  }

  return result;
}

function notContains(comparingValue, substr){
  return !contains(comparingValue, substr);
}

const comparingFunctions = {
  gt,
  gte,
  lt,
  lte,
  eq,
  neq,
  regex,
  contains,
  notContains,
};

export function compare(comparator, comparingValue, comparedValue){
  if(checkInvalidValue(comparator) || checkInvalidValue(comparingValue) 
    || (comparator !== 'regex' && checkInvalidValue(comparedValue))){
    return false;
  }

  return comparingFunctions[comparator](comparingValue, comparedValue);
}

export function compareDate(comparator, dateFormat, dateValue, comparedValue){
  const comparingValue = comparator === 'regex' ?
   DateTimeUtils.epochToCustomDateFormat(dateFormat, dateValue) : dateValue;


  return compare(comparator, comparingValue, comparedValue);
}

