/* Copyright 2019 Greyskies. All Rights Reserved. */

export const LABELS = {
  ROLL_UP_PLANS: 'Time Series Data Plans',
  ROLL_UP_PLAN: 'Time Series Data Plan',
  RETENTION_PLANS:'Big Data Plans',
  RETENTION_PLAN:'Big Data Plan',
  DATA_PLAN:'Data Plan',
  NAME: 'Name',
  PLAN_KEEPS: 'Plan keeps',
  RAW_DATA: 'Raw Data',
  ROLL_UP_RESOLUTION: 'Roll Up Resolution',
  RESOLUTION: 'Resolution',
  INDEX_INTERVAL: 'Index Interval',
  DATE_RANGE: 'Date Range',
  INTERVAL: 'Interval',
  RECORDS: 'Records',
  RECORD: 'Record',
  TYPE: 'Type',
  RECORD_TYPE: 'Record Type',    
  FIELDS_AGGREGATION: 'Fields Aggregation',
  AGGREGATION: 'Aggregation',
  FIELDS: 'Fields',  
  FIELD: 'Field',
  WEIGHTED_AVG_FIELD: 'Weighted Average Field',
  FOR: 'For',
  PLAN: 'Plan',
  MAX_RAW_RESOLUTION: 'Records max raw resolution',
};

export const DEFAULT_VALUES = {
  DATE_RANGE_INTERVAL:1,
  DATE_RANGE_UNIT:'DAYS',
  RESOLUTION_INTERVAL:15,
  RESOLUTION_UNIT:'MINUTES', 
  INDEX_INTERVAL: 'ONE_MONTH',
};

export const RECORD_TYPE_SOURCES = {
  SERVICES: "SERVICES",
  ATTRIBUTE: "ATTRIBUTE",
};

export const TYPES = {
  ELASTIC_SEARCH: 'ELASTICSEARCH',
  LAKEHOUSE: 'LAKEHOUSE',
};

export const TOOLTIPS = {
  ORDER_UP: 'Order Up',
  ORDER_DOWN:'Order Down',
  DELETE_RESOLUTION:'Delete Resolution',
};

export const ERR_MSGS = {
  sort: 'Roll up resolutions are not sorted correctly',
  unique : 'Roll up resolutions contain duplicate resolutions',
  isGreaterThanRawRes: 'Roll up resolution must be greater than raw resolution',
  isGreaterOrEqualRollupRecordIndexInterval: 'Index Interval must be greater than or equal record Index Interval',
  isGreaterThanOrEqualRollupIndexInterval: 'Index Interval must be greater or equal previous Resolution Index Interval',
  noKeyFieldForRollupPlan: 'Roll up resolutions are not applicable for records that have non key fields',
  noRawResolutionForRollupPlan: 'Roll up resolutions are not applicable for records that have no raw resolution',
  noKeyFieldForFieldsAggs: 'No need for fields aggregation as this record has no key field',
  noRawResolutionForFieldsAggs: 'No need for fields aggregation as this record has no raw resolution',
  dateRangeIsGreaterThanMaxRawRes: 'Raw Data Date Range must be greater than Records max raw resolution',
};

export const getRetentionMsg = (dateRange) =>
  `Data after ${dateRange} will be deleted.`;

