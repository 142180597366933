/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import ReactDOM from 'react-dom';

import Routes from 'components/Routes';

function app(){
	ReactDOM.render(
		<Routes />,
		document.getElementById('container')
	);
}

app();
