/* Copyright 2019 Greyskies. All Rights Reserved. */

export const MIN_FONT_SIZE = 8;
export const INVALID_FONT_SIZE_MESSAGE = 'Invalid Font Size';
export const INSERT_FONT_MESSAGE = 'Please Insert Font Size';
export const DRILL_DOWN_TITLE = 'Drilldown';
export const ASSOCIATION_TITLE = 'Association';
export const FORMAT_TITLE = 'Format';
export const SETTINGS_TITLE = 'Settings';
export const THRESHOLDS_TITLE = 'Thresholds';
export const LINK_DETAILS = 'Link Details';
export const NODE_DETAILS = 'Node Details';
export const LINK_ADVANCED_SETTINGS_TITLE = 'Link Advanced Settings';
export const PREVIEW_TITLE = 'Widget Preview';
export const LAYERS_TITLE = 'Layers';
export const OVERLAY_TITLE = 'Overlay';
export const SIGNAL_TITLE = 'Signal';
export const TAG_TITLE = 'Tag';