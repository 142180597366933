/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as UserProfileAPI from 'api/UserProfileAPI';
import AppDispatcher from 'dispatcher/AppDispatcher';
import UserManagementConstants from 'constants/UserManagementConstants';

export const getUserProfileInfo = (userId) => {
  return UserProfileAPI.getProfileInfo(userId);
};

export const saveProfileInfo = (data) => {
  return UserProfileAPI.saveProfileInfo(data).then(value => {
    AppDispatcher.dispatch({
      actionType: UserManagementConstants.ADD_USER,
      data: value,
    });   
    return value;
  });
};

export const resetPassword = (data) => {
  return UserProfileAPI.resetPassword(data).then(value => {
    AppDispatcher.dispatch({
      actionType: UserManagementConstants.ADD_USER,
      data: value,
    });   
    return value;
  });
};

export const logout = () => {
  return UserProfileAPI.logout();
};
