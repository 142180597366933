/* Copyright 2019 Greyskies. All Rights Reserved. */
import {TEMPLATE_PREFIX} from 'config/defaults';

/** @deprecated */

export const getErrorDeleteMsg = (deletedType, deletedName) =>
   `Error while deleting ${deletedType} ${deletedName}`;
export const getDeletePromptMsg = (deletedType, deletedName) =>
  `Are you sure you want to delete the ${deletedType} ${deletedName}?`;
export const getSuccessDeleteMsg = (deletedType, deletedName) =>
  `${deletedType} ${deletedName} is deleted successfully`;
export const getDeleteTitle = (deletedType) =>
  `Delete ${deletedType}`;
export const getAddNewTitle = (newType) =>
`New ${newType}`;
export const getNewTitle = (newType) =>
`New ${newType}`;
export const getEditTitle = (editType) =>
  `Edit ${editType}`;
export const getNotFoundError = (type) =>
  `${type} not found`;
export const getSuccessRefreshJobMsg = (refreshType, refreshName) =>
  `Refresh Job for ${refreshType} ${refreshName} is triggered successfully`;
export const getErroruRefreshJobMsg = (refreshType, refreshName) =>
  `Error while triggering refresh job of ${refreshType} ${refreshName}`;
export const getRefreshJobTitle = (refreshType) =>
  `Refresh ${refreshType}`;
export const getSuccessSaveMsg = (savedType, isCreate) =>
  `${savedType} is ${isCreate ? 'added' : 'edited'} successfully`;
export const getErrorSaveMsg = (savedType, isCreate) =>
  `Error while ${isCreate ? 'adding' : 'editing'} ${savedType}`;
export const getKafkaErrorSaveMsg = (savedType, isCreate) =>
  `${savedType} is ${isCreate ? 'added' : 'edited'} successfully but failed to publish. Please save it again later.`
export const getSaveTitle = (savedType, isCreate) => {
  if(isCreate){
    return getAddNewTitle(savedType);
  }

  return getEditTitle(savedType);
};

export const getPanelTitle = (savedType, isCreate) => {
  if(isCreate){
    return getNewTitle(savedType);
  }

  return getEditTitle(savedType);
};

export const getCreateButtonText = (newType) =>
  `Create ${newType}`;
export const getUpdateButtonText = (editType) =>
  `Update ${editType}`;
export const getAppendButtonText = (newType) =>
  `Append ${newType}`;
export const getSaveButtonText = (savedType, isCreate) => {
  if(isCreate){
    return getCreateButtonText(savedType);
  }

  return getUpdateButtonText(savedType);
};

export const getSaveButton = () => {
  return 'Save';
};

export const getDeleteWarningMsg = (toBeDeletedItem) =>
  `Deleting this folder will move all its ${toBeDeletedItem} to the default folder`;

export const getSelectMsg = (selectType) =>
  `Please select ${selectType}`;

export const getInsertMsg = (InsertType) =>
  `Please Insert ${InsertType}`;

export const getCloneTitle = (cloneType) =>
  `Clone ${cloneType}`;

export const getCopyTitle = (type) => 
  `Copy ${type}`;

export const getDeleteSpecificType = (type) =>
`Are you sure you want to delete this ${type}?`;

export const ANALYST_ACTIVATION_ERROR_TITLE = 'Something went wrong';

export const getRefreshTitle = (refreshType) =>
  `Refresh ${refreshType}`;

export const getSuccessRefreshMsg = (refreshType, refreshName) =>
  `${refreshName} ${refreshType} has been refreshed successfully`;

export const getErrorRefreshMsg = (refreshType, refreshName) => 
  `Error while refreshing ${refreshName} ${refreshType}`;

export const getDeletingInProgressMsg  = (deletedType, deletedName) =>
  `Deleting ${deletedType} ${deletedName} in progress, please refresh within a few minutes`;

export const getConfirmMessage = (message) =>
`Are you sure you want to ${message}?`;

export const getTemplateTitle = (title, isTemplate, isCreate) => { 
  title = isTemplate ? TEMPLATE_PREFIX + title : title;

  return isCreate ? getAddNewTitle(title) : getEditTitle(title);
};