/* Copyright 2019 Greyskies. All Rights Reserved. */

/* global define */
/* eslint-env node, mocha */
import moment from 'moment';

const relativeTimeRe = /^([-+])(\d*)\s*(minutes|minute|min|Min|Minute|Minutes|hr|Hr|hours|hour|Hour|Hours|days|day|Days|Day|weeks|week|Week|Weeks|months|month|mon|Month|Months|years|year|Year|Years|Quarters|Quarter|seconds|second|Seconds|Second|sec|s|m|h|d|D|M|y|Y|q|Q|Qr|qr|ms|w|wk|Wk)(([\/\*])*\s*(minutes|minute|min|Min|Minute|Minutes|hr|Hr|hours|hour|Hour|Hours|days|day|Days|Day|weeks|week|Week|Weeks|months|month|mon|Month|Months|years|year|Year|Years|Quarters|Quarter|seconds|second|Seconds|Second|sec|s|m|h|d|D|M|y|Y|q|Q|Qr|qr|ms|w|wk|Wk)*)/;  
const convertCase = ({
  ms: 'milliseconds',
  s: 'seconds',
  sec: 'seconds',
  second: 'seconds',
  seconds: 'seconds',
  Second: 'seconds',
  Seconds: 'seconds',
  m: 'minutes',
  min: 'minutes',
  minute: 'minutes',
  minutes: 'minutes',
  Min: 'minutes',
  Minute: 'minutes',
  Minutes: 'minutes',
  h: 'hours',
  hr: 'hours',
  Hr: 'hours',
  hour: 'hours',
  hours: 'hours',
  Hour: 'hours',
  Hours: 'hours',
  d: 'days',
  D: 'days',
  day: 'days',
  days: 'days',
  Day: 'days',
  Days: 'days',
  w: 'weeks',
  W: 'weeks',
  wk: 'weeks',
  Wk: 'weeks',
  week: 'weeks',
  weeks: 'weeks',
  Week: 'weeks',
  Weeks: 'weeks',
  M: 'months',
  mon: 'months',
  month: 'months',
  months: 'months',
  Month: 'months',
  Months: 'months',
  y: 'years',
  Y: 'years',
  yr: 'years',
  yrs: 'years',
  year: 'years',
  years: 'years',
  Year: 'years',
  Years: 'years',
  q: 'quarters',
  qr: 'quarters',
  Q: 'quarters',
  Qr: 'quarters',
  qtr: 'quarters',
  quarter: 'quarters',
  quarters: 'quarters',
  Quarter: 'quarters',
  Quarters: 'quarters',
});

export function getUnitAndIntervalFromString(relativeTimeString){
  const result = relativeTimeRe.exec(relativeTimeString);
  const direction = relativeTimeString.charAt(0);
  const interval = result[2];
  const unit = convertCase[result[3]];
  const startOrEnd = result[5];
  const startOrEndUnit = convertCase[result[6]];

  return {direction, interval, unit, startOrEnd, startOrEndUnit}
}  

export function isRelativeTimeFormat(relativeTimeString) {
  relativeTimeString = relativeTimeString.trim();
  if (relativeTimeString === 'now') {
    return true;
  }

  return relativeTimeRe.test(relativeTimeString);
} 

export function relativeTime(relativeTimeString, startReferenceDate) {
  relativeTimeString = relativeTimeString.trim();
  let result = startReferenceDate ? moment(startReferenceDate * 1000) : moment();

  if (relativeTimeRe.test(relativeTimeString)) {
    const {unit, interval, startOrEnd, startOrEndUnit, direction} 
      = getUnitAndIntervalFromString(relativeTimeString);

    if (direction === '-') {
      result = result.subtract(+interval, unit);
    }else{
      result = result.add(+interval, unit);
    }

    if(startOrEnd == '/' && startOrEndUnit){
      result = result.startOf(startOrEndUnit);
    }else if(startOrEnd == '*' && startOrEndUnit){
      result = result.endOf(startOrEndUnit);
    }
  }

  return result;
}

export function getPastTimeRangeString(relativeTimeString, fullDateRange) {
  const {unit, interval} = getUnitAndIntervalFromString(relativeTimeString);

  if(fullDateRange){
    return `Last ${interval} ${unit}`;
  }

  return `${interval} ${unit} Ago`;
}

export function getFutureTimeRangeString(relativeTimeString, fullDateRange) {
  const {unit, interval} = getUnitAndIntervalFromString(relativeTimeString);

  if(fullDateRange){
    return `Next ${interval} ${unit}`;
  }

  return `After ${interval} ${unit}`;
}

export function toRelativeTimeString(relativeTimeString) {
  if (relativeTimeString === 'now') {
    return 'now';
  }
  const {direction} = getUnitAndIntervalFromString(relativeTimeString);

  if (direction === '-') {
    return  getPastTimeRangeString(relativeTimeString);
  }

  return getFutureTimeRangeString(relativeTimeString);
}