/* Copyright 2019 Greyskies. All Rights Reserved. */

export const LIGHT_THRESHOLD_COLORS = {
    NORMAL: "#B5DFFD",
};

export const ORIGINAL_THRESHOLD_COLORS = {
    LOW : "#F8DE2C",
    MEDIUM: "#EC8923", 
    HIGH: "#E3231B",
    NORMAL: "#47AFFA",
}; 

export const SLIDER_RAIL_COLOR = '#E3231B';
export const TRANSPARENT = 'transparent';
export const ANOMALY_POINT_STROKE_COLOR = '#707070';

