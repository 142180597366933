/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as ArrayUtils from './ArrayUtils';

const emailKey = "email";
export function formStringEmailsToObjects(mailStringArray){
    var mailObjArray = [];
    mailStringArray.map((emailString) => {
        mailObjArray.push({
            id: null,
            version: null,
            email: emailString,
        });
    });
    return mailObjArray;
};
export function getEmailParamsForLoad(obj){
    obj.toMail = ArrayUtils.convertObjectToString(obj.toMail, emailKey);
    obj.cc = ArrayUtils.convertObjectToString(obj.cc, emailKey);
    obj.bcc = ArrayUtils.convertObjectToString(obj.bcc, emailKey);

    return obj;
};
export function getEmailParamsForSave (obj){
    obj.toMail = formStringEmailsToObjects(obj.toMail);
    obj.cc = formStringEmailsToObjects(obj.cc);
    obj.bcc = formStringEmailsToObjects(obj.bcc);

    return obj;
};

export function updateOriginalEmailObj (newObj, originalObj) {
    for (let newObjKey in newObj) {
      for (let originalObjKey in originalObj) {
        if(originalObj[originalObjKey].email === newObj[newObjKey].email){
          newObj[newObjKey].id = originalObj[originalObjKey].id;
          newObj[newObjKey].version = originalObj[originalObjKey].version;
          break;
        }      
      }
    }
  
    return newObj;
  }; 
export function updateActionEmails(obj) {
    obj.toMail = updateOriginalEmailObj(obj.toMail);
    obj.cc = updateOriginalEmailObj(obj.cc);
    obj.bcc = updateOriginalEmailObj(obj.bcc);

    return obj;
};