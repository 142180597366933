/* Copyright 2019 Greyskies. All Rights Reserved. */

import Joi from '@hapi/joi';
import {ValidationUtils} from 'js-utils';
import { EmailDefaults, CommonValidationSchema } from 'react-template';

const commonPasswordValidation = Joi.string()
  .trim()
  .min(8)
  .max(32)
  .regex(/^[^\s]{8,32}$/)
  .message('Password can not include spaces')
  .regex(/([\d])/)
  .message('Password should contain numbers')
  .regex(/(\W)/)
  .message('Password should contain special characters')
  .regex(/([a-z])/)
  .message('Password should contain lowercase characters')
  .regex(/([A-Z])/)
  .message('Password should contain uppercase characters')
  .error(ValidationUtils.getErrMsgString);

const SCHEMA = {
  password:  commonPasswordValidation.label('Password').required(),
  confirmPassword: commonPasswordValidation.label('Confirm password').required(),
  changePassword:  commonPasswordValidation.label('Password').optional().allow(''),
  confirmChangePassword: commonPasswordValidation.label('Confirm password').optional().allow(''),
  currentPassword: Joi.string()
    .trim()
    .label('Current password')
    .error(ValidationUtils.getErrMsgString)
    .required(),
  email: Joi.string()
    .trim()
    .regex(/^([a-zA-Z0-9_.-])+@([a-zA-Z0-9_.-])+\.([a-zA-Z]{2,})$/)
    .message('Email is invalid')
    .min(0)
    .max(256)
    .required()
    .label('Email')
    .error(ValidationUtils.getErrMsgString),
  username: Joi.string()
    .trim()
    .min(3)
    .max(45)
    .required()
    .label("Username")
    .error(ValidationUtils.getErrMsgString)
    .regex(/^[^\s#%.=;?/]{3,45}$/)
    .message("Username can only include letters, numbers and special characters except # % . = / ; ? and spaces"),
  firstName: Joi.string()
    .trim()
    .max(256)
    .optional()
    .allow('')
    .label("First Name")
    .error(ValidationUtils.getErrMsgString),
  lastName: Joi.string()
    .trim()
    .max(256)
    .optional()
    .allow('')
    .label("Last Name")
    .error(ValidationUtils.getErrMsgString),
  mobile: Joi.string()
    .trim()
    .max(20)
    .optional()
    .allow('')
    .label("Mobile number")
    .error(ValidationUtils.getErrMsgString)
    .regex(/^[0-9]{0,20}$/)
    .message('Mobile number can only include digits'),
  emails: Joi.array()
    .label(EmailDefaults.LABELS.EMAILS)
    .error(ValidationUtils.getErrMsgArray(EmailDefaults.LABELS.EMAILS))
    .min(1)
    .max(EmailDefaults.MAX_EMAILS_COUNT)
    .unique((a, b) => a.toLowerCase() == b.toLowerCase()),
  emailTag: CommonValidationSchema.mail,
};

export default ValidationUtils.validateSchema(SCHEMA);