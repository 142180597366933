/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component } from 'react';
import {Row, Col, Button, ButtonGroup} from 'react-bootstrap';
import { withRouter } from 'react-router';
import AutomationWorkflowActions from 'actions/AutomationWorkflowActions';
import { MAIN_ROUTES } from 'config/routesDefaults';
import {Icons, UIConstructionUtils, ButtonsConstructor, Loader} from 'react-template';
import { NotificationManager } from 'react-notifications';
import * as MsgDefaults from 'config/MessagingAndTitlesDefaults';
import * as automationWorkflowDefaults from 'config/automationWorkflowDefaults';

const AutomationWorkflowActivationOptions = {};

AutomationWorkflowActivationOptions[automationWorkflowDefaults.ACTIVATION_KEYS.EXPIRED] = {
  icon: Icons.hourGlassEnd + Icons.styles.ev6x,
  description: 'Expired',
  className: 'evc-text-danger margBtm10',
  message: automationWorkflowDefaults.MSGS.LINK_EXPIRED,
};    
AutomationWorkflowActivationOptions[automationWorkflowDefaults.ACTIVATION_KEYS.RESUMED] = {
  icon: Icons.success,
  description: 'Succeed',
  className: 'evc-text-danger margBtm10',
  message: automationWorkflowDefaults.MSGS.ACTION_RESUMED,
};

@withRouter
export default class AutomationWorkflowActivation extends Component {
  constructor(props) {
    super(props);
    const processId = this.props.location.query.pId;
    const taskId = this.props.location.query.tId;
    const lastStage = this.props.location.query.lastStage

    this.state = {
      processId,
      taskId,
      lastStage,
      sending: false,
    };
    this.redirectToDashboard = ::this.redirectToDashboard;
    this.resumeWorkflowAction = ::this.resumeWorkflowAction;
    this.renderAutomationWorkflowActivationState = ::this.renderAutomationWorkflowActivationState;
  }

  componentWillMount() {
    this.setState({sending: true}, () => {
      AutomationWorkflowActions.checkAutomationWorkflowActivation(this.state.taskId).then(response => {
        const newState = {sending: false};

        if (!response) {
          newState.automationWorkflowActivationState = automationWorkflowDefaults.ACTIVATION_KEYS.EXPIRED;
        }
        this.setState(newState);
      }).catch((error) => {
        this.setState({sending: false});
        NotificationManager.warning(error, MsgDefaults.ANALYST_ACTIVATION_ERROR_TITLE, 10000);
      });
    });
  }
  redirectToDashboard() {
    this.props.router.push({
      pathname: MAIN_ROUTES.DASHBOARD,
    });
  }

  resumeWorkflowAction(){
    this.setState({sending: true}, () => {
      AutomationWorkflowActions.resumeAutomationWorkflowAction(this.state.processId, this.state.taskId, this.state.lastStage).then(response => {
        const newState = {sending: false};

        if (response) {
          newState.automationWorkflowActivationState = automationWorkflowDefaults.ACTIVATION_KEYS.RESUMED;
        }
        this.setState(newState);
      }).catch((error) => {
        this.setState({sending: false});
        NotificationManager.warning(error, MsgDefaults.ANALYST_ACTIVATION_ERROR_TITLE, 10000);
      });
    });   
  }

  renderAutomationWorkflowActivationState(){
    const automationWorkflowActivationStateObject = 
        AutomationWorkflowActivationOptions[this.state.automationWorkflowActivationState];

    return (
      <div className="textCenter virticalAlign workFlowActivation-page">
        <h1>
          {UIConstructionUtils.getIcon(automationWorkflowActivationStateObject.icon)}
        </h1>
        <h2 className={automationWorkflowActivationStateObject.className}>
          {automationWorkflowActivationStateObject.description}
        </h2>
        <h4 className="normal-text">
          {automationWorkflowActivationStateObject.message}
        </h4>
        <Button
          bsStyle="primary"
          onClick={this.redirectToDashboard}>
            {UIConstructionUtils.getIcon(Icons.arrowLeft)} Home
        </Button>
      </div>
    );
  }

  renderResumingConfirmation(){
    return (
      <div className="textCenter virticalAlign workFlowActivation-page">
        <h1>
          {UIConstructionUtils.getIcon(Icons.help + " " + Icons.styles.ev6x)}
        </h1>
        <h4 className="normal-text"> 
          {automationWorkflowDefaults.MSGS.RESUMING_CONFIRMATION} 
        </h4>
        <Row>
          <Col className="btn-row block-center textCenter" xs={12} sm={12}>
            <ButtonGroup className={'row'}>
              <Col xs={12} sm={6}>
                {ButtonsConstructor.getButtonComponent({
                  className: 'cancelForm-btn',
                  onClick: this.redirectToDashboard,
                  bsStyle: 'cancel'}, 'No')}
              </Col>
              <Col xs={12} sm={6}>
                {ButtonsConstructor.getButtonComponent({
                  className: 'submitForm-btn',
                  onClick: this.resumeWorkflowAction,
                  bsStyle: 'primary'}, 'Yes')}
              </Col>
            </ButtonGroup>
          </Col>
        </Row>    
      </div>
    );
  }

  render() {
    if(this.state.sending){
      return(<Loader/>); 
    }
    if (this.state.automationWorkflowActivationState) {
      return this.renderAutomationWorkflowActivationState();
    } else {
      return this.renderResumingConfirmation();
    }
  }
}
