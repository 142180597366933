/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import GreyskiesLogo from 'web-style-guide/img/footerGSlogo.png';
import { Col, Row } from 'react-bootstrap';
import HelpActions from 'actions/HelpActions';
import moment from 'moment-timezone';

const FooterBuildProps = (props) => {
  if(props.footerProps){
    const buildProps = props.footerProps.buildProps;
    const currentYear = moment().year();

    return(
      <Row className="footer-wrapper">
        <Col xs={4} className='footer-left footer-col text-left'>
          {props.footerProps.showOrganizationLogo ?
            <p className="footer-info-item">
              <a onClick={HelpActions.fetchPrivacyPolicyHelpFile} className='footer-link pointer-cursor'>
                Privacy Policy
              </a>
              <a onClick={HelpActions.fetchTermsHelpFile} className='footer-link pointer-cursor'>
                Terms
              </a>
            </p>
          : null }
        </Col>
        <Col xs={4} className='footer-center footer-col text-center'>
          {props.footerProps.showOrganizationLogo ?
            <p className="footer-info-item">
              <span className='footer-cpyrits-text'>
                Copyright &#169; {currentYear}
              </span>
              <img src={GreyskiesLogo} className='footer-logo'/>
                Greyskies Inc
            </p>
          : null }
        </Col>
        <Col xs={4} className='footer-right footer-col text-right footer-build-info'>
          {buildProps.version ?
            <p className="footer-info-item">
              <span >Version: </span>
              <span className="semiBold-text">
                {buildProps.version}
              </span>
            </p>
          : null }
          {buildProps.branch ?
            <p className="footer-info-item">
              <span>Branch: </span>
              <span className="semiBold-text">
                {buildProps.branch}
              </span>
            </p>
          : null }
          {buildProps.build ?
            <p className="footer-info-item">
              <span>Build: </span>
              <span className="semiBold-text">
                {buildProps.build}
              </span>
            </p>
          : null }
        </Col>
      </Row>
    );
  }

  return null;
};

export default FooterBuildProps;
