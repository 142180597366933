/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import { TooltippedButton, Icons, CommonValidationSchema } from 'react-template';
import LoginAPI from 'api/LoginAPI';
import { ValidationUtils } from 'js-utils';
import userDefaults from 'config/userDefaults';

const SHOW_PASSWORD = 'showPassword';
const SHOW_CONFIRM_PASSWORD = 'showConfirmPassword';
const SHOW_CURRENT_PASSWORD = 'showCurrentPassword';

export function validateConfirmPassword(value, password) {
  if (password === value) {
    return null;
  }
  return ValidationUtils.errorToValidationObject(ValidationUtils.ERR_MSGS.notMatching('Passwords'));
}

export const checkIfUsernameExists = (originalName, inputValidator) => (username) => {
  return CommonValidationSchema.checkIfNameExists(
    username, 
    'username', 
    true, 
    originalName,
    LoginAPI.checkUserExists,
    inputValidator,
    'Username'
  );
}

function getShowHidePasswordBtn(state, stateKey, onClick) {
  return (
    <TooltippedButton
      onClick={() => onClick({ [stateKey]: !state[stateKey] })}
      tooltip={`${state[stateKey] ? 'Hide' : 'Show'} Password`} className='btn-primary togglePasswordBtn'
    >
      <i className={state[stateKey] ? Icons.hide : Icons.view}></i>
    </TooltippedButton>
  );
}

export function getInfoPasswordBtn() {
  return (
    <TooltippedButton  className="btn-action infoBtn" tooltip={userDefaults.PASSWORD_VALIDATION} >
      <i className={Icons.info}/>
    </TooltippedButton>
  );
}

export function getPasswordsFormInputs(state, hasCurrentPassword, onClick, requiredAstric) {
  return [
    ...(hasCurrentPassword ? [{
      type: 'text',
      keyword: 'currentPassword',
      validationLabel: 'Current Password',
      placeholder: getLabel("Current Password", requiredAstric),
      validation: true,
      trimmed: true,
      password: !state[SHOW_CURRENT_PASSWORD],
      initialValue: state.currentPassword,
      variableSizes: {
        inputSize: 12,
      },
      buttons: getShowHidePasswordBtn(state, SHOW_CURRENT_PASSWORD, onClick),
    }] : []),
    {
      type: 'text',
      keyword: 'password',
      validationLabel: 'Password',
      placeholder: getLabel("Password", requiredAstric),
      validation: true,
      trimmed: true,
      password: !state[SHOW_PASSWORD],
      initialValue: state.password,
      validateBefore: ['confirmPassword'],
      variableSizes: {
        inputSize: 12,
      },
      class: 'passwordWithInfo',
      buttons:  <div className='passwordBtnsWrapper'>
                  {getInfoPasswordBtn()}
                  {getShowHidePasswordBtn(state, SHOW_PASSWORD, onClick)}
                </div>,
    },
    {
      type: 'text',
      keyword: 'confirmPassword',
      validationLabel: 'Confirm Password',
      placeholder: getLabel("Confirm Password", requiredAstric),
      validation: true,
      trimmed: true,
      password: !state[SHOW_CONFIRM_PASSWORD],
      initialValue: state.confirmPassword,
      variableSizes: {
        inputSize: 12,
      },
      buttons: getShowHidePasswordBtn(state, SHOW_CONFIRM_PASSWORD, onClick),

    }];
}

export function getEmailFormInput(disabled, initialValue, showLabel) {
  return getTextInput("email", "Email", disabled, initialValue, showLabel, true);
}

export function getUsernameFormInput(disabled, initialValue, showLabel, requiredAstric, onBlurValidationPromise) {
  return {
    ...getTextInput("username", getLabel("Username", requiredAstric), disabled, initialValue, showLabel, true),
    onBlurValidationPromise,
  };
}

export function getFirstNameFormInput(disabled, initialValue, showLabel) {
  return getTextInput("firstName", "First Name", disabled, initialValue, showLabel);
}

export function getLastNameFormInput(disabled, initialValue, showLabel) {
  return getTextInput("lastName", "Last Name", disabled, initialValue, showLabel);
}

export function getMobileFormInput(disabled, initialValue, showLabel) {
  return getTextInput("mobile", "Mobile", disabled, initialValue, showLabel);
}

function getTextInput(keyword, label, disabled, initialValue, showLabel, trimmed) {
  const input = {
    type: 'text',
    keyword,
    initialValue,
    disabled,
    validation: !disabled,
    trimmed,
  };

  if(showLabel) {
    return {
      ...input,
      label,
    };
  } else {
    return {
      ...input,
      placeholder: label,
      validationLabel: label,
      variableSizes: {
        inputSize: 12,
      },
    };
  }
}

function getLabel(label, required) {
  return `${label}${required ? '*' : ''}`
}