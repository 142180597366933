import React from 'react';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import defaults from 'config/defaults';
import { Icons } from 'react-template';
import { MAIN_ROUTES } from 'config/routesDefaults';


function ChangeContextButton(props) {
  let btnClassName;
  let btnToolTip;
  let divClassName;

  if(props.context == MAIN_ROUTES.ADMIN){
    btnClassName = Icons.reporterSettings + Icons.styles.evLg;
    btnToolTip = defaults.REPORTER_TOOLS_TOOL_TIP;
    divClassName = MAIN_ROUTES.REPORTER;
  }else {
    btnClassName = Icons.adminSettings + Icons.styles.evLg;
    btnToolTip = defaults.ADMIN_SETTINGS_TOOL_TIP;
    divClassName = MAIN_ROUTES.ADMIN;
  }

  return (
    props.inNavbar ? 
      <li>
        <div className='navbar-action-icon switchContext-btn' onClick={props.changeContext}>
          <i className={btnClassName}></i>
          <span className="navbar-item-title">
            {btnToolTip}
          </span>
        </div> 
      </li>
    : <li className="navbar-notification navbar-action">
        <OverlayTrigger
          rootClose placement="bottom" trigger={['hover', 'focus']}
          overlay={
            <Tooltip placement="bottom" className="in" id="tooltip-bottom">
              {btnToolTip}
            </Tooltip>
          }
        >
          <div className={`navbar-action-icon switchContext ${divClassName}`}
            onClick={props.changeContext}>
            <i className={btnClassName}></i>
          </div>
        </OverlayTrigger>
      </li>
  );
}

ChangeContextButton.propTypes = {
};

export default ChangeContextButton;

