/* Copyright 2019 Greyskies. All Rights Reserved. */

/**
 * Generic class for parse and write different file types
 * each file type should have a separate class 
 *  containing implemntations for the following methods:
 *    i. parseFile
 *    ii. writeFile
 */

import * as Utils from '../Utils';
import * as FileDownloadUtils from '../FileDownloadUtils';
import * as CsvTransformer from './CsvTransformer';
import * as TextTransformer from './TextTransformer';

export const BOOLEAN = 'boolean';
export const PARSING_LINE_TYPES = {
  OBJECT: 'object',
  SINGLE_VALUE: 'single_value',
};

export const FILE_TYPES = {
  CSV: 'csv',
  TEXT: 'txt',
};

export const parseValueByType = (value, type) => {
  const trimmedValue = value.trim();

  switch (type) {
    case BOOLEAN:
      return Utils.stringToBoolean(trimmedValue.toLowerCase());  
    default:
      return trimmedValue;
  }
};

const getTransformerByType = (type) => {
  let transformer = null;

  switch (type) {
    case FILE_TYPES.CSV:
      transformer = CsvTransformer;
      break;
    case FILE_TYPES.TEXT:
      transformer = TextTransformer;
      break;
    default:
      break;
  }

  return transformer;
};


/*
 * Read the given file and parse it using the provided transformParams.
 *
 * @param {blob} file - the file to parse.
 * @param {string} type - the type of the input file.
 * @param {object} transformParams - The trasformation descriptor for parsing
 *  the loaded file.
 * @return {Promise} - A promise that resolves to an object containing
 *  the file parsed data.
 */
export const parseFile = (file, type, transformParams) => {
  const reader = new FileReader();
  const transformer = getTransformerByType(type);

  return new Promise(resolve => {    
    reader.onload = (e) => {
      const fileText = e.target.result;
      const parsedFile = transformer.parseFile(fileText, transformParams);

      resolve(parsedFile);
    };
    
    reader.readAsText(file);
  });
};

/*
 * Write the given data to a new file and download it.
 *
 * @param {String|array|object} data - Data to write.
 * @param {string} type - the type of the output file.
 * @param {object} transformParams - The trasformation descriptor for writting
 *  the required file.
 */
export const writeFile = (data, type, transformParams) => {
  const transformer = getTransformerByType(type);
  const fileContent = transformer.writeFile(data, transformParams);
  let fileFullName = transformParams.appendDateToFileName ? 
    FileDownloadUtils.generateFileName(transformParams.fileName) : transformParams.fileName;
    
  fileFullName += `.${type}`;
  FileDownloadUtils.downloadFileByType(fileContent, fileFullName, type);
};