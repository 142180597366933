/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import EventsActions from 'actions/EventsActions';
import { MAIN_ROUTES } from 'config/routesDefaults';
import {Loader, Icons} from 'react-template';
import { NotificationManager } from 'react-notifications';
import * as MsgDefaults from 'config/MessagingAndTitlesDefaults';


const AnalystActivationOptions = {
  active: {
    icon: Icons.success,
    description: 'Success',
    className: 'evc-text-success',
    message: 'The action is now in progress',
    hasBack: true,
  },
  expired: {
    icon: Icons.expired,
    description: 'Expired',
    className: 'evc-text-danger',
    message: 'This link may have been expired or already run by another user',
    hasBack: true,
  },
};

@withRouter
export default class analystActivation extends Component {
  constructor(props) {
    super(props);
    const uniqueKey = this.props.location.query.key;

    this.state = {
      uniqueKey,
    };
    this.redirectToListing = ::this.redirectToListing;
  }

  componentWillMount() {
    let analystActivationState = 'expired';

    EventsActions.getAnalystActivationResponse(this.state.uniqueKey).then(response => {
      if (response) {
        analystActivationState = 'active';
      }
      this.setState({ analystActivationState });
    }).catch((error) => {
      NotificationManager.warning(error, MsgDefaults.ANALYST_ACTIVATION_ERROR_TITLE, 10000);
      this.redirectToListing();
    });
  }
  redirectToListing() {
    this.props.router.push({
      pathname: MAIN_ROUTES.EVENTS,
    });
  }
  render() {
    if (this.state.analystActivationState) {
      const analystActivationStateObject = AnalystActivationOptions[this.state.analystActivationState];

      return (
        <div className="textCenter virticalAlign">
          <div>
            <h1 className="margBtm0">
              <i className={analystActivationStateObject.icon}></i>
            </h1>
            <h2 className={analystActivationStateObject.className}>{analystActivationStateObject.description}</h2>
            <h4 className="normal-text">{analystActivationStateObject.message}</h4>
            <Button
              bsStyle="primary"  
              onClick={this.redirectToListing}>
              <i className={Icons.arrowLeft}></i> Back
            </Button>
          </div>
        </div>);
    } else {
      return (
        <div className='ossLoader'>
          <Loader />
        </div>
      );
    }
  }
}
