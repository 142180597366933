/* Copyright 2019 Greyskies. All Rights Reserved. */

import AutomationWorkflowAPI from 'api/AutomationWorkflowAPI';

export default {
  saveWorkflow(data) {
    return AutomationWorkflowAPI.saveAutomationWorkflow(data);
  },
  checkWorkflowNameExists(workflowName) { 
    return AutomationWorkflowAPI.checkWorkflowNameExists(workflowName);
  },
  getAutomationWorkflow(workflowId){
    return AutomationWorkflowAPI.getAutomationWorkflow(workflowId);
  },
  initiateAutomationWorkflowTable(){
    return AutomationWorkflowAPI.initiateAutomationWorkflowTable();
  },
  deleteAutomationWorkflow(workflowId){
    return AutomationWorkflowAPI.deleteAutomationWorkflow(workflowId);
  },
  getAutomationWorkflowList(workflowId){
    return AutomationWorkflowAPI.getAutomationWorkflowList(workflowId);
  },
  checkAutomationWorkflowActivation(tId){
    return AutomationWorkflowAPI.checkAutomationWorkflowActivation(tId);
  },
  resumeAutomationWorkflowAction(pId, tId, lastStage){
    return AutomationWorkflowAPI.resumeAutomationWorkflowAction(pId, tId, lastStage);
  },
  fireAutomationWorkflow(workflowId, parameters){
    return AutomationWorkflowAPI.fireAutomationWorkflow(workflowId, parameters);
  },
  getAutomationWorkflowParameters(workflowId){
    return AutomationWorkflowAPI.getAutomationWorkflowParameters(workflowId);
  },
}
