/* Copyright 2019 Greyskies. All Rights Reserved. */

export const SUCCESS = 200;
export const NO_CONTENT_STATUS_CODE = 204;
export const TOO_MANY_SELECTIONS_STATUS_CODE = 204;
export const UNAUTHORIZED = 401;
export const FORBIDDEN = 403;
export const NOT_FOUND = 404;
export const UNPROCESSABLE_ENTITY_CODE = 422;
export const CONCURENT_UPDATE = 451;
export const INTERNAL_SERVER_ERROR = 500;
export const LOGOUT = 503;
export const CONTACT_SUPPORT = 601;
export const SYSTEM_OVERLOAD = 602;
// export const SYSTEM_OVERLOAD = 603;   /// UNKOWN
export const FAILED_SCHEDULING = 604;