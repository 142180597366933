/* Copyright 2019 Greyskies. All Rights Reserved. */

import {Icons} from 'react-template';
import {DateTimeUtils} from 'js-utils';
import {MAIN_ROUTES} from 'config/routesDefaults';

// Basic Info defaults
export const EVENT_ACTION_RUN_LOG_DETAILS_FIELDS = {
  stageStatus: 'Status',
  generateConfigTime : 'Generated Config Time',
  approvalTime: 'Approval Time',
  userName : 'Approved By',
  runTime: 'Run Time',
  keysVal: 'Record Identifier',
  sentAt: 'Sent At',
  sentTo: 'Sent To',
  startedAt: 'Started At',
  finishedAt: 'Finished At',
  ansibleProjectName: 'Ansible Project Name',
  entryPoint: 'Entry Point',
  approvedAt: 'Approval Time',
  approvedBy: 'Approved By',
  reportName: 'Report Name',
  proName: 'Project Name',
  extVar: 'Variables',
};

export const COMMON_DETAILS_FIELDS_KEYS = {
  runStatus: 'runStatus',
  stageStatus: 'stageStatus',
  runTime: 'runTime',
};

export const EVENT_LOGS_TABLE_FIELDS = {
  DATE: 'Date',
  RUN_ID: 'Run Id',
  ACTION_NAME: 'Action Name',
  KEYS: 'Keys',
  DOWNLOAD: 'Logs',
  EXTERNAL_VARIABLES_LABEL: 'External Variables',
  RUN_TYPE: 'Run Type',
  USER: 'User',
};

export const FILTERS = {
  ACTION_TYPE: 'Action Type',
  TEMPLATE_NAME: 'Template Name',
  KEYS: 'Keys',
  DATE: 'Date',
  NETWORK_ELEMENTS: 'Network Elements',
  WORKFLOW_NAME: 'Workflow Name',
  PROJECT_NAME: 'Project Name',
  RUN_TYPES: 'Run Type',
  RUN_STATUS: 'Run Status',
  COLLECTION_PROFILE: 'Collection Profile',
  ATTRIBUTE_BINDING: 'Attribute Binding',
  INPUT_SOURCE: 'Input Source',
  USERNAMES: 'User',
};

export const ACTION_TYPES = {
  TEMPLATES: {
    value: 'Template',
    text: 'Template',
  },
  WORKFLOWS: {
    value: 'Workflow',
    text: 'Workflow',
  },
};

export const ATTRIBUTE = 'ATTRIBUTE';
export const RECORD = 'RECORD';
export const NO_RUN_ID_MSG = 'No Run ID selected';
export const NO_DATA_AVAILABLE_MSG = 'no data available';
export const ENTITY_NAME_PARAM = 'entityName';
export const RUN_ID = 'runId';
export const LOGS_DOWNLOAD_URL = 'admin/automationRunsInfo/download';
export const OUTPUT_DOWNLOAD_URL = 'admin/automationRunsInfo/downloadOutput';
export const DOWNLOAD_PATH_KEY = 'downloadPath';
export const OUTPUT_PATH_KEY = 'runOutputPath';
export const RECORDS_IDENTIFIER = 'keysVal';

export const NETWORK_ELEMENT_PLACEHOLDER = 'Network Element';
export const TEMPLATE_NAME_PLACEHOLDER = 'Template Name';
export const RECORDS_KEY_FILTER_POPUP_MSG = 'Please fill the keywords that the fields may contain';
export const OUTPUT = 'Output';
export const EXTERNAL_VARIABLE_KEY = 'extVar';
export const statusIconsMap = {
  Success: Icons.successDefault,
  Failure: Icons.error,
  'In Progress': Icons.inProgress,
  UNKNOWN: Icons.error,
  CONNECTED: Icons.pending,
  SUBMITTED:Icons.pending,
  INITIALIZING: Icons.pending,
  RUNNING: Icons.inProgress,
  FINISHED: Icons.successDefault,
  FAILED: Icons.error,
  KILLED: Icons.error,
  LOST: Icons.error,
};
export const WORKFLOW = 'workflow';
export const EVENT = 'event';
export const CUSTOM_ANALYTICS = 'customAnalytics';
export const EVENT_ATTRIBUTE_RUN_TYPE = 'eventAttribute';
export const EVENT_RECORD_RUN_TYPE = 'eventRecord';
export const TRIGGER_TYPE = {
  ON_DEMAND : 'On Demand',
  EVENT : 'Event',
};
export const INFO_LOGGING_KEYS = {
  EVENT : 'event', // todo should be separated into event_management and triggered_event
  LISTING_WORKFLOW : 'listingWorkflow',
  ON_DEMAND_WORKFLOW : 'onDemandWorkflow',
  ANALYTICS_LOGS : 'analyticsLogs',
  LISTING_ANALYTICS : 'listingAnalytics',
  ON_DEMAND_ANALYTICS : 'onDemandAnalytics',
};

const createDefaults = (keys, defaultValue) => {
  const defaults = {};

  keys.forEach(key => {
    defaults[INFO_LOGGING_KEYS[key]] = defaultValue;
  });

  return defaults;
};

const workflowKeys = ['LISTING_WORKFLOW', 'ON_DEMAND_WORKFLOW'];
const analyticsKeys = ['ANALYTICS_LOGS', 'LISTING_ANALYTICS', 'ON_DEMAND_ANALYTICS'];

export const DEFAULT_RUN_TYPE = {
  [INFO_LOGGING_KEYS.EVENT]: EVENT,
  ...createDefaults(workflowKeys, WORKFLOW),
  ...createDefaults(analyticsKeys, CUSTOM_ANALYTICS),
};

export function getDefaultRunType(infoType, isAttributeType) {
  const result = {
    [INFO_LOGGING_KEYS.EVENT]: isAttributeType ? EVENT_ATTRIBUTE_RUN_TYPE : EVENT_RECORD_RUN_TYPE,
    ...createDefaults(workflowKeys, WORKFLOW),
    ...createDefaults(analyticsKeys, CUSTOM_ANALYTICS),
  };

  return result[infoType];
}

export const DEFAULT_NAME_FIELD_LABEL = {
  [INFO_LOGGING_KEYS.EVENT]: 'Event Name',
  ...createDefaults(workflowKeys, 'Workflow Name'),
  ...createDefaults(analyticsKeys, 'Project Name'),
};

export const DEFAULT_PAGE_LABEL = {
  [INFO_LOGGING_KEYS.EVENT]: 'Event Logs',
  ...createDefaults(workflowKeys, 'Workflow Logs'),
  ...createDefaults(analyticsKeys, 'Analytics Logs'),
};

export const DEFAULT_ES_NAME_FIELD = {
  [INFO_LOGGING_KEYS.EVENT]: 'eventName',
  ...createDefaults(workflowKeys, 'workflowName'),
  ...createDefaults(analyticsKeys, 'proName'),
};

export const DEFAULT_DATE_RANGE = {
  [INFO_LOGGING_KEYS.EVENT]: DateTimeUtils.LAST_7DAYS,
  [INFO_LOGGING_KEYS.LISTING_WORKFLOW]: DateTimeUtils.LAST_7DAYS,
  [INFO_LOGGING_KEYS.ON_DEMAND_WORKFLOW]: DateTimeUtils.LAST_HOUR,
  [INFO_LOGGING_KEYS.ANALYTICS_LOGS]: DateTimeUtils.THIS_MONTH,
  [INFO_LOGGING_KEYS.LISTING_ANALYTICS]: DateTimeUtils.LAST_7DAYS,
  [INFO_LOGGING_KEYS.ON_DEMAND_ANALYTICS]: DateTimeUtils.LAST_HOUR,
};

export const DEFAULT_BACK_ROUTE = {
  [INFO_LOGGING_KEYS.EVENT]: MAIN_ROUTES.EVENTS,
  ...createDefaults(workflowKeys, MAIN_ROUTES.AUTOMATION_WORKFLOWS),
  [INFO_LOGGING_KEYS.ANALYTICS_LOGS]: null,
  [INFO_LOGGING_KEYS.LISTING_ANALYTICS]: MAIN_ROUTES.CUSTOM_ANALYTICS,
  [INFO_LOGGING_KEYS.ON_DEMAND_ANALYTICS]: MAIN_ROUTES.CUSTOM_ANALYTICS,
};

export const IS_DETAILS_STATICALLY_BUILT = {
  [INFO_LOGGING_KEYS.EVENT]: false,
  ...createDefaults(workflowKeys, false),
  ...createDefaults(analyticsKeys, true),
};

export const STATIC_DETAILS_FIELDS = {
  ...createDefaults(analyticsKeys, ['proName', 'runTime', 'extVar']),
};

function getEventFilterSelections() {
  return {
    templateNames: [],
    workflowNames: [],
    actionTypes: [],
  };
}

function getWorkflowFilterSelections(isOnDemand, userSelected) {
  return {
    triggerTypes: isOnDemand ? [TRIGGER_TYPE.ON_DEMAND] : [],
    triggeredBy: isOnDemand ? [userSelected] : [],
  };
}

function getAnalyticsLogsFilterSelections(isOnDemand, userSelected) {
  return {
    triggerTypes: isOnDemand ? [TRIGGER_TYPE.ON_DEMAND] : [],
    triggeredBy: isOnDemand ? [userSelected] : [],
    runStatus: [],
    attributeBinding: [],
    collectionProfile: [],
    inputSource: [],
  };
}

export function getInfoTypeFilterSelections(infoType, userSelected) {
  switch (infoType) {
  case INFO_LOGGING_KEYS.EVENT:
    return getEventFilterSelections();
  case INFO_LOGGING_KEYS.LISTING_WORKFLOW:
  case INFO_LOGGING_KEYS.ON_DEMAND_WORKFLOW:
    return getWorkflowFilterSelections(
      infoType === INFO_LOGGING_KEYS.ON_DEMAND_WORKFLOW, userSelected);
  case INFO_LOGGING_KEYS.ANALYTICS_LOGS:
  case INFO_LOGGING_KEYS.LISTING_ANALYTICS:
  case INFO_LOGGING_KEYS.ON_DEMAND_ANALYTICS:
    return getAnalyticsLogsFilterSelections(
      infoType === INFO_LOGGING_KEYS.ON_DEMAND_ANALYTICS, userSelected);
  default:
    throw new Error(`Unsupported infoType: ${infoType}`);
  }
}

function getEventAttributeStates(response) {
  const {
    neId = [],
    templateName: templateNamesList = [],
    workflowName: workflowNamesList = [],
  } = response;
  const shouldLoadData = [templateNamesList, workflowNamesList].some(arr => arr.length);

  return { templateNamesList, workflowNamesList, neId, shouldLoadData};
}

function prepareRecordsState(recordKeys) {
  const newState = {};
  const recordFilterValues = {};
  const fieldNameToESNameMap = {};

  recordKeys.forEach(keysValDto => {
    fieldNameToESNameMap[keysValDto.fieldName] = keysValDto.elasticsearchName;
    recordFilterValues[keysValDto.fieldName] = '';
  });
  newState.fieldNameToESNameMap = fieldNameToESNameMap;
  newState.recordFilterValues = recordFilterValues;
  newState.showKeysFilter = Object.keys(fieldNameToESNameMap).length;

  return newState;
}

function getEventRecordStates(response) {
  const {
    templateName: templateNamesList = [],
    workflowName: workflowNamesList = [],
    recordKeys,
  } = response;
  const shouldLoadData = [templateNamesList, workflowNamesList].some(arr => arr.length);
  
  return {
    ...prepareRecordsState(recordKeys),
    templateNamesList,
    workflowNamesList,
    shouldLoadData,
  };
}


function getWorkflowStates(response, userSelected) {
  const {
    triTyp: triggerTypesList = [],
    triBy = [],
  } = response;

  const shouldLoadData = [triggerTypesList, triBy].some(arr => arr.length);

  return {
    triggerTypesList,
    triggeredByList: userSelected ? [userSelected] : triBy,
    shouldLoadData,
  };
}

function getAnalyticsStates(response, userSelected) {
  const {
    triTyp: triggerTypesList = [],
    triBy: triggeredByList = [],
    runStatus: runStatusList = [],
    colPro: collectionProfileList = [],
    attBin: attributeBindingList = [],
    inpSrc: inputSourceList = [],
    colPro: collectionProfilesList = [],
  } = response;
  const shouldLoadData = [triggerTypesList, triggeredByList, runStatusList, collectionProfilesList,
    attributeBindingList, inputSourceList].some(arr => arr.length);

  // To initially display the selected username in the filter if the logs are not added yet
  if (userSelected && !triggeredByList.includes(userSelected)) {
    triggeredByList.push(userSelected);
  }

  return {
    triggerTypesList,
    triggeredByList,
    runStatusList,
    collectionProfileList,
    attributeBindingList,
    inputSourceList,
    shouldLoadData,
  };
}

export function getSpecificStatesFromInitFiltersResponse(runType, response, userSelected) {
  switch (runType) {
  case EVENT_ATTRIBUTE_RUN_TYPE:
    return getEventAttributeStates(response);
  case EVENT_RECORD_RUN_TYPE:
    return getEventRecordStates(response);
  case WORKFLOW:
    return getWorkflowStates(response, userSelected);
  case CUSTOM_ANALYTICS:
    return getAnalyticsStates(response, userSelected);
  default:
    throw new Error(`Unsupported runType: ${runType}`);
  }
}


function getEventAttributeQueryParams(filterSelection, entityId, runs, selectedNEs) {
  return {
    templateNames: filterSelection.templateNames,
    workflowNames: filterSelection.workflowNames,
    actionTypes: filterSelection.actionTypes,
    runs,
    networkElement: selectedNEs,
    workflowId: -1,
    projectId: -1,
    ...(entityId && { eventId: entityId }),
  };
}

function convertFieldNameEs(recordFilterValues, fieldNameToESNameMap) {
  return Object.keys(recordFilterValues).reduce((acc, fieldName) => {
    const esName = fieldNameToESNameMap[fieldName];
    
    if (esName) {
      acc[esName] = recordFilterValues[fieldName];
    }

    return acc;
  }, {});
}

function getEventRecordQueryParams(filterSelection, entityId, recordFilterValues, fieldNameToESNameMap) {
  return {
    templateNames: filterSelection.templateNames,
    workflowNames: filterSelection.workflowNames,
    actionTypes: filterSelection.actionTypes,
    recordKeysValues: convertFieldNameEs(recordFilterValues, fieldNameToESNameMap),
    workflowId: -1,
    projectId: -1,
    ...(entityId && { eventId: entityId }),
  };
}

function getWorkflowQueryParams(filterSelection, entityId) {
  return {
    triggerTypes: filterSelection.triggerTypes,
    triggeredBy: filterSelection.triggeredBy,
    eventId: -1,
    projectId: -1,
    ...(entityId && { workflowId: entityId }),
  };
}


function getAnalyticsQueryParams(filterSelection, entityId) {
  return {
    triggerTypes: filterSelection.triggerTypes,
    triggeredBy: filterSelection.triggeredBy,
    eventId: -1,
    workflowId: -1,
    runStatus: filterSelection.runStatus,
    collectionProfiles: filterSelection.collectionProfile,
    attributeBindings: filterSelection.attributeBinding,
    inputSources: filterSelection.inputSource,
    ...(entityId && { projectId: entityId }),
  };
}

export function getSpecificQueryParamsFromFilterSelection(runType, filterSelection,
  entityId, runs, selectedNEs, recordFilterValues, fieldNameToESNameMap) {
  switch (runType) {
  case EVENT_ATTRIBUTE_RUN_TYPE:
    return getEventAttributeQueryParams(filterSelection, entityId, runs, selectedNEs);
  case EVENT_RECORD_RUN_TYPE:
    return getEventRecordQueryParams(filterSelection, entityId,
      recordFilterValues, fieldNameToESNameMap);
  case WORKFLOW:
    return getWorkflowQueryParams(filterSelection, entityId);
  case CUSTOM_ANALYTICS:
    return getAnalyticsQueryParams(filterSelection, entityId);
  default:
    throw new Error(`Unsupported runType: ${runType}`);
  }
}


function initiateEventAttributeFilterSelection(filterSelection = {}, oldSelection = {}) {
  if (!filterSelection.templateNames) {
    filterSelection.templateNames = [];
  }
  if (!filterSelection.workflowNames) {
    filterSelection.workflowNames = [];
  }
  if (!filterSelection.actionTypes) {
    filterSelection.actionTypes = [];
  }
  const treeSelection = filterSelection.treeSelection
  const newState = {};
  const isTreeSelectionEqual = _.isEqual(oldSelection.treeSelection, treeSelection);
  
  if (!isTreeSelectionEqual) {
    newState.selectedNEs = treeSelection ?
      treeSelection.treeNodes.map(node => node.treeNodeId) : [];
  }
  
  const isSelectionChanged = !_.isEqual(oldSelection.templateNames, filterSelection.templateNames)
    || !_.isEqual(oldSelection.workflowNames, filterSelection.workflowNames)
    || !_.isEqual(oldSelection.actionTypes, filterSelection.actionTypes)
    || !isTreeSelectionEqual;

  return { isSelectionChanged, newState };
}

function initiateEventRecordFilterSelection(filterSelection, oldSelection) {
  if (!filterSelection.templateNames) {
    filterSelection.templateNames = [];
  }
  if (!filterSelection.workflowNames) {
    filterSelection.workflowNames = [];
  }
  if (!filterSelection.actionTypes) {
    filterSelection.actionTypes = [];
  }
  const isSelectionChanged = !_.isEqual(oldSelection.templateNames, filterSelection.templateNames)
    || !_.isEqual(oldSelection.workflowNames, filterSelection.workflowNames)
    || !_.isEqual(oldSelection.actionTypes, filterSelection.actionTypes);

  return { isSelectionChanged, newState: {} };
}


function initiateWorkflowFilterSelection(filterSelection, oldSelection) {
  if (!filterSelection.triggerTypes) {
    filterSelection.triggerTypes = [];
  }
  if (!filterSelection.triggeredBy) {
    filterSelection.triggeredBy = [];
  }
  const isSelectionChanged = !_.isEqual(oldSelection.triggerTypes, filterSelection.triggerTypes)
  || !_.isEqual(oldSelection.triggeredBy, filterSelection.triggeredBy);

  return { isSelectionChanged, newState: {} };
}

function initiateAnalyticsFilterSelection(filterSelection, oldSelection) {
  if (!filterSelection.triggerTypes) {
    filterSelection.triggerTypes = [];
  }
  if (!filterSelection.triggeredBy) {
    filterSelection.triggeredBy = [];
  }
  if (!filterSelection.runStatus) {
    filterSelection.runStatus = [];
  }
  if (!filterSelection.collectionProfile) {
    filterSelection.collectionProfile = [];
  }
  if (!filterSelection.attributeBinding) {
    filterSelection.attributeBinding = [];
  }
  if (!filterSelection.inputSource) {
    filterSelection.inputSource = [];
  }
  const isSelectionChanged = !_.isEqual(oldSelection.triggerTypes, filterSelection.triggerTypes)
    || !_.isEqual(oldSelection.triggeredBy, filterSelection.triggeredBy)
    || !_.isEqual(oldSelection.runStatus, filterSelection.runStatus)
    || !_.isEqual(oldSelection.collectionProfile, filterSelection.collectionProfile)
    || !_.isEqual(oldSelection.attributeBinding, filterSelection.attributeBinding)
    || !_.isEqual(oldSelection.inputSource, filterSelection.inputSource);

  return { isSelectionChanged, newState: {} };
}

export function initiateSpecificFilterSelectionData(runType, filterSelection, oldSelection) {
  switch (runType) {
  case EVENT_ATTRIBUTE_RUN_TYPE:
    return initiateEventAttributeFilterSelection(filterSelection, oldSelection);
  case EVENT_RECORD_RUN_TYPE:
    return initiateEventRecordFilterSelection(filterSelection, oldSelection);
  case WORKFLOW:
    return initiateWorkflowFilterSelection(filterSelection, oldSelection);
  case CUSTOM_ANALYTICS:
    return initiateAnalyticsFilterSelection(filterSelection, oldSelection);
  default:
    throw new Error(`Unsupported runType: ${runType}`);
  }
}

function getfilterConfigFlags({
  isAvailable = false,
  mandatory = false,
  disabled = false,
  isInitiallyShown = false,
} = {}) {
  return {
    isAvailable,
    mandatory,
    disabled,
    isInitiallyShown,
  };
}


function getEventFilterConfiguration(isAttributeEvent, showKeysFilter, isSpecificRun){
  return {
    dateRange: getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      disabled: isSpecificRun,
      isInitiallyShown: true,
    }),
    entityName: getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      disabled: true,
      isInitiallyShown: true,
    }),
    dynamicEntityName:getfilterConfigFlags({}),
    runStatus:getfilterConfigFlags({}),
    collectionProfile:getfilterConfigFlags({}),
    attributeBinding:getfilterConfigFlags({}),
    inputSource:getfilterConfigFlags({}),
    actionTypes: getfilterConfigFlags({
      isAvailable: true,
    }),
    templateNames: getfilterConfigFlags({
      isAvailable: true,
    }),
    workflowNames: getfilterConfigFlags({
      isAvailable: true,
    }),
    treeSelection: getfilterConfigFlags({
      isAvailable: isAttributeEvent,
      disabled: isSpecificRun,
    }),
    keys: getfilterConfigFlags({
      isAvailable: !isAttributeEvent && showKeysFilter,
      disabled: isSpecificRun,
    }),
    triggerTypes: getfilterConfigFlags({}),
    triggeredBy: getfilterConfigFlags({}),
  };
}

function getlistWorkflowFilterConfiguration(isSpecificRun){
  return {
    dateRange:getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      disabled: isSpecificRun,
      isInitiallyShown: true,
    }),
    entityName:getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      disabled: true,
      isInitiallyShown: true,
    }),
    dynamicEntityName:getfilterConfigFlags({}),
    runStatus:getfilterConfigFlags({}),
    collectionProfile:getfilterConfigFlags({}),
    attributeBinding:getfilterConfigFlags({}),
    inputSource:getfilterConfigFlags({}),
    actionTypes: getfilterConfigFlags({}),
    templateNames: getfilterConfigFlags({}),
    workflowNames: getfilterConfigFlags({}),
    treeSelection: getfilterConfigFlags({}),
    keys: getfilterConfigFlags({}),
    triggerTypes: getfilterConfigFlags({
      isAvailable: true,
    }),
    triggeredBy: getfilterConfigFlags({
      isAvailable: true,
    }),
  };
}

function getOnDemandWorkflowFilterConfiguration(isSpecificRun){
  return {
    dateRange:getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      disabled: isSpecificRun,
      isInitiallyShown: true,
    }),
    entityName:getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      disabled: true,
      isInitiallyShown: true,
    }),
    dynamicEntityName:getfilterConfigFlags({}),
    runStatus:getfilterConfigFlags({}),
    collectionProfile:getfilterConfigFlags({}),
    attributeBinding:getfilterConfigFlags({}),
    inputSource:getfilterConfigFlags({}),
    actionTypes: getfilterConfigFlags({}),
    templateNames: getfilterConfigFlags({}),
    workflowNames: getfilterConfigFlags({}),
    treeSelection: getfilterConfigFlags({}),
    keys: getfilterConfigFlags({}),
    triggerTypes: getfilterConfigFlags({
      isAvailable: true,
      isInitiallyShown: true,
      disabled: true,
      mandatory: true,
    }),
    triggeredBy: getfilterConfigFlags({
      isAvailable: true,
      isInitiallyShown: true,
      disabled: true,
      mandatory: true,
    }),
  };
}

function getAnalyticsLogsFilterConfiguration(){
  return {
    dateRange:getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      isInitiallyShown: true,
    }),
    entityName:getfilterConfigFlags({}),
    dynamicEntityName: getfilterConfigFlags({
      isAvailable: true,
      isInitiallyShown: true,
    }),
    runStatus:getfilterConfigFlags({
      isAvailable: true,
    }),
    collectionProfile:getfilterConfigFlags({
      isAvailable: true,
    }),
    attributeBinding:getfilterConfigFlags({
      isAvailable: true,
    }),
    inputSource:getfilterConfigFlags({
      isAvailable: true,
    }),
    actionTypes: getfilterConfigFlags({}),
    templateNames: getfilterConfigFlags({}),
    workflowNames: getfilterConfigFlags({}),
    treeSelection: getfilterConfigFlags({}),
    keys: getfilterConfigFlags({}),
    triggerTypes: getfilterConfigFlags({
      isAvailable: true,
    }),
    triggeredBy: getfilterConfigFlags({
      isAvailable: true,
    }),
  };
}

function getlistAnalyticsFilterConfiguration(isSpecificRun){
  return {
    dateRange:getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      disabled: isSpecificRun,
      isInitiallyShown: true,
    }),
    entityName:getfilterConfigFlags({}),
    dynamicEntityName: getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      isInitiallyShown: true,
    }),
    runStatus:getfilterConfigFlags({
      isAvailable: true,
    }),
    collectionProfile:getfilterConfigFlags({
      isAvailable: true,
    }),
    attributeBinding:getfilterConfigFlags({
      isAvailable: true,
    }),
    inputSource:getfilterConfigFlags({
      isAvailable: true,
    }),
    actionTypes: getfilterConfigFlags({}),
    templateNames: getfilterConfigFlags({}),
    workflowNames: getfilterConfigFlags({}),
    treeSelection: getfilterConfigFlags({}),
    keys: getfilterConfigFlags({}),
    triggerTypes: getfilterConfigFlags({
      isAvailable: true,
    }),
    triggeredBy: getfilterConfigFlags({
      isAvailable: true,
    }),
  };
}

function getOnDemandAnalyticsFilterConfiguration(isSpecificRun){
  return {
    dateRange:getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      disabled: isSpecificRun,
      isInitiallyShown: true,
    }),
    entityName:getfilterConfigFlags({}),
    dynamicEntityName: getfilterConfigFlags({
      isAvailable: true,
      mandatory: true,
      isInitiallyShown: true,
    }),
    runStatus:getfilterConfigFlags({
      isAvailable: true,
    }),
    collectionProfile:getfilterConfigFlags({
      isAvailable: true,
    }),
    attributeBinding:getfilterConfigFlags({
      isAvailable: true,
    }),
    inputSource:getfilterConfigFlags({
      isAvailable: true,
    }),
    actionTypes: getfilterConfigFlags({}),
    templateNames: getfilterConfigFlags({}),
    workflowNames: getfilterConfigFlags({}),
    treeSelection: getfilterConfigFlags({}),
    keys: getfilterConfigFlags({}),
    triggerTypes: getfilterConfigFlags({
      isAvailable: true,
      isInitiallyShown: true,
      disabled: false,
      mandatory: false,
    }),
    triggeredBy: getfilterConfigFlags({
      isAvailable: true,
      isInitiallyShown: true,
      disabled: false,
      mandatory: false,
    }),
  };
}

export function getFilterConfigurations(key, isAttributeEvent, showKeysFilter, isSpecificRun) {
  switch (key) {
  case INFO_LOGGING_KEYS.EVENT:
    return getEventFilterConfiguration(isAttributeEvent, showKeysFilter, isSpecificRun);
  case INFO_LOGGING_KEYS.LISTING_WORKFLOW:
    return getlistWorkflowFilterConfiguration(isSpecificRun);
  case INFO_LOGGING_KEYS.ON_DEMAND_WORKFLOW:
    return getOnDemandWorkflowFilterConfiguration(isSpecificRun);
  case INFO_LOGGING_KEYS.ANALYTICS_LOGS:
    return getAnalyticsLogsFilterConfiguration();
  case INFO_LOGGING_KEYS.LISTING_ANALYTICS:
    return getlistAnalyticsFilterConfiguration(isSpecificRun);
  case INFO_LOGGING_KEYS.ON_DEMAND_ANALYTICS:
    return getOnDemandAnalyticsFilterConfiguration(isSpecificRun);
  default:
    throw new Error(`Unsupported key: ${key}`);
  }
}

export const COLS_DISPLAYED_IN_INFO_PAGE = {
  event: {
    runTime: true,
    extVar: true,
    actionName: true,
    treePath: true,
    runId: true,
  },
  listingWorkflow: {
    runTime: true,
    triBy: true,
    triTyp: true,
    extVar: true,
    runId: true,
  },
  onDemandWorkflow: {
    runTime: true,
    triBy: true,
    triTyp: true,
    extVar: true,
    runId: true,
  },
  analyticsLogs: {
    runTime: true,
    proName: true,
    triBy: true,
    triTyp: true,
    runId: true,
  },
  listingAnalytics: {
    runTime: true,
    proName: true,
    triBy: true,
    triTyp: true,
    runId: true,
  },
  onDemandAnalytics: {
    runTime: true,
    proName: true,
    triBy: true,
    triTyp: true,
    runId: true,
  },
};
