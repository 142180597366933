/* Copyright 2019 Greyskies. All Rights Reserved. */

export const PIXEL_TO_PDF_FACTOR = 2.3;
export const PDF_FONT_SIZE = 16;
export const PDF_FONT = 'helvetica';
export const PDF_FONT_TYPE = 'bold';
export const PDF_WIDTH = 190;
export const PDF_DATE_FORMAT = 'MMM D, YYYY, h:mm';
export const PDF_TOOLTIP = 'PDF';
export const CSV_TOOLTIP = 'CSV';
export const DOWNLOAD_CENTER = 'Download Center';
export const NEW_DOWNLOAD_MESSAGE = 'A new download added ';
export const DOWNLOAD_DONE_MESSAGE = ' download done';
export const DOWNLOAD_FAILED_MESSAGE = ' download failed';
export const SUCCESS = 'success';
export const DANGER = 'danger';

