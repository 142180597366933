/* Copyright 2019 Greyskies. All Rights Reserved. */

import AppDispatcher from 'dispatcher/AppDispatcher';
import DownloadsConstants from 'constants/DownloadsConstants';
import DownloadsStore from 'stores/DownloadsStore';

export default {
  addDownload(download, callback) {
    AppDispatcher.dispatch({
      actionType: DownloadsConstants.ADD_DOWNLOAD,
      data: download,
    });
    if(callback){
      callback();
    }
  },
  clearAllDownloads(){
    AppDispatcher.dispatch({
      actionType: DownloadsConstants.CLEAR_DOWNLOADS,
    });
  },
  removeDownload(download){
    AppDispatcher.dispatch({
      actionType: DownloadsConstants.REMOVE_DOWNLOAD,
      data: download,
    });
  },
  addImageDownload(path, name){
    const download = {
      name,
      path,
      type:'png',
      id : 'image-' + DownloadsStore.generateInstantId(),
      progress : 100,
      progressColor : 'success',
    };
    AppDispatcher.dispatch({
      actionType: DownloadsConstants.ADD_DOWNLOAD,
      data: download,
    });
  },
  addWidgetDownload(messageContent, name, type, id){
    const download = {
      name,
      ...messageContent,
      type,
      id: id + DownloadsStore.generateInstantId(),
      progress : 100,
      progressColor : 'success',
    };

    this.addDownload(download, null);
  },
};
