/* Copyright 2019 Greyskies. All Rights Reserved. */

import { DateTimeUtils } from 'js-utils';


export const DESIGN_SPACE_STATUS = {
  NO_DESIGNSPACE_AVAILABLE: 'NO_DESIGNSPACE_AVAILABLE',
  NEED_UPDATE: 'NEED_UPDATE',
};

export const REFRESH_RATES =
  [
    {
      value: null,
      name: 'OFF'
    },
    {
      value: 30,
      name: '30 Seconds'
    },
    {
      value: 1 * 60,
      name: '1 Minute'
    },
    {
      value: 2 * 60,
      name: '2 Minutes'
    },
    {
      value: 5 * 60,
      name: '5 Minutes'
    },
    {
      value: 10 * 60,
      name: '10 Minutes'
    },
    {
      value: 15 * 60,
      name: '15 Minutes'
    },
    {
      value: 30 * 60,
      name: '30 Minutes'
    }
  ];

export const LABELS = {
  DASHBOARD: 'Dashboard',
  REPORT: 'Report',
  ACTIONS: 'Actions',
  ADD_WIDGET: 'Add Widget',
  DASHBOARDS_VIEW: 'Dashboards View',
  ADD_DASHBOARD: 'Add Dashboard',
  ADD_PANEL: 'Add Panel',
  PASTE_PANEL: 'Paste Panel',
  CLEAR_CLIPBOARD: 'Clear Clipboard',
  CLONE: 'Clone',
  EDIT: 'Edit',
  DELETE: 'Delete',
  FAVORITE_OR_UNFAVORITE: 'Favorite / Unfavorite',
  FAVORITES: 'Favorites',
  SELECT_REFRESH_RATE: 'Select refresh rate',
  TEMPLATE: 'Template',
  EXPORT: 'Export',
  DOWNLOAD_REPORT: 'Download Report',
  PLAYBACK: 'Playback',
  VIEW_FROM: 'View From',
};

export const NO_REPORT_AVAILABLE = 'No reports available';
export const NO_DASHBOARD_AVAILABLE = 'No dashboards available';

export const SLIDE_SHOW_INTERVALS = [30, 45, 60];
export const DEFAULT_SLIDE_SHOW_INTERVAL = SLIDE_SHOW_INTERVALS[0] * 1000;
export const REFRESH_RATE_RELATIVE_DATE = DateTimeUtils.LAST_HOUR;
export const DEFAULT_REFRESH_RATE = REFRESH_RATES[0];

export const IMPORTING_FAILED_TITLE = (designSpaceType) =>
`Error while importing new ${designSpaceType}`;

const getChangePermissionLabel = (designSpaceType, actionType) => {
  return `Share ${designSpaceType} with my group with ${actionType} permission`;
};

const getLoseAccessMsg = (designSpaceType, canView) => {
  return ` By changing the owner you will lose access to${canView ? ' Edit' : ''}  this ${designSpaceType}`;
};


export const OWNERSHIP_DEFAULTS = {
  EDIT: 'Edit',
  VIEW: 'View',
  EDITOR: 'EDITOR',
  VIEWER: 'VIEWER',
  DO_NOT_SHARE_LABEL: 'Don\'t Share',
  PROCEED_OWN_NOTIFYING_MSG: 'The new owner will be notified.',
  PROCEED_CONFIRMATION_MSG: 'What would you like to do?',
  getChangePermissionLabel,
  getLoseAccessMsg,
};

export const UNCATEGORIZED = 'uncategorized'; 

export const MAX_ZOOM_IN = 200; 
export const MIN_ZOOM_OUT = 50;
export const ZOOMING_STEP = 10;

export const TABLE_CHART_OPTIONS_LAYOUT = {
  h: 10,
  i: '1000',
  maxH: undefined,
  maxW: undefined,
  minH: 7,
  minW: 6,
  moved: false,
  static: false,
  w: 12,
  x: 0,
  y: 0,
};

export const COPIED_ITEM_TYPES = {
  WIDGET: 'widget',
  PANEL: 'panel'
};