/* Copyright 2019 Greyskies. All Rights Reserved. */

const COLOR_TYPE = 'colorType';
const COLOR_KPI = 'colorKpi';
const COLOR_UNIT = 'colorUnit';
const SIZE_TYPE = 'sizeType';
const SIZE_KPI = 'sizeKpi';
const SIZE_UNIT = 'sizeUnit';
const MARKERS_LAYER_LABEL = 'marker';
const HEATMAP_LAYER_LABEL = 'heatmap';
const DEFAULT_MARKER_ICON_NAME = 'Circle';
const DEFAULT_MARKER_SVG_VIEW_BOX = '0 0 22 22';
const DEFAULT_MARKER_COLOR = '#dc3912';
const DEFAULT_MARKER_SIZE = 12;
const DEFAULT_MARKER_ICON_REDUIS = 11;
const DEFAULT_MARKER_ICON_SCALE_FACTOR = (1 / DEFAULT_MARKER_ICON_REDUIS);
const MIN_MARKER_SIZE = 8;
const MAX_MARKER_SIZE = 30;

const MIN_HEAT_MAP_RADIUS = 5;
const MAX_HEAT_MAP_RADIUS = 20;
const HEAT_MAP_OPACITY = 0.6;
const HEAT_MAP_WEIGHT_MIN_SCALE = 0;
const HEAT_MAP_WEIGHT_MAX_SCALE = 100;
const DEFAULT_HEATMAP_WEIGHT = 100;

const OTHER_RANGE = 'OTHER';

const HEATMAP_COLOR_PALETTES = {
  blueRed: [
    'rgba(0, 255, 255, 0)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 191, 255, 1)',
    'rgba(0, 127, 255, 1)',
    'rgba(0, 63, 255, 1)',
    'rgba(0, 0, 255, 1)',
    'rgba(0, 0, 223, 1)',
    'rgba(0, 0, 191, 1)',
    'rgba(0, 0, 159, 1)',
    'rgba(0, 0, 127, 1)',
    'rgba(63, 0, 91, 1)',
    'rgba(127, 0, 63, 1)',
    'rgba(191, 0, 31, 1)',
    'rgba(255, 0, 0, 1)'
  ],
  greenYellowRed : [ 
    'rgba(0, 0, 0, 0)',
    'rgba(102, 255, 0, 1)',
    'rgba(147, 255, 0, 1)',
    'rgba(193, 255, 0, 1)',
    'rgba(238, 255, 0, 1)',
    'rgba(244, 227, 0, 1)',
    'rgba(249, 198, 0, 1)',
    'rgba(255, 170, 0, 1)',
    'rgba(255, 113, 0, 1)',
    'rgba(255, 57, 0, 1)',
    'rgba(255, 0, 0, 1)',
    'rgba(255, 0, 0, 1)'
  ]
};

const HEATMAP_COLOR_PALETTE_DEFAULT = 'blueRed';

const MARKERS_LAYER = {
  visible: true,
  type: MARKERS_LAYER_LABEL,
  iconName: DEFAULT_MARKER_ICON_NAME,
  colorRanges: [],
  name: null,
  colorType: 'value',
  fixedColor: null,
  colorKpi: null,
  colorUnit: null,
  sizeType: 'fixed',
  fixedSize: DEFAULT_MARKER_SIZE,
  sizeKpi: null,
  sizeUnit: null,
};
const HEATMAP_LAYER = {
  visible: true,
  type: HEATMAP_LAYER_LABEL,
  colorRanges: [],
  name: null,
  colorPalette: HEATMAP_COLOR_PALETTE_DEFAULT,
  colorKpi: null,
  colorType: 'value',
  heatMapRadius: [5, 20],
};
const DEFAULT_LAYER = {
  ...MARKERS_LAYER,
  name: 'Layer 1',
  colorType: 'fixed',
  fixedColor: DEFAULT_MARKER_COLOR,

};
const mapWidgetDefaults = {
  COLOR_TYPE,
  COLOR_KPI,
  COLOR_UNIT,
  SIZE_TYPE,
  SIZE_KPI,
  SIZE_UNIT,
  MARKERS_LAYER_LABEL,
  HEATMAP_LAYER_LABEL,
  DEFAULT_MARKER_ICON_NAME,
  DEFAULT_MARKER_SVG_VIEW_BOX,
  DEFAULT_MARKER_ICON_REDUIS,
  DEFAULT_MARKER_ICON_SCALE_FACTOR,
  DEFAULT_MARKER_COLOR,
  DEFAULT_MARKER_SIZE,
  MIN_MARKER_SIZE,
  MAX_MARKER_SIZE,
  MIN_HEAT_MAP_RADIUS,
  MAX_HEAT_MAP_RADIUS,
  DEFAULT_LAYER,
  MARKERS_LAYER,
  HEATMAP_LAYER,
  HEATMAP_COLOR_PALETTES,
  HEAT_MAP_OPACITY,
  HEAT_MAP_WEIGHT_MIN_SCALE,
  HEAT_MAP_WEIGHT_MAX_SCALE,
  DEFAULT_HEATMAP_WEIGHT,
  OTHER_RANGE,
};


export default mapWidgetDefaults;
