/* Copyright 2019 Greyskies. All Rights Reserved. */

import AppDispatcher from 'dispatcher/AppDispatcher';
import EventsConstants from 'constants/EventsConstants';
import EventsAPI from '../api/EventsAPI';

export default {
  listAllSeverities(){
    EventsAPI.listAllSeverities().then(severities => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.LIST_ALL_Severities,
        data: severities,
      });
    });
  },
  listAllMLJobs(){
    EventsAPI.listAllMLJobs().then(mlJobsList => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.LIST_ALL_ML_JOBS,
        data: mlJobsList,
      });
    });
  },

  listAllRecordSources(){
    EventsAPI.listAllRecordSources().then(recordSources => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.LIST_ALL_RECORD_TYPES,
        data: recordSources,
      });
    });
  },

  listAllTypes(){
    EventsAPI.listAllTypes().then(types => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.LIST_ALL_TYPES,
        data: types,
      });
    });
  },

  listAllResolutions(){
    EventsAPI.listAllResolutions().then(resolutions => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.LIST_ALL_Resolutions,
        data: resolutions,
      });
    });
  },
  addEvent(data, isNewEvent){
    return EventsAPI.addEvent(data, isNewEvent).then(value => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.ADD_EVENT,
        data: value,
      });

      return value;
    });
  },
  listEventArguments(recordTypeId, isMLEvent){
    EventsAPI.listEventArguments(recordTypeId, isMLEvent).then(eventArguments => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.LIST_EVENT_ARGUMENTS,
        data: eventArguments,
      });
    });
  },
  getEventList(query) {
    return EventsAPI.getEventList(query).then(value => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.GET_EVENT_LIST,
        data: value,
      });
    });
  },

  deleteEvent(index, id) {
    return EventsAPI.deleteEvent(id).then(value => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.DELETE_EVENT,
        data: {
          index,
          value,
          id,
        },
      });

      return value;
    });
  },
  getEvent(id, errorCallback){
    return EventsAPI.getEvent(id).then(value => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.GET_EVENT,
        data: value,
      });
    }).catch(error => {
      errorCallback(error.errorStatus);
    });
  },
  getSNMPTrapVariableTypes(){ 
    EventsAPI.getSNMPTrapVariableTypes().then(types => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.LIST_ALL_SNMP_VARIABLE_TYPES,
        data: types,
      });
    });
  },
  getRecordSourceFieldsById(id){
    return EventsAPI.getRecordSourceFieldsById(id);
  },
  getAnalystActivationResponse(key){
    return EventsAPI.getAnalystActivationResponse(key);
  },
  getTreeNodes(query, node){
    return EventsAPI.getNodes(node ? node.networkElementId : null, query, node ? node : null);
  },
  getEventWorkflowDataList(query){
    return EventsAPI.getEventWorkflowDataList(query);
  },
  activateEvents(ids){
    return EventsAPI.activateListOfEvents(ids);
  },
  deactivateEvents(ids){
    return EventsAPI.deactivateListOfEvents(ids);
  },
  getAllTagDefinitions(){
    return EventsAPI.getAllTagDefinitions().then(tags => {
      AppDispatcher.dispatch({
        actionType: EventsConstants.LIST_ALL_TAGS,
        data: tags,
      });
    });
  },
}
