/* Copyright 2019 Greyskies. All Rights Reserved. */

import AppDispatcher from 'dispatcher/AppDispatcher';
import AppConstants from 'constants/AppConstants';
import UserAPI from 'api/UserAPI';
import ConfigurationApi from 'api/ConfigurationApi';

export default {
  getUserData() {
    UserAPI.getUserData().then(value => {
      AppDispatcher.dispatch({
        actionType: AppConstants.GET_USER_DATA,
        data : value,
      });
    });
  },
  getAppConfigs() {
    ConfigurationApi.getAppConfigs().then(value => {
      AppDispatcher.dispatch({
        actionType: AppConstants.GET_APP_CONFIG,
        data : value,
      });
    });
  },
  getFooterProps() {
    ConfigurationApi.getFooterProps().then(value => {
      AppDispatcher.dispatch({
        actionType: AppConstants.GET_BUILD_PROPS,
        data : value,
      });
    });
  },
  sendHeartBeat() {
    return ConfigurationApi.sendHeartBeat();
  },
};
