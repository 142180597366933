/* Copyright 2019 Greyskies. All Rights Reserved. */

import React from 'react';
import {withRouter} from 'react-router';
import {Panel, PanelGroup, FormWithFooter, HelpHeader} from 'react-template';
import {Row, Col, Button} from 'react-bootstrap';
import {MsgDefaults} from 'js-utils';
import inputValidator from 'validations/AccountManagementValidationSchema';
import _ from 'lodash';
import AppStore from 'stores/AppStore';
import  * as UserProfileActions from 'actions/UserProfileActions';
import {NotificationManager} from 'react-notifications';
import userDefaults from 'config/userDefaults';
import {USER_PROFILE} from 'config/pagesHeaderTitle';
import * as AccountManagementUtils from 'utils/AccountManagementUtils';
import {fromJS} from 'immutable';

@withRouter
export default class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.userId = AppStore.getUserData().userId;
    this.onBack = ::this.onBack;
    this.onChange = ::this.onChange;
    this.onSave = ::this.onSave;
    this.getFormInputs = ::this.getFormInputs;
    this.adjustFieldsBeforeSaving = ::this.adjustFieldsBeforeSaving;
    this.onResetPassword = ::this.onResetPassword;
    this.getResetPasswordCustomInput = ::this.getResetPasswordCustomInput;

    this.state = {
      userInfo: {},
      validation: {},
    };
  }

  componentDidMount(){
    UserProfileActions.getUserProfileInfo(this.userId)
      .then(response => {
        this.setState({
          userInfo: response,
        });
      });
  }

  inputValidator(attribute, value){
    return inputValidator(attribute, value);
  }

  onBack() {  
    this.props.router.goBack();
  }

  onChange(fields){
    const stateTemp = fromJS(this.state).toJS();

    stateTemp.userInfo.firstName = fields.firstName;
    stateTemp.userInfo.lastName = fields.lastName;
    stateTemp.userInfo.mobile = fields.mobile;

    stateTemp.validation = fields.validation;

    this.setState({
      ...stateTemp,
    });
  }

  onResetPassword(){
    const pathname = this.props.location.pathname + '/resetPassword';

    this.props.router.push({
      pathname,
    });
  }

  adjustFieldsBeforeSaving(){
    const userInfo = fromJS(this.state.userInfo).toJS();

    userInfo.firstName = this.state.userInfo.firstName.trim();
    userInfo.lastName = this.state.userInfo.lastName.trim();
    userInfo.mobile = this.state.userInfo.mobile.trim();

    return userInfo;
  }

  onSave(){
    const user = this.adjustFieldsBeforeSaving();

    UserProfileActions.saveProfileInfo(user).then(value => {
      if(value){
        this.onBack();
        NotificationManager.success(MsgDefaults.getSuccessUpdateMsg(userDefaults.PROFILE), MsgDefaults.getSuccessTitle(), 10000);
      } else {
        NotificationManager.error(MsgDefaults.getErrorUpdateMsg(userDefaults.PROFILE), MsgDefaults.getErrorTitle(), 10000);
      }})
      .catch(
      () => {
        NotificationManager.error(MsgDefaults.getErrorUpdateMsg(userDefaults.PROFILE), MsgDefaults.getErrorTitle(), 10000);
      });
  }

  getFormInputs(){
    const formInputs = [
      AccountManagementUtils.getEmailFormInput(true, this.state.userInfo.email, true),
      AccountManagementUtils.getUsernameFormInput(true, this.state.userInfo.username, true),
      AccountManagementUtils.getFirstNameFormInput(this.state.userInfo.ldap, this.state.userInfo.firstName, true),
      AccountManagementUtils.getLastNameFormInput(this.state.userInfo.ldap, this.state.userInfo.lastName, true),
      AccountManagementUtils.getMobileFormInput(this.state.userInfo.ldap, this.state.userInfo.mobile, true),
    ];

    if(!this.state.userInfo.ldap){
      formInputs.push(this.getResetPasswordCustomInput());
    }

    return formInputs;
  }

  getResetPasswordCustomInput(){
    return {
      type: 'custom',
      label: 'Password',
      component: <Button 
      onClick={this.onResetPassword} 
      bsStyle='primary'
      className='submitForm-btn'
    >
      {this.props.cancelButtonText || 'Reset Password'}
    </Button>,
    };
  }

  render() {
    return (
      <div>
        <HelpHeader 
          label={USER_PROFILE}
          className="userProfile-header"
          backFn={this.onBack} 
        />
        <Row>
          <Col xs={12} md={8} className='block-center'>
            <PanelGroup>
              <Panel 
                panelTitle={USER_PROFILE}
                classes={{ panelIdentifier :`'editUser'`}}>
                <FormWithFooter 
                  inputs={this.getFormInputs()}
                  validationFunction={this.inputValidator}
                  hidefooterButtonsGroup={this.state.userInfo.ldap}
                  onChange={this.onChange}
                  onCancel={this.onBack}
                  onSubmit={this.onSave}
                  className={'editUser-form'}
                  submitButtonText='Save'
                />
              </Panel>
            </PanelGroup>
          </Col>
        </Row>
      </div>
    );
  }
}