/* Copyright 2019 Greyskies. All Rights Reserved. */

export const TOPOLOGY_PLACEHOLDER = 'Select Topology';
export const SELECTED_TOPOLOGY = 'Topology';
export const TOPOLOGY_DISPLAY_NAME = 'Topology';
export const NO_DATA_MESSAGE = 'No data available';
export const NEW_WIDGET_ASSOCIATION = 'Please select Topology and Network Elements from the left panel.';
export const SHOW_LINK_LABEL = 'Show Link Label';
export const SHOW_NODE_ALIASE = 'Show Node Alias';
export const LINK_VALUE_SOURCE_PLACEHOLDER = 'Please Select Value Source';
export const LINK_ATTRIBUTE_MULTI_PLACEHOLDER = 'Please Select Attributes';
export const LINK_ATTRIBUTE_SINGLE_PLACEHOLDER = 'Please Select Attribute';
export const UNIT_PLACEHOLDER = 'Please Select Unit';
export const LINK_SETTING = 'Link Setting';
export const NODE_SETTING = 'Node Setting';
export const LINK_RANGES_LABEL = 'Link Ranges';
export const LINK_RANGE = 'Link Range';
export const AGGREGATION = 'Aggregation';
export const LINK_VALUE_SOURCE = 'Value Source';
export const SELECTION = 'Selection';
export const SELECTIONS = 'Node Selections';
export const SOURCE_TYPE = 'Source Type';
export const LAYOUT = 'layout';
export const TOPOLOGY_MAPPING_TITLE = 'Mappings';
export const TOPOLOGY_MAPPING = 'Mapping';
export const DEFAULT_FGA = 'Default FGA';
export const TOPOLOGY_TYPE_RECORDS = 'RECORDS';
export const TOPOLOGY_TYPE_TOPOLOGY = 'TOPOLOGY';
