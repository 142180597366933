/* Copyright 2019 Greyskies. All Rights Reserved. */
import React, {Component} from 'react';
import { Icons, ThemeUtils } from 'react-template';
import UserManagementActions from 'actions/UserManagementActions';
import AppStore from 'stores/AppStore';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

export default class SwitchThemeMode extends Component {
  constructor(props) {
    super(props);
    this.changeTheme = ::this.changeTheme;
  }

  changeTheme() {      
    let attribute = document.getElementsByTagName("html")[0].getAttribute('data-theme');
    
    if (attribute == ThemeUtils.getDarkTheme()) {
      ThemeUtils.setTheme(ThemeUtils.getLightTheme(), UserManagementActions.updateTheme, AppStore.setPreferedTheme, AppStore.getUserData().layoutTheme);
    }else {
      ThemeUtils.setTheme(ThemeUtils.getDarkTheme(), UserManagementActions.updateTheme, AppStore.setPreferedTheme, AppStore.getUserData().layoutTheme);
    }
  }

  render() {

    return (
      this.props.inNavbar ? 
        <div id="dark" className={`navbar-action-icon ${this.props.className}`} onClick={this.changeTheme}>
        <i className={Icons.theme + Icons.styles.evLg}></i>
        {this.props.inNavbar ?           
          <span className="navbar-item-title">
            Change Theme
          </span> : null
        }
        </div> 
      : <OverlayTrigger
          rootClose placement="bottom" trigger={['hover', 'focus']}
          overlay={
            <Tooltip placement="bottom" className="in" id="tooltip-bottom">
              Change Theme
            </Tooltip>
          }
        >
          <div id="dark" className={`navbar-action-icon ${this.props.className}`} onClick={this.changeTheme}>
            <i className={Icons.theme + Icons.styles.evLg}></i>
          </div>
        </OverlayTrigger>

    );
  }
}
