/* Copyright 2019 Greyskies. All Rights Reserved. */

import * as PermissionsDefaults from 'config/PermissionsDefaults';
import {Icons} from 'react-template';
import * as userLogsDefaults from 'config/userLogsDefaults';
import * as pagesHeaderTitle from 'config/pagesHeaderTitle';
import {PAGES} from 'config/routesDefaults';

export default {
  admin:{
    elements: [
      {
        // id: 'generalSettings',
        icon: Icons.assocSetting + Icons.styles.ev2x,
        children: [
          {
            id: 'systemSettings',
            name: pagesHeaderTitle.SYSTEM_SETTINGS_NAME,
            icon: Icons.systemConfig + Icons.styles.ev2x,
            children: [],
          },
          {
            id:'designSpaceOrganizer',
            name:'Design Space Organizer',
            icon: Icons.manageLandingPage + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'templatesPlaceholders',
            name: pagesHeaderTitle.PLACEHOLDERS_SETTINGS,
            icon: Icons.placeholder + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'topologySettings',
            name: pagesHeaderTitle.TOPOLOGY_SETTINGS,
            icon: Icons.nodeSettings + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Access Management',
        icon: Icons.permissions + Icons.styles.ev2x,
        children: [
          {
            id: 'userManagement',
            name: pagesHeaderTitle.USER_MANAGEMENT,
            icon: Icons.manageUsers + Icons.styles.ev2x,
            children: [],
          },
          {
            id:'groupManagement',
            name: pagesHeaderTitle.GROUP_MANAGEMENT,
            icon: Icons.manageGroups + Icons.styles.ev2x,
            children: [],
          },
          {
            id:'manageOwnership',
            name: pagesHeaderTitle.OWNERSHIP_MANAGEMENT,
            icon: Icons.permissions + Icons.styles.ev2x,
            children: [],
          },
          {
            id:'LDAPConfigManagement',
            name: pagesHeaderTitle.LDAP_MANAGEMENT,
            icon: Icons.ldap + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Data Model',
        icon: Icons.dataModel + Icons.styles.ev2x,
        children: [
          {
            id: 'dataModelTree',
            name: 'Data Model Tree',
            icon: Icons.dataModelTree + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'recordsManagement',
            name:'Records',
            icon: Icons.addRecords + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'attributeTypes',
            name: pagesHeaderTitle.DATA_TYPES_NAME,
            icon: Icons.attrTypes + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'tags',
            name:'Tags',
            icon: Icons.tags + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Collection Management',
        icon: Icons.collectionManagement + Icons.styles.ev2x,
        children: [
          {
            id: 'PushBasedDataBindingMappingManagement',
            name: 'Push Mappings',
            icon: Icons.pushBased + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'lookup',
            name: 'Lookup',
            icon: Icons.lookup + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'adminDefined',
            name: pagesHeaderTitle.ATTRIBUTE_DATA_INSERTION,
            icon: Icons.pencil + Icons.styles.ev2x,
            children: [],
          },
          {
            id: PermissionsDefaults.UNREACHABILITY_PLAN_KEY,
            name: 'Unreachability Plan',
            icon: Icons.unreachability + Icons.styles.ev2x,
            children: [],
          },
          {
            id: PermissionsDefaults.CP_BLOCK_LIST,
            name: 'Block List',
            icon: Icons.blockList + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'rollUpPlansManagement',
            name:'Time Series Data Plan',
            icon: Icons.timeSeries + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'lakehouseRetentionPlansManagement',
            name:'Big Data Plan',
            icon: Icons.retentionBigData + Icons.styles.ev2x,
            children: [],
          },
          {
            id: PermissionsDefaults.ROUTING_GROUP_KEY,
            name: 'Routing Group',
            icon: Icons.routingGroup + Icons.styles.ev2x,
            children: [],
          },
          {
            id: PermissionsDefaults.DATA_FLOW_KEY,
            name: 'Dataflow',
            icon: Icons.dataFlow + Icons.styles.ev2x,
            children: [],
          },
          {
            id: PAGES.COLLECTION_METHOD,
            name: 'Collection Method',
            icon: Icons.collectionMethod + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'CustomAnalytics',
        icon: Icons.customAnalytics + Icons.styles.ev2x,
        children: [
          {
            id:'customAnalytics',
            name: pagesHeaderTitle.CUSTOM_ANALYTIC,
            icon: Icons.analyticsProject + Icons.styles.ev2x,
            children: [],
          },
          {
            id:'analyticsJobsManagement',
            name: pagesHeaderTitle.ANALYTIC_JOBS,
            icon: Icons.analyticsJob + Icons.styles.ev2x,
            children: [],
          },
          {
            id:'analyticsRunsInfo',
            name: pagesHeaderTitle.ANALYTIC_LOGS,
            icon: Icons.systemLogs + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Attriute Binding Management',
        icon: Icons.attributeBinding + Icons.styles.ev2x,
        children: [
          {
            id: 'dataBindingManagement',
            name: 'Discovery/ Metric Binding',
            icon: Icons.discoveryMetricBinding + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'sqlBindingManagement',
            name: pagesHeaderTitle.ADVANCED_METRIC_BINDING,
            icon: Icons.sqlBinding + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'syntheticBindingManagement',
            name: pagesHeaderTitle.SYNTHETIC_TESTING,
            icon: Icons.syntheticBinding + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'configurationBindingManagement',
            name: 'Configuration Binding',
            icon: Icons.configBinding + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Collection Profile Management',
        icon: Icons.collectionProfile + Icons.styles.ev2x,
        children: [
          {
            id: 'collectionProfiles',
            name: 'Discovery/ Metric Collection',
            icon: Icons.discoveryMetricCollection + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'sqlCollectionProfiles',
            name: pagesHeaderTitle.ADVANCED_METRIC_COLLECTION,
            icon: Icons.sqlCollection + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'syntheticCollectionProfiles',
            name: 'Synthetic Collection',
            icon: Icons.syntheticCollection + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'configurationCollectionProfiles',
            name: 'Configuration Collection',
            icon: Icons.configCollection + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        id:'fga',
        name: pagesHeaderTitle.DATA_ANALYSIS,
        icon: Icons.fga + Icons.styles.ev2x,
        children: [],
      },
      {
        id: 'auditingTemplatesManagement',
        name:'Template Checking Profiles',
        icon: Icons.auditingTemplate + Icons.styles.ev2x,
        children: [],
      },
      {
        name: 'NE Management',
        icon: Icons.neAllocation + Icons.styles.ev2x,
        children: [
          {
            id: 'networkElementManagement',
            name: pagesHeaderTitle.NETWORK_BROWSER,
            icon: Icons.networkElement + Icons.styles.ev2x,
            isLandingPage: true,
            children: [],
          },
          {
            id: 'organizationGroupManagement',
            name: 'Organization Groups',
            icon: Icons.organizationGroup + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Events',
        icon: Icons.mainEvents + Icons.styles.ev2x,
        children: [
          {
            id: 'events',
            name: pagesHeaderTitle.EVENT_DEFINITIONS,
            icon: Icons.events + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'triggeredEvents',
            name:'Triggered Events',
            icon: Icons.triggeresEvents + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'automation',
        icon: Icons.automation + Icons.styles.ev2x,
        children: [
          {
            id:'eventHealing',
            name: pagesHeaderTitle.TEMPLATES,
            icon: Icons.eventHealing + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'automationWorkflow',
            name: pagesHeaderTitle.AUTOMATION_WORKFLOWS,
            icon: Icons.workflow + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Northbound Interface',
        icon: Icons.api + Icons.styles.ev2x,
        children: [
          {
            id:'applicationClientManagement',
            name: pagesHeaderTitle.REST_API,
            icon: Icons.appAccess + Icons.styles.ev2x,
            children: [],
          },
          {
            id:'nbi',
            name: pagesHeaderTitle.STREAMING_NBI,
            icon: Icons.nbi + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: pagesHeaderTitle.EVENT_LOGS_NAME,
        icon: Icons.paste + Icons.styles.ev2x,
        children: [
          {
            id:'eventLogs',
            name: pagesHeaderTitle.EVENT_LOGS_NAME,
            icon: Icons.systemLogs + Icons.styles.ev2x,
            children: [],
          },
          {
            id:'userLogs',
            name: userLogsDefaults.USER_LOGS_NAME,
            icon: Icons.userLogs + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        id: 'license',
        name: 'License',
        icon: Icons.license + Icons.styles.ev2x,
        children: [],
      },
    ],
  },
  reporter:{
    elements: [
      {
        id: 'dashboard',
        name: 'Dashboard',
        href: '../user/dashboard',
        icon:  Icons.dashboard + Icons.styles.ev2x,
        children: [],
      },
      {
        name: 'Reports',
        href: 'all_reports',
        icon: Icons.report + Icons.styles.ev2x,
        children: [
          {
            id: 'report',
            name: 'Report Designer',
            href: '../user/metricOverDurationReport',
            icon: Icons.reportDesigner + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'scheduleReports',
            name: 'Scheduled Reports',
            href: '../user/scheduleReports',
            icon: Icons.scheduledReports + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Analysis',
        href: '../user/analysis',
        icon: Icons.dataAnalysis + Icons.styles.ev2x,
        children: [
          {
            id: 'forecasting',
            name: 'Forecasting',
            icon: Icons.forcasting + Icons.styles.ev2x,
            children: [],
          },
          {
            id: 'mlJobs',
            name: 'ML Jobs',
            icon: Icons.mlJobs + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: 'Archived Files',
        icon: Icons.archive + Icons.styles.ev2x,
        children: [
          {
            id: PermissionsDefaults.CONFIGURATION_LISTING,
            name: 'Archived Files',
            icon: Icons.archive + Icons.styles.ev2x,
            children: [],
          },
          {
            id: PermissionsDefaults.CONFIGURATION_DIFF_VIEWER,
            name: 'Configuration Difference',
            icon: Icons.fileDiff + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        name: pagesHeaderTitle.FILE_EXPORT,
        icon: Icons.fileExport + Icons.styles.ev2x,
        children: [
          {
            id: 'fileExport',
            name: pagesHeaderTitle.FILE_EXPORT,
            icon: Icons.fileExport + Icons.styles.ev2x,
            children: [],
          }, 
          {
            id: 'ScheduledFileExports',
            name: 'Scheduled File Exports',
            icon: Icons.scheduledFileExports + Icons.styles.ev2x,
            children: [],
          },
        ],
      },
      {
        id: PermissionsDefaults.OTEL,
        name: pagesHeaderTitle.OTEL,
        icon: Icons.opentelemetry + Icons.styles.ev2x,
        children: [],
      },
     
    ],
  },
};
