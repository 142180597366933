/* Copyright 2019 Greyskies. All Rights Reserved. */

import React, {Component, Fragment} from 'react';
import {ProgressBar, Row, Col} from 'react-bootstrap';
import DownloadsActions from 'actions/DownloadsActions';
import {FileDownloadUtils} from 'js-utils';
import {Icons} from 'react-template';

export default class DownloadItem extends Component{

  constructor(props) {
    super(props);
    this.downloadPDF = ::this.downloadPDF;
    this.downloadReport = ::this.downloadReport;
    this.remove = ::this.remove;
    this.getExportStateStyle = ::this.getExportStateStyle;
    this.getExportStateLabel = ::this.getExportStateLabel;
    this.getCancelIconClassName = ::this.getCancelIconClassName;
    this.getCommandButton = ::this.getCommandButton;
    this.getRemoveClassName = ::this.getRemoveClassName;
  }
  state={
    name:this.props.name,
  }

  downloadPDF(e){
    e.preventDefault();
    const fileName = `${this.state.name}.${this.props.type}`;

    if(this.props.path && this.props.path != null){
      if(this.props.isReport){
        this.downloadReport(this.props.path);
      }else{
        FileDownloadUtils.saveAs(this.props.path, fileName);
      }
    }
    if (this.props.messageData && this.props.messageData !== null){
      const url = FileDownloadUtils.transformDataToUrl(this.props.messageData, this.props.type);

      FileDownloadUtils.saveAs(url, fileName);
      FileDownloadUtils.revokeUrl(url);
    }
  }

  downloadReport(path){
    const generatedURL = 'user/report/export/download/';
    const queryString = new URLSearchParams();

    queryString.append('filepath', path);
    window.location = `${generatedURL}?${queryString.toString()}`;
  }

  remove(e){
    e.preventDefault();
    DownloadsActions.removeDownload(this.props);
  }


  getCommandButton(progress){
    return progress < 100 ?
      null
      :
        <a href="#" onClick={this.downloadPDF}
          className="downloadBTN">
          <i className={Icons.download}></i>
        </a>;
  }

  getExportStateStyle(progress){
    return progress < 100 ? 'info' : 'success';
  }

  getExportStateLabel(progress){
    return progress < 100 ? progress + '%' : 'Done';
  }

  getCancelIconClassName(progress){
    return progress < 100 ? Icons.close : Icons.delete;
  }

  getRemoveClassName(progress){
    return progress < 100 ? 'cancelBTN' : 'removeBTN';
  }

  render(){
    const removeBtn = <a href="#" onClick={this.remove} className={this.getRemoveClassName(this.props.progress)}>
                      <i className={this.getCancelIconClassName(this.props.progress)} ></i>
                    </a>;

    let title = this.state.name;

    return(
      <Fragment>
        <Row style={{paddingRight:'8px'}}>
          <Col xs={9} style={{wordWrap: 'break-word'}}>
            {title}
          </Col>
          <Col xs={3}>
            {this.getCommandButton(this.props.progress)}
            {removeBtn}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ProgressBar active now={this.props.progress}
              bsStyle={this.getExportStateStyle(this.props.progress)}
              label={this.getExportStateLabel(this.props.progress)} />
          </Col>
        </Row>
      </Fragment>
    );
  }
}
