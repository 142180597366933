/* Copyright 2019 Greyskies. All Rights Reserved. */

import BaseStore from './BaseStore';
import DownloadsConstants from 'constants/DownloadsConstants';
import { NotificationManager } from 'react-notifications';
import { FileDownloadUtils, DateTimeUtils, RequestUtils} from 'js-utils';
import * as DownloadDefaults from 'config/downloadDefaults.js';
import AppStore from './AppStore';
import { fetchEventSource } from '@microsoft/fetch-event-source';


const DOWNLOAD_COMPLETE_PERCENTAGE = 100;
const SSE_CONNECTION_RETRIAL_INTERVAL = 10 * 1000;

class RetriableError extends Error {}
class NotAuthorized extends Error {}
class DownloadsStore extends BaseStore{

  constructor(){
    super();
    this.downloads = [];

    this.addActionHandler(DownloadsConstants.ADD_DOWNLOAD,
      this.addDownload.bind(this));
    this.addActionHandler(DownloadsConstants.REMOVE_DOWNLOAD,
      this.removeDownload.bind(this));
    this.addActionHandler(DownloadsConstants.CLEAR_DOWNLOADS,
      this.clearAllDownloads.bind(this));
    this.registerWithDispatcher();
    this.pid = setInterval(() => {
      if(AppStore.getUserData()){
        this.bc = new BroadcastChannel('test_channel');
        this.startSseConnection(this);
        clearInterval(this.pid);
      }
    }, SSE_CONNECTION_RETRIAL_INTERVAL);
  }

  startSseConnection(caller){
    fetchEventSource('user/report/connectSse/', {
      openWhenHidden: true,
      async onopen(response) {
        if (response.ok) {
          caller.bc.onmessage = message => {
            if(message.data.type == 'reload'){
              window.location.reload();
            }
            caller.handleMessage(message.data);
          }
          
          return;
        } else if (response.status == RequestUtils.ERR_CODES.UNAUTHORIZED) {
          throw new NotAuthorized();
        } else {
          throw new RetriableError();
        }
      },
      onmessage(msg) {
        caller.handleMessage(JSON.parse(msg.data));
      },
      onclose() {
        throw new RetriableError();
      },
      onerror(err) {
        if (err instanceof NotAuthorized) {
          caller.bc.postMessage({type: 'reload'});
          caller.bc.close();
          window.location.reload();
        }else{
          return SSE_CONNECTION_RETRIAL_INTERVAL;
        }
      },
    });
  }

  handleMessage(msg){
    const data = msg.id ? msg
    : {
      isReport: true,
      exportId: msg.exportId,
      reportId: msg.reportId,
      progress: msg.progress,
      path: msg.path,
      name: msg.reportName,
      id: `${msg.reportName}-${msg.reportId}-${this.generateInstantId()}`,
    };

    if(msg.type == 'delete'){
      this.removeDownload({data:{id: data.id, stopBrodcast: true}});
    }else if(msg.type == 'deleteAll'){
      this.clearAllDownloads(null, true);
    }else if (msg.progress === 0 ){ // new Report download
      this.addDownload({data}, msg.id);
    }else {
      this.updateReportDownload(data, msg.id);
    }
  }

  updateReportDownload(data, stopBrodcast){    
    const downloadItem = this.downloads.find( download => download.exportId == data.exportId);

    if(downloadItem){
      downloadItem.progress = data.progress;
      downloadItem.path = data.path;
      downloadItem.name = data.name;
      if(downloadItem.progress === DOWNLOAD_COMPLETE_PERCENTAGE){
        NotificationManager.success(downloadItem.name + ' is ready to be downloaded.', DownloadDefaults.DOWNLOAD_CENTER);
        if(!stopBrodcast){
          this.bc.postMessage(downloadItem);
        }
        this.emitChange();
      }
    }else{
      this.addDownload({data});
    }
  }

  addDownload(action, stopBrodcast){
    const download = action.data;

    NotificationManager.info(DownloadDefaults.NEW_DOWNLOAD_MESSAGE +
      download.name, DownloadDefaults.DOWNLOAD_CENTER);
    if(download.progressColor == DownloadDefaults.DANGER){
      NotificationManager.error( download.name +
        DownloadDefaults.DOWNLOAD_FAILED_MESSAGE, DownloadDefaults.DOWNLOAD_CENTER);
    }else if(download.progress == DOWNLOAD_COMPLETE_PERCENTAGE 
      || download.progressColor == DownloadDefaults.SUCCESS){
      NotificationManager.success( download.name +
        DownloadDefaults.DOWNLOAD_DONE_MESSAGE, DownloadDefaults.DOWNLOAD_CENTER);
    }
    if(!stopBrodcast){
      this.bc.postMessage(download);
    }
    this.downloads.unshift(download);
    this.emitChange();
  }

  generateInstantId(){
    return DateTimeUtils.getCurrentDateTimeinMillieSeconds();
  }

  removeDownload(action){
    const downloadId = action.data.id;

    this.downloads.forEach( (download, index) => {
      if (download.id === downloadId ){
        FileDownloadUtils.revokeUrl(download.url );
        this.downloads.splice(index, 1);
        if(!action.data.stopBrodcast){
          this.bc.postMessage({type: 'delete', id: downloadId});
        }
      }
    });
    this.emitChange();
  }

  clearAllDownloads(action, stopBrodcast){
    const downloadKeys = Object.keys(this.downloads);

    downloadKeys.forEach(downloadKey => {
      if(this.downloads[downloadKey].progress == DOWNLOAD_COMPLETE_PERCENTAGE
        ||  this.downloads[downloadKey].progressColor == DownloadDefaults.DANGER){
        FileDownloadUtils.revokeUrl(this.downloads[downloadKey].url );
        delete this.downloads[downloadKey];
      }
    });
    if(!stopBrodcast){
      this.bc.postMessage({type: 'deleteAll'});
    }
    this.emitChange();
  }

  getDownloads(){
    return this.downloads;
  }

}

export default new DownloadsStore();
